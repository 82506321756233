import React, { useState, useEffect, useRef } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import { Button, Tab, Tabs } from '@mui/material'
import DataTable from 'react-data-table-component'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import { Circles } from 'react-loader-spinner'
import axios from 'axios'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Grid, Chip, Autocomplete, TextField, createFilterOptions, ToggleButton, InputAdornment } from '@mui/material'
import { toast } from 'react-toastify'
import QuickReportPreview from './QuickReportPreview'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NewAdvanceFilters from './NewAdvanceFilters'
import store from '../../../Store'
import { calculateDaysBetween, getFullFormattedDate, getYMD } from '../../../utils/dateHelpers'
import SchedulingSection from './SchedulingSection'
import { CgNotes } from 'react-icons/cg'
import { FcOpenedFolder } from 'react-icons/fc'
import { RiDeleteBin6Line } from 'react-icons/ri'
import DeleteNews from './DeleteNews'
import { GrEdit } from 'react-icons/gr'
import { BiSave } from 'react-icons/bi'
import { AiOutlineSchedule } from 'react-icons/ai'
import PreviewIcon from '@mui/icons-material/Preview'
import CancelIcon from '@mui/icons-material/Cancel'
import NewSearch from './NewSearch'
import NewSearchQueryResult from '../../../components/NewsEditedCard/NewSearchQueryResult'
import InfiniteScroll from 'react-infinite-scroller'
import FilterListIcon from '@mui/icons-material/FilterList'
import Cron from 'react-js-cron'
import { AiOutlineSave } from 'react-icons/ai'
import CronTab from '../../AnalystAdmin/CronTab'
import './MyRequests.css'
import NewAdvanceSearchTab from './NewAdvanceSearchTab'
import SaveSearches from './SaveSearches'
import { StepperButton, Steppers } from './Steppers'
import AddIcon from '@mui/icons-material/Add'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import SearchIcon from '@mui/icons-material/Search'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { IoIosClose } from 'react-icons/io'
import { Spinner } from 'reactstrap'
import SavedQueries from './SavedQueries'
import SelectTemplate from './SelectTemplate'
import TemplatePreview from './TemplatePreview'
import SendNewsLetter from './SendNewsLetter'
import ScheduleSucessMsg from './ScheduleSucessMsg'
import DetailedReportPreview from './DetailedReportPreview'
import QuickAdvanceFilters from './QuickAdvanceFilters'
import { LastCronValue } from '../../../utils/dashboardStrings'
import CustomLoader from './CustomLoader'
import NewsHeaderSection from './NewsHeaderSection'
import NewNoResults from '../../../components/NoResults/NewNoResults'
import JustShowFilters from './JustShowFilters'
import { subDays } from 'date-fns'
import { FaSearch } from 'react-icons/fa'
var lo_ = require('lodash')

const customStyles = {
    headRow: {
        style: {
            backgroundColor: '#00193C', // Change to your desired header background color
            color: '#FFF', // Change to your desired header text color
            fontSize: '16px', // Change to your desired header font size
            height: '20px',
        },
    },
}

const sections = [
    { label: 'Business Events', value: 'subcategories' },
    { label: 'Company', value: 'companies' },
    { label: 'Industry', value: 'industries' },
    { label: 'Sector', value: 'sectors' },
    { label: 'Topic', value: 'topics' },
]

const filter = createFilterOptions()
export default function MyRequests() {
    const currentPath = window.location.pathname
    const wrapperRef = useRef(null)
    const filterRef = useRef(null)
    const filterInputRef = useRef(null)
    const [token, setToken] = useState(null)

    closeExpandable(wrapperRef)
    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setBasicSearchDrop(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    const OpenFilter = () => {
        if (!newsFilters) {
            setNewsFilters(true)
            document.addEventListener('mousedown', handleClickOutsideFilter)
        }
    }

    const handleClickOutsideFilter = event => {
        if (
            filterInputRef.current &&
            !filterInputRef.current.contains(event.target) &&
            !filterRef.current.contains(event.target)
        ) {
            setNewsFilters(false)
            document.removeEventListener('mousedown', handleClickOutsideFilter)
        }
    }

    const [pathName, setPathName] = useState(currentPath)

    const usertype = store.getState().auth.user.user_type
    const userEmail = store.getState().auth.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [subTab, setSubTab] = useState(0)
    const [search, setSearch] = useState('')
    const [reports, setReports] = useState([])
    const [alerts, setAlerts] = useState([])
    const [collectionQueries, setCollectionQueries] = useState([])
    const [nonCollectionQueries, setNonCollectionQueries] = useState([])
    const [iframeReport, setIframeReport] = useState(false)
    const [documents, setDocument] = useState('')
    const [quickReportModal, setQuickReportModal] = useState(false)
    const [detailedReportModal, setDetailedReportModal] = useState(false)
    const [EditPage, setEditPage] = useState(false)
    const [editQuery, setEditQuery] = useState(null)
    const [allCategories, setAllCategories] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [allIndustries, setAllIndustries] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [allSources, setAllSources] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedSources, setSelectedSources] = useState([])
    const [practiceSubCategory, setPracticeSubCategory] = useState([])
    const [showBusinessEvents, setShowBusinessEvents] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const [dateSection, setDateSection] = useState(false)
    const [selectedOption, setSelectedOption] = useState('29')
    const [includeSection, setIncludeSection] = useState(false)
    const [includeBusiness, setIncludeBusiness] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [users, setUsers] = useState([])
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [expandedView, setExpandedView] = useState(false)
    const [basicSearchResponse, setBasicSearchResponse] = useState({ data: [], next: null })
    const [basicSearchInput, setBasicSearchInput] = useState('')
    const [loadmoreFilter, setLoadMoreFilter] = useState({ value: null, type: null })
    const [cronTabValue, setCronTabValue] = useState('0 0 * * 1')
    const [editCron, setEditCron] = useState(null)
    const [isEnabled, setIsEnabled] = useState(false)
    const [lastScheduled, setLastScheduled] = useState(null)
    const [nextScheduled, setNextScheduled] = useState(null)
    const [templates, setTemplates] = useState([])
    const [emailSubject, setEmailSubject] = useState('')
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState({ id: null, query: '' })
    const [allCollectionName, setAllCollectionName] = useState([])
    const [collectionName, setCollectionName] = useState(null)
    const [collectionDescription, setCollectionDescription] = useState('')
    const [collectionShared, setCollectionShared] = useState(false)
    const [collectionUsers, setCollectionUsers] = useState([])
    const [collectionModal, setCollectionModal] = useState(false)
    const [collectionShareList, setCollectionShareList] = useState([
        { value: true, label: 'Share with Everyone' },
        { value: false, label: 'Private' },
    ])
    const [collectionPrivacy, setCollectionPrivacy] = useState({ value: true, label: 'Share with Everyone' })
    const [collectionId, setCollectionId] = useState(null)
    const [editCollection, setEditCollection] = useState(false)
    const [deleteCollection, setDeleteCollection] = useState(false)
    const [saveQueryName, setSaveQueryName] = useState('')
    const [basicSearchDrop, setBasicSearchDrop] = useState(false)
    const [newsFilters, setNewsFilters] = useState(false)
    const [sortBy, setSortBy] = useState('date')

    var startDate = new Date()
    startDate.setDate(startDate.getDate() - Number(selectedOption))
    const [selectedDatefrom, setSelectedDateFrom] = useState(getYMD(startDate))
    const [selectedDateTo, setSelectedDateTo] = useState(getYMD(new Date()))
    const [isLoader, setIsLoader] = useState(false)
    const [collectionTab, setCollectionTab] = useState(0)
    const [allTopics, setAllTopics] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [scheduleModel, setScheduleModal] = useState(false)
    const [scheduleContent, setScheduleContent] = useState([])
    const [allowedDropdowns, setAllowedDropdowns] = useState(['period', 'months', 'month-days', 'week-days', 'hours'])
    const [fromFilter, setFromFilter] = useState([])
    const [deleteReportModal, setDeleteReportModal] = useState(false)

    const [advanceResponse, setAdvanceResponse] = useState({ data: [], next: null })
    const [semanticSaveModal, setSemanticSaveModal] = useState(false)
    const [editId, setEditId] = useState('')
    const [directRun, setDirectRun] = useState(true)
    const [selectedLevel, setSelectedLevel] = useState('level0')
    const [selectedQueries, setSelectedQueries] = useState({
        articleDateFrom: '',
        articleOperator: 'BETWEEN',
        articleDateTo: '',
        region: [],
        regionOperator: 'IS',
        sectors: [],
        industryOperator: 'IS',
        company: [],
        companyOperator: 'IS',
        category: [],
        categoryOperator: 'IS',
        topic: [],
        topicOperator: 'IS',
        newsTitle: '',
        newsTitleOperator: 'CONTAINS',
        newsContent: '',
        newsContentOperator: 'CONTAINS',
        source: [],
        sourceOperator: 'IS',
    })
    const [selectedStories, setSelectedStories] = useState([])
    const [editqueryName, setEditQueryName] = useState('')
    const [saveModal, setSaveModal] = useState(false)
    const [createNews, setCreateNews] = useState(false)
    const [createNewsTab, setCreateNewsTab] = useState(null)
    const [collectionSub, setCollectionSub] = useState(0)
    const [saveSub, setSaveSub] = useState(false)
    const [saveExisSub, setSaveExisSub] = useState(false)
    const [saveDummyData, setSaveDummyData] = useState([])
    const [dummyDataModal, setDummyDataModal] = useState(false)
    const [dummyEdit, setDummyEdit] = useState(false)
    const [dummyEditQuery, setDummyEditQuery] = useState(null)
    const [autoMate, setAutoMate] = useState([
        { value: 'Automate', label: 'Automate' },
        { value: 'Manual', label: 'Manual' },
    ])
    const [subPlace, setSubPlace] = useState(0)
    const [runAllCollection, setRunAllCollection] = useState([])
    const [isAllSelected, setAllSelected] = useState(false)
    const [selectedNewsOnly, setSelectedNewsOnly] = useState(false)
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [duplicateCollapsed, setDuplicateCollapsed] = useState(false)

    const [activeStep, setActiveStep] = useState(0)
    const [hyperlinkTitle, setHyperLinkTitle] = useState('')
    const [hyperlinkSource, setHyperLinkSource] = useState('')
    const [introText, setIntroText] = useState()
    const [subjectLine, setSubjectLine] = useState()
    const [primaryColor, setPrimaryColor] = useState('')
    const [secondaryColor, setSecondaryColor] = useState('')
    const [title, setTitle] = useState('')

    // Primary Logo
    const [selectedLogo, setSelectedLogo] = useState('')
    const [logoManualImage, setLogoManualImage] = useState(false)
    const [logoFile, setLogoFile] = useState(null)
    const [logoFileName, setFileName] = useState('')
    const [logoSize, setLogoSize] = useState({})

    // Right Side Logo
    const [secondaryLogo, setSecondaryLogo] = useState('')
    const [logo2ManualImage, setLogo2ManualImage] = useState(false)
    const [logo2File, setLogo2File] = useState(null)
    const [seclogoFileName, setsecFileName] = useState('')
    const [secLogoSize, setSecLogoSize] = useState({})

    // Background Image
    const [selectedBg, setSelectedBg] = useState('')
    const [bgManualImage, setBgManualImage] = useState(false)
    const [bgFile, setBgFile] = useState(null)
    const [bgFileName, setBgFileName] = useState('')
    const [categorizedNews, setCategorizedNews] = useState([])
    const [hyperlink, setHyperlink] = useState('')
    const [content, setContent] = useState('')
    const [allFirms, setAllFirms] = useState([])
    const [allFirmUsers, setAllFirmUsers] = useState([])
    const [sentUsers, setSentUsers] = useState([])
    const [selectedPreviewTempalte, setSelectedPreviewTemplate] = useState(null)
    const [selectedArticles, setSelectedArticles] = useState(true)
    const [summaryLoader, setSummaryLoader] = useState(false)
    const [progressContent, setProgressContent] = useState(null)
    const [currentDate, setCurrentDate] = useState(30)
    const [advanceCurrentDate, setAdvanceCurrentDate] = useState(30)
    const [userReceiveDetailedReport, setUserReceiveDetailedReport] = useState(false)
    const [combinedRegCouPro, setCombinedRegCouPro] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState({
        alerts: 10,
        reports: 10,
    })
    const [relevantNews, setRelevantNews] = useState(false)
    const [customDate, setcustomDate] = useState({
        startDate: subDays(new Date(), 29),
        endDate: new Date(),
    })
    const [fromSavedData, setFromSavedData] = useState([])
    const [loadMoreLoader, setLoadMoreLoader] = useState(false)
    const [approxLength, setApproxLength] = useState(false)
    const [runAllSearches, setRunAllSearches] = useState({ data: [] })
    const [editOnly, setEditOnly] = useState(false)

    useEffect(() => {
        // isAllSelected
        //     ? setSelectedStories(current => [
        //           ...current,
        //           ...basicSearchResponse.data.filter(each => !selectedStories.includes(each)),
        //       ])
        //     : setSelectedStories([])
        isAllSelected
            ? setSelectedStories(current => [
                  ...current,
                  ...(basicSearchResponse.overallData.length !== 0
                      ? basicSearchResponse.overallData.filter(each => !selectedStories.includes(each))
                      : []),
                  ...(advanceResponse.data && advanceResponse.data.data && advanceResponse.data.data.length !== 0
                      ? advanceResponse.data.data.filter(each => !selectedStories.includes(each))
                      : []),
              ])
            : setSelectedStories([])
    }, [isAllSelected])

    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        if (pathName === '/app/request/') {
            headerTitle.textContent = 'My Requests'
        } else {
            headerTitle.textContent = 'Newsletters'
        }
    }, [pathName])

    useEffect(() => {
        if (createNews) {
            setDirectRun(false)
        } else {
            setDirectRun(true)
        }
    }, [createNews])

    const allIndustriesName = allHierarchy.map(val => val.name)

    const allSectorsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const addingOfIndustriesSectorsNames = allIndustriesName.concat(allSectorsName)

    const allRegionName = regionHierarchy.map(val => val.name)

    const allCountryName = regionHierarchy
        .map(val => val.countries.map(cty => cty.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allProvinceName = regionHierarchy
        .map(val => val.countries.map(cty => cty.provinces.map(pro => pro.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const industryDrop =
        allIndustries.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
            ? [
                  ...new Set(
                      allIndustries
                          .filter(val => val.label === basicSearchInput)
                          .map(val => val.label)
                          .concat(selectedSectors)
                  ),
              ]
            : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc))

    const companyDrop =
        defaultData.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
            ? [
                  ...new Set(
                      defaultData
                          .filter(val => val.label === basicSearchInput)
                          .map(val => val.label)
                          .concat(selectedCompanies)
                  ),
              ]
            : selectedCompanies

    const categoryDrop =
        allSubCategories.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
            ? [
                  ...new Set(
                      allSubCategories
                          .filter(val => val.label === basicSearchInput)
                          .map(val => val.label)
                          .concat(selectedCategories)
                  ),
              ]
            : [
                  ...new Set(
                      practiceSubCategory.reduce((allitems, item) => {
                          const selList = allitems

                          if (selectedCategories.includes(item.id)) {
                              if (!selList.includes(item.name)) {
                                  selList.push(item.name)
                              }
                          }
                          item.subcategories.forEach(subItem => {
                              if (Boolean(selectedCategories.includes(subItem.id)) && !selList.includes(subItem.name)) {
                                  selList.push(subItem.name)
                              }
                          })

                          return selList
                      }, [])
                  ),
              ]

    const topicDrop =
        allTopics.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
            ? [
                  ...new Set(
                      allTopics
                          .filter(val => val.label === basicSearchInput)
                          .map(val => val.label)
                          .concat(selectedSectors)
                  ),
              ]
            : allTopicsName.filter(acc => selectedSectors.includes(acc))

    const filterStories = basicSearchResponse.overallData
        ? basicSearchResponse.overallData.filter(story => {
              const isMultipleSources = selectedSources.length > 0
              const sourceCondition = isMultipleSources ? selectedSources.includes(story.source_name) : true
              const isMultipleSubcategories = selectedSubCategories.length > 0
              const subcategoryCondition = isMultipleSubcategories
                  ? story.subcategories &&
                    story.subcategories.some(subcategory => selectedSubCategories.includes(subcategory.name))
                  : true
              const isMultiplecompanies = selectedCompanies.length > 0
              const companyCondition = isMultiplecompanies
                  ? story.companies && story.companies.some(subcategory => selectedCompanies.includes(subcategory.name))
                  : true

              const combinedRegionsCountriesProvinces = [
                  ...(story.regions ? story.regions : []),
                  ...(story.countries ? story.countries : []),
                  ...(story.provinces ? story.provinces : []),
              ]
              const isMultipleRegionsCountriesProvinces = selectedRegions.length > 0
              const regionCountryProvinceCondition = isMultipleRegionsCountriesProvinces
                  ? combinedRegionsCountriesProvinces.length > 0 &&
                    combinedRegionsCountriesProvinces.some(com => selectedRegions.includes(com.name))
                  : true

              const combinedIndustrySectors = [
                  ...(story.industries ? story.industries : []),
                  ...(story.sectors ? story.sectors : []),
              ]
              const isMultipleindustriesSectors = selectedSectors.length > 0
              const industrySectorCondition = isMultipleindustriesSectors
                  ? combinedIndustrySectors.length > 0 &&
                    combinedIndustrySectors.some(com => selectedSectors.includes(com.name))
                  : true
              const isMultipletopics =
                  selectedSectors.length > 0 && allTopicsName.filter(acc => selectedSectors.includes(acc)).length > 0
              const topicCondition = isMultipletopics
                  ? story.topics && story.topics.some(reg => selectedSectors.includes(reg.name))
                  : true
              return (
                  sourceCondition &&
                  subcategoryCondition &&
                  companyCondition &&
                  topicCondition &&
                  regionCountryProvinceCondition &&
                  industrySectorCondition
              )
          })
        : []

    function filterStoriesFromData({ overallData }) {
        if (!overallData) return [] // If no data, return an empty array

        return overallData.filter(story => {
            // Source filter condition
            const sourceCondition = selectedSources.length > 0 ? selectedSources.includes(story.source_name) : true

            // Subcategory filter condition
            const subcategoryCondition =
                selectedSubCategories.length > 0
                    ? story.subcategories &&
                      story.subcategories.some(subcategory => selectedSubCategories.includes(subcategory.name))
                    : true

            // Company filter condition
            const companyCondition =
                selectedCompanies.length > 0
                    ? story.companies && story.companies.some(company => selectedCompanies.includes(company.name))
                    : true

            // Regions, Countries, and Provinces combined filter condition
            const combinedRegionsCountriesProvinces = [
                ...(story.regions || []),
                ...(story.countries || []),
                ...(story.provinces || []),
            ]
            const regionCountryProvinceCondition =
                selectedRegions.length > 0
                    ? combinedRegionsCountriesProvinces.some(region => selectedRegions.includes(region.name))
                    : true

            // Industry and Sector combined filter condition
            const combinedIndustrySectors = [...(story.industries || []), ...(story.sectors || [])]
            const industrySectorCondition =
                selectedSectors.length > 0
                    ? combinedIndustrySectors.some(industry => selectedSectors.includes(industry.name))
                    : true

            // Topic filter condition
            const isMultipleTopics =
                selectedSectors.length > 0 && allTopicsName.some(topic => selectedSectors.includes(topic))
            const topicCondition = isMultipleTopics
                ? story.topics && story.topics.some(topic => selectedSectors.includes(topic.name))
                : true

            // Combine all conditions
            return (
                sourceCondition &&
                subcategoryCondition &&
                companyCondition &&
                regionCountryProvinceCondition &&
                industrySectorCondition &&
                topicCondition
            )
        })
    }

    // Example Usage
    const filterParams = {
        overallData: basicSearchResponse.overallData,
    }

    const filteredStories = filterStoriesFromData(filterParams)

    useEffect(() => {
        const len =
            selectedSources.length +
            selectedRegions.length +
            selectedSubCategories.length +
            selectedCompanies.length +
            selectedSectors.length

        console.log('runedit', editOnly)

        if ((basicSearchInput.length > 0 || len > 0) && !editOnly) {
            if (basicSearchResponse.overallData && basicSearchResponse.overallData.length > 0) {
                setBasicSearchResponse(prevResponse => {
                    return {
                        ...prevResponse,
                        scroll_id: basicSearchResponse.scroll_id,
                        size: basicSearchResponse.size,
                        total: basicSearchResponse.total,
                        next: basicSearchResponse.next,
                        data: filteredStories,
                        overallData: basicSearchResponse.overallData,
                    }
                })
            } else {
                // setBasicSearchResponse({ data: [], next: null, overallData: [] })
                generateQuery(selectedOption)
            }
        }
    }, [selectedSources, selectedRegions, selectedSectors, selectedSubCategories, selectedCompanies])

    useEffect(() => {
        setIsLoader(true)
        getData('/news/report-request/', setReports)
        // getData('/news/search-query/', setAlerts)
        getData('/news/all-companies/', setAllCompanies)
        getData('/news/industries-sectors-topics/', setAllHierarchy)
        getData('/news/sources/', setAllSources)
        getData('/news/all-subcategories/', setAllSubCategories)

        axios.get('/news/categories/').then(response => {
            setAllCategories(response.data.results)
        })
        getCollection()
        getIndSecTopics()
        getRegions()
        getPracticeArea()
        getUsers()
        getAllCollections()
        getTemplates()
        getTopics()
        getAllFirms()
        getAllFirmUsers()
        getAuthUSer()
        axios.get(`/news/all-regions/`).then(response => {
            setAllRegions(response.data)
        })
    }, [])

    const uploadFile = async (fileToUpload, type) => {
        let formData = new FormData()
        formData.append('logo', fileToUpload)
        formData.append('name', fileToUpload.name)
        formData.append('type', type)
        axios
            .post('/news/upload/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    if (type === 'logo') {
                        setSelectedLogo(res.data.logo)
                        setLogoManualImage(false)
                        setLogoFile(null)
                    }
                    if (type === 'logo2') {
                        setSecondaryLogo(res.data.logo)
                        setLogo2ManualImage(false)
                        setLogo2File(null)
                    }
                    if (type === 'bg') {
                        setSelectedBg(res.data.logo)
                        setBgManualImage(false)
                        setBgFile(null)
                    }
                }
            })
    }

    useEffect(() => {
        async function uploadImages() {
            if (logoManualImage) {
                uploadFile(logoFile, 'logo')
            }
            if (bgManualImage) {
                uploadFile(bgFile, 'bg')
            }
            if (logo2ManualImage) {
                uploadFile(logo2File, 'logo2')
            }
        }
        if (activeStep === 3) {
            uploadImages()
        }
    }, [activeStep])

    useEffect(() => {
        setDefaultData(allCompanies)
        flattenCategories()
    }, [allCompanies])

    useEffect(() => {
        getAlerts()
    }, [collectionQueries])

    const handleChangeRowsPerPage = (currentRowsPerPage, page) => {
        setRowsPerPage(prevState => ({
            ...prevState,
            [page]: currentRowsPerPage,
        }))
    }

    const getAllFirms = () => {
        axios.get('/analyst/firms/').then(response => {
            setAllFirms(
                response.data.firms.map(val => {
                    return {
                        label: val.label,
                        value: val.value,
                    }
                })
            )
        })
    }

    const getAuthUSer = () => {
        axios.get('/accounts/auth/user/').then(res => {
            setUserReceiveDetailedReport(res.data.receive_detailed_report)
        })
    }

    const getAllFirmUsers = async () => {
        await axios
            .get('/endusers/firm-users/')
            .then(res => {
                setAllFirmUsers(res.data)
            })
            .catch(err => console.log(err))
    }

    const handleTemplateSelected = (event, template) => {
        setSelectedPreviewTemplate(template)
        if (template !== null) {
            setIntroText(template.introtext)
            setSubjectLine(template.subject)
            setPrimaryColor(template.primary_color)
            setSecondaryColor(template.secondary_color)
            setTitle(template.title)
            setSelectedLogo(template.logo)
            setLogoManualImage(false)
            setFileName(template.logoname)
            setSecondaryLogo(template.secondary_logo)
            setLogo2ManualImage(false)
            setsecFileName(template.seclogoname)
            setBgManualImage(false)
            setBgFileName(template.bgname)
            setSelectedBg(template.background_image)
            setActiveStep(2)
        }
    }
    const setLogo = e => {
        if (e.target.files[0]) {
            setSelectedLogo(URL.createObjectURL(e.target.files[0]))
            setLogoFile(e.target.files[0])
            setLogoManualImage(true)
            setFileName(e.target.files[0].name)
        }
    }

    const setsecondaryLogo = e => {
        if (e.target.files[0]) {
            setSecondaryLogo(URL.createObjectURL(e.target.files[0]))
            setLogo2File(e.target.files[0])
            setLogo2ManualImage(true)
            setsecFileName(e.target.files[0].name)
        }
    }

    const setBackgroundImage = e => {
        if (e.target.files[0]) {
            setSelectedBg(URL.createObjectURL(e.target.files[0]))
            setBgFile(e.target.files[0])
            setBgManualImage(true)
            setBgFileName(e.target.files[0].name)
        }
    }

    const setSize = (width, height, logo) => {
        if (logo === 'primary') {
            setLogoSize({ width: width, height: height })
        }
        if (logo === 'secondary') {
            setSecLogoSize({ width: width, height: height })
        }
    }

    const setNewsCategory = data => {
        setCategorizedNews(data)
    }

    function resetPrimaryLogo() {
        setFileName('')
        setSelectedLogo('')
        setLogoFile(null)
        setLogoManualImage(false)
    }

    function resetSecondaryLogo() {
        setsecFileName('')
        setSecondaryLogo('')
        setLogo2File(null)
        setLogo2ManualImage(false)
    }

    const toggleRow = row => {
        const selectedIndex = saveDummyData.findIndex(selectedRow => selectedRow.id === row.id)
        let newSelectedRows = []

        if (selectedIndex === -1) {
            newSelectedRows = [...saveDummyData, row]
        } else if (selectedIndex === 0) {
            newSelectedRows = [...saveDummyData.slice(1)]
        } else if (selectedIndex === saveDummyData.length - 1) {
            newSelectedRows = [...saveDummyData.slice(0, -1)]
        } else if (selectedIndex > 0) {
            newSelectedRows = [...saveDummyData.slice(0, selectedIndex), ...saveDummyData.slice(selectedIndex + 1)]
        }

        setSaveDummyData(newSelectedRows)
    }

    const getTopics = () => {
        axios.get('/news/topics/').then(response => {
            let remappedTopics = response.data.map(item => {
                return { value: item.id, label: item.name }
            })
            remappedTopics = lo_.orderBy(remappedTopics, ['label'], ['asc'])

            setAllTopics(remappedTopics)
        })
    }

    const getAlerts = () => {
        axios
            .get('/news/search-query/')
            .then(res => {
                const savedData = res.data.filter(
                    val =>
                        val.query_string === null ||
                        (val.query_string === null && val.quick_search_query === null && val.query === null)
                )

                setAlerts(savedData)
            })
            .catch(err => console.log(err))
    }

    const handleLoadQuick = () => {
        // const relevanceResult = 110 - Number(selectedRelavance)
        // const aplhaResult = Number(selectedAlpha)
        setLoadMoreLoader(true)
        let data = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from: selectedDatefrom,
                    to: selectedDateTo,
                },
                sectors: {
                    operator: 'IS',
                    values: industryDrop,
                },
                company: {
                    operator: 'IS',
                    values: companyDrop,
                },
                category: {
                    operator: 'IS',
                    values: [...categoryDrop, ...selectedSubCategories],
                },
                topics: {
                    operator: 'IS',
                    values: topicDrop,
                },
            },
            next: basicSearchResponse.next,
            relevance_cutoff: calculateCutOff(selectedOption, customDate),
            semantic_preference: 0.5,
            // type: loadmoreFilter.value === null ? 'level0' : 'level2',
            type: 'level0',
        }
        if (basicSearchInput !== '') {
            data.query = loadmoreFilter.value ? loadmoreFilter.value : basicSearchInput
            data.prompt_type =
                loadmoreFilter.type === 'company'
                    ? 'Company Quick Search News Validation'
                    : loadmoreFilter.type === undefined ||
                      loadmoreFilter.type === null ||
                      loadmoreFilter.type === 'category'
                    ? 'Quick Search News Validation'
                    : 'Sector Quick Search News Validation'
        }

        if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            data.filters[loadmoreFilter.type] = {
                operator: 'IS',
                values: [loadmoreFilter.value],
            }
        }
        // if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
        //     data.non_entity_query = loadmoreFilter.value
        // }

        data.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSectorsName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
            industries: allIndustriesName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
            companies: [...selectedCompanies, ...companyDrop],
            topics: allTopicsName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
            subcategories: [...selectedSubCategories, ...categoryDrop],
            source_name: selectedSources,
        }

        const savedFilters = [...topicDrop, ...companyDrop, ...industryDrop, ...categoryDrop]

        const isFilterExist =
            savedFilters.length > 0 && savedFilters.filter(val => val === basicSearchInput).length === 1

        if (basicSearchResponse.next !== null) {
            if (
                basicSearchInput === '' ||
                loadmoreFilter.value ||
                (basicSearchInput !== '' && isFilterExist) ||
                (basicSearchInput === '' && filterLength > 0)
            ) {
                let data = {
                    next: basicSearchResponse.next,
                    size: basicSearchResponse.size,
                    total: basicSearchResponse.total,
                    scroll_id: basicSearchResponse.scroll_id,
                }
                if (token) {
                    token.cancel('Operation canceled due to new request.')
                }
                const newCancelSource = axios.CancelToken.source()
                setToken(newCancelSource)

                axios
                    .post('/news/scroll/', data, { cancelToken: newCancelSource.token })
                    .then(response => {
                        let newsBefore = [...basicSearchResponse.data, ...response.data.data]
                        let mergeUniqueData = [
                            ...new Map(
                                [...basicSearchResponse.overallData, ...response.data.data].map(item => [item.id, item])
                            ).values(),
                        ]
                        const filterParams = {
                            overallData: mergeUniqueData,
                        }

                        const filteredStories = filterStoriesFromData(filterParams)
                        // setBasicSearchResponse(prevResponse => {
                        //     return {
                        //         ...prevResponse,
                        //         data: newsBefore,
                        //         total: response.data.total,
                        //         next: response.data.next,
                        //         scroll_id: response.data.scroll_id,
                        //         size: response.data.size,
                        //     }
                        // })
                        const finalTotal =
                            basicSearchResponse.total === response.data.next
                                ? mergeUniqueData.length
                                : response.data.total

                        setBasicSearchResponse(prevResponse => {
                            return {
                                ...prevResponse,
                                data: filteredStories,
                                total: finalTotal,
                                next: response.data.next,
                                scroll_id: response.data.scroll_id,
                                size: response.data.size,
                                overallData: [...basicSearchResponse.overallData, ...response.data.data],
                            }
                        })
                    })
                    .catch(err => {
                        if (axios.isCancel(err)) {
                            console.log('POST Request canceled:', err.message)
                            setIsLoading(false)
                            setProgressContent(null)
                            clearTimeouts()
                            setLoadMoreLoader(false)
                            setIsLoading(true)
                        } else {
                            setIsLoading(false)
                            console.log('POST err:', err.message)
                            setProgressContent(null)
                            setLoadMoreLoader(false)
                            clearTimeouts()
                        }

                        console.log(err)
                    })
                    .finally(() => {
                        // setIsLoading(false)
                        setLoadMoreLoader(false)
                    })
            } else {
                // axios
                //     .post('/semantic/quick-search/', data)
                //     .then(response => {
                //         let newsBefore = [
                //             ...new Map(
                //                 [...basicSearchResponse.data, ...response.data.data].map(item => [item.id, item])
                //             ).values(),
                //         ]

                //         setBasicSearchResponse({
                //             // total: newsBefore.length,
                //             total: response.data.total,
                //             next: response.data.next,
                //             data: newsBefore,
                //         })
                //     })
                //     .finally(() => {
                //         setIsLoading(false)
                //         setLoadMoreLoader(false)
                //     })

                if (basicSearchResponse.next < basicSearchResponse.total) {
                    // Simulate fetching the next 20 items
                    const nextItems = basicSearchResponse.overallData.slice(
                        basicSearchResponse.next,
                        basicSearchResponse.next + 30
                    )

                    setTimeout(() => {
                        // Update the response with the new items
                        setBasicSearchResponse(prevState => ({
                            ...prevState,
                            data: [...new Map([...prevState.data, ...nextItems].map(item => [item.id, item])).values()],
                            next: prevState.next + 30, // Increment the "next" pointer
                        }))
                        setIsLoading(false)
                        setLoadMoreLoader(false)
                    }, 2000)
                } else {
                    setIsLoading(false)
                    setLoadMoreLoader(false)
                }
            }
        } else {
            setIsLoading(false)
            setLoadMoreLoader(false)
        }
    }

    const getCollection = () => {
        axios
            .get('/news/collection/')
            .then(res => {
                const data = res.data.collections.map(collection => ({
                    ...collection,
                    queries: collection.queries.filter(query => query.query_string === null),
                }))
                setCollectionQueries(data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoader(false)
            })
    }

    const getUsers = () => {
        axios.get('/endusers/firm-users/').then(res => {
            const filter = [userEmail]
            filter.forEach(email => {
                const existingUser = res.data.find(user => user.email === email)

                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }

                    res.data.push(newUser)
                }
            })
            res.data.sort((a, b) => (a.email === userEmail ? -1 : b.email === userEmail ? 1 : 0))

            setUsers(res.data)
        })
    }

    const scheduling = row => {
        setScheduleContent(row)
        setScheduleModal(!scheduleModel)
        setTimeout(() => {
            setIsEnabled(row.scheduled)
            const currentTemplate = templates.filter(val => val.value === row.template)
            if (currentTemplate.length > 0) {
                setSelectedTemplate(currentTemplate[0])
            } else {
                setSelectedTemplate(null)
            }
            if (row.section) {
                const currentsection = sections.filter(val => val.value === row.section)
                setSelectedSection(currentsection[0])
            } else {
                setSelectedSection(null)
            }
            if (row.has_section) {
                setIncludeSection(true)
            } else {
                setIncludeSection(false)
            }
            if (row.include_subcategory) {
                setIncludeBusiness(true)
            } else {
                setIncludeBusiness(false)
            }
            if (row.subject) {
                if (row.subject !== '') {
                    setEmailSubject(row.subject)
                } else {
                    setEmailSubject(`${row.query_name + '-insights'}`)
                }
            } else {
                setEmailSubject(`${row.query_name + '-insights'}`)
            }
            if (row.recipients) {
                const resultArray = row.recipients.split(',')

                const filter = resultArray

                filter.forEach(email => {
                    const existingUser = users.find(user => user.email === email)

                    if (!existingUser) {
                        const newUser = {
                            id: email,
                            email: email,
                            full_name: email,
                        }

                        users.push(newUser)
                    }
                })

                const currentUser = users.filter(val => resultArray.includes(val.email))

                if (currentUser.length > 0) {
                    setSelectedUsers(currentUser)
                }
            } else {
                setSelectedUsers([])
            }
            if (row.cron_expression) {
                setCronTabValue(LastCronValue(row.cron_expression, '+'))
            } else {
                setCronTabValue('0 0 * * 1')
            }
            if (row.quick_search_query) {
                setSelectedLevel('level0')
            } else {
                setSelectedLevel('level2')
            }
            if (row.quick_search_query) {
                setSelectedLevel('level0')
            } else {
                setSelectedLevel('level2')
            }
        }, scheduleModel)
    }

    const getTemplates = () => {
        axios.get('/endusers/templates/').then(response => {
            setTemplates(
                response.data
                    .filter(each => each.custom_template === false)
                    .map(each => {
                        return {
                            value: each.id,
                            label: each.name,
                            imageUrl: each.thumbnail,
                            has_sections: each.has_sections,
                        }
                    })
            )
        })
    }

    const getPracticeArea = () => {
        axios.get('/news/practice-areas-subcategories/').then(response => {
            setPracticeSubCategory(
                response.data.results.map((item, index) => ({
                    ...item,
                    id: 'Cat_' + item.id + index,
                    subcategories: item.subcategories.map((it, i) => ({
                        ...it,
                        id: 'Sub_' + it.id + index + i,
                    })),
                }))
            )
        })
    }

    const getRegions = () => {
        axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)

            const result = sortedData.flatMap(regions => [
                { value: regions.id, label: regions.name, type: 'region' },
                ...regions.countries.flatMap(country => [
                    { value: country.id, label: country.name, type: 'country' },
                    ...country.provinces.map(province => ({
                        value: province.id,
                        label: province.name,
                        type: 'province',
                    })),
                ]),
            ])

            setCombinedRegCouPro(result)
        })
    }

    const getIndSecTopics = () => {
        axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            setAllIndustries(updatedIndustries)
        })
    }

    const saveChanges = row => {
        const payload = {
            id: row.id,
            query: row.query,
            query_name: row.query_name,
            template: selectedTemplate && selectedTemplate.value ? selectedTemplate.value : null,
            user_recipients: selectedUsers.map(val => val.id).filter(val => !isNaN(val) && typeof val === 'number'),
            last_runtime: row.last_runtime,
            scheduled: isEnabled,
            cron_expression: LastCronValue(cronTabValue, '-'),
            recipients: selectedUsers.map(val => val.email),
            newsletter: 'saved_search_newsletter',
            section:
                selectedTemplate && selectedTemplate.has_sections && selectedSection ? selectedSection.value : null,
            query_string: row.query_string,
            quick_search_query: row.quick_search_query,
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: row.quick_search_query ? 'level0' : 'level2',
            // curation_level: row.curation_level ? row.curation_level : 'level0',
            // curation_level: 'level0',
        }
        if (isEnabled) {
            if (
                selectedTemplate &&
                selectedTemplate.value &&
                selectedUsers.length > 0 &&
                emailSubject !== ''
                //  &&
                // ((selectedTemplate.has_sections && selectedSection) || !selectedTemplate.has_sections)
            ) {
                if (row.apscheduler_id !== null) {
                    payload.apscheduler_id = row.apscheduler_id
                }
                axios
                    .put(`/news/search-query/${row.id}/`, payload)
                    .then(res => {
                        toast('Query has been successfully updated')
                        setScheduleModal(!scheduleModel)
                        // getSearchQueries()
                        getCollection()
                    })
                    .catch(err => console.log(err))
            } else {
                toast.warn('Please fill out all the fields.')
            }
        } else {
            if (row.apscheduler_id !== null) {
                payload.apscheduler_id = row.apscheduler_id
            }
            axios
                .put(`/news/search-query/${row.id}/`, payload)
                .then(res => {
                    toast('Query has been successfully updated')
                    setScheduleModal(!scheduleModel)
                    // getSearchQueries()
                    getCollection()
                })
                .catch(err => console.log(err))
        }
    }

    const getAllCollections = () => {
        axios.get('/news/collection/').then(response => {
            const data = response.data.collections.map(res => {
                return {
                    value: res.id,
                    label: res.name,
                    description: res.description,
                    users: res.users,
                    creator: res.creator,
                    firm: res.firm,
                    record_date: res.record_date,
                    is_active: res.is_active,
                    shared: res.shared,
                    thumbnail: res.thumbnail,
                }
            })

            const sortedOptions = [...data].sort((a, b) => a.label.localeCompare(b.label))

            setAllCollectionName(sortedOptions)
        })
    }

    const flattenCategories = () => {
        const flattened = []

        const flattenHelper = category => {
            flattened.push({ id: category.id, name: category.name })
            if (category.subcategories && category.subcategories.length > 0) {
                category.subcategories.forEach(subcategory => {
                    flattenHelper(subcategory)
                })
            }
        }

        practiceSubCategory.forEach(category => {
            flattenHelper(category)
        })

        setShowBusinessEvents([...new Set(flattened)])

        return [...new Set(flattened)]
    }

    const getData = (api, setState) => {
        axios.get(api).then(res => setState(res.data))
    }

    let timeoutIds = [] // Store timeout IDs to manage clearing

    const clearTimeouts = () => {
        timeoutIds.forEach(id => clearTimeout(id))
        timeoutIds = []
    }

    const QuickprogressSteps = ['Validating news results', 'Almost done']

    const progressContents = (responseTime = 7000, completionTime = 7000) => {
        const stepsArray = QuickprogressSteps
        clearTimeouts()

        stepsArray.forEach((step, index) => {
            const timeoutId = setTimeout(() => {
                const percentage = Math.round(((index + 1) / stepsArray.length) * 100)
                const displayPercentage = percentage > 94 ? 94 : percentage
                const steps = `${step}...(${displayPercentage}%)`
                setProgressContent(steps)
            }, index * (responseTime / stepsArray.length))
            timeoutIds.push(timeoutId) // Save the timeout ID
        })
    }

    const customOnchange = ranges => {
        const startDate = ranges.selection.startDate
        const endDate = ranges.selection.endDate

        const formatDate = { startDate: startDate, endDate: endDate }
        setcustomDate(formatDate)
    }

    function dateFormatChanges(parsedDate) {
        const year = parsedDate.getFullYear()
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0')
        const day = parsedDate
            .getDate()
            .toString()
            .padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`
        return formattedDateString
    }

    const advanceSearch = () => {
        setIsLoading(true)

        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })

        const completionTime = 7000
        const responseTime = 7000
        setProgressContent('Working on it...')
        // const initialTimeoutId = setTimeout(() => {
        //     progressContents(responseTime, completionTime)
        //     setIsLoading(true)
        // }, 100)
        // timeoutIds.push(initialTimeoutId)
        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)
        // setSortBy(null)
        // setNewsSourceFilter([])
        // setDateFilter('59')
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom !== '' ? selectedQueries.articleDateFrom : getYMD(startDate),
                    to: selectedQueries.articleDateTo !== '' ? selectedQueries.articleDateTo : getYMD(new Date()),
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(value => value.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }
        axios
            .post(`/news/level2/search/`, payLoad, { cancelToken: newCancelSource.token })
            .then(res => {
                setAdvanceResponse(res)
                var startCount =
                    selectedQueries.articleDateFrom !== '' ? new Date(selectedQueries.articleDateFrom) : startDate
                var endCount =
                    selectedQueries.articleDateTo !== '' ? new Date(selectedQueries.articleDateTo) : new Date()

                // Calculate the difference in milliseconds
                var diffInTime = endCount.getTime() - startCount.getTime()

                // Convert the difference from milliseconds to days and round down to the nearest whole number
                var diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24))
                setAdvanceCurrentDate(Number(isNaN(diffInDays)) === NaN ? 29 : diffInDays)
                setIsLoading(false)
                setProgressContent(null)
                clearTimeouts()
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setIsLoading(false)
                    setProgressContent(null)
                    clearTimeouts()
                } else {
                    setIsLoading(false)
                    console.log('POST err:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                }
            })
    }

    const handleLoadMore = () => {
        let data = {
            next: advanceResponse.data.next,
            size: advanceResponse.data.size,
            total: advanceResponse.data.total,
            scroll_id: advanceResponse.data.scroll_id,
        }
        axios.post('/news/scroll/', data).then(response => {
            let newsBefore = [...advanceResponse.data.data, ...response.data.data]
            setAdvanceResponse({
                ...response,
                data: {
                    total: response.data.total,
                    next: response.data.next,
                    scroll_id: response.data.scroll_id,
                    data: newsBefore,
                },
            })
        })
    }

    const clearFilters = () => {
        setEditQuery(null)
        setEditPage(false)
        setSubTab(0)
        setCollectionDescription('')
        setCollectionId(null)
        setCollectionName(null)
        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })
        setRunAllSearches({ data: [] })
        setEditId('')
        setActiveTab(0)
        getAlerts()
        getCollection()
        setSelectedCollection(null)
        setEditCron(null)
        setEditOnly(false)
        setApproxLength(false)
    }

    const clearQuickFilters = () => {
        setSelectedSubCategories([])
        setSelectedCompanies([])
        setSelectedSectors([])
        setSelectedSources([])
        setSelectedRegions([])
        setSelectedOption('29')
        setCurrentDate(30)
        setAdvanceCurrentDate(30)
        setEditOnly(false)
        setApproxLength(false)
    }

    const calculateCutOff = (date, customDate) => {
        const selectedNumber = Number(date)
        const daysBetween = calculateDaysBetween(customDate.startDate, customDate.endDate)

        const value = isNaN(selectedNumber) && date === 'custom' ? daysBetween : selectedNumber

        if (value <= 7) return 3
        if (value < 30) return 13
        if (value < 60) return 23
        if (value < 90) return 33
        return 43
    }

    const numberMapping = {
        '1': '1',
        '2': '2',
        '7': '6',
        '30': '29',
        '60': '59',
        '90': '89',
        '180': '179',
        '365': '364',
    }

    const cancelRequest = () => {
        if (token) {
            token.cancel('Request canceled by user.')
            setToken(null)
            setIsLoading(false)
            setLoadMoreLoader(false)
            setProgressContent(null)
            clearTimeouts()
            console.log('API request manually canceled')
        }
    }

    const getduplicateUpdates = async (data, cancelToken) => {
        const newsIds = data && data.data ? data.data.map(v => v.id) : []
        const batchSize = 50 // Number of items per batch

        // Create a function to process a batch
        const processBatch = async batch => {
            const duplicatePayload = { news_ids: batch }
            try {
                const res = await axios.post('/semantic/duplicate_news/', duplicatePayload, cancelToken)
                return res.data // Return duplicate news for the batch
            } catch (error) {
                console.error('Error processing batch:', error)
                return {} // Return empty in case of an error
            }
        }

        // Split newsIds into batches
        const batches = []
        for (let i = 0; i < newsIds.length; i += batchSize) {
            batches.push(newsIds.slice(i, i + batchSize))
        }

        // Process all batches sequentially
        const allDuplicateNews = {}
        for (const batch of batches) {
            const duplicateNews = await processBatch(batch)
            Object.assign(allDuplicateNews, duplicateNews) // Merge batch results
        }

        // Update the state with all processed duplicates
        setBasicSearchResponse(prevResponse => {
            // Convert previous news data to a map for easier comparison
            const prevNewsState = ((prevResponse.data && prevResponse.data) || []).reduce((acc, item) => {
                acc[item.id] = item
                return acc
            }, {})

            // Create a new updated news state
            const updatedNewsState = Object.values(data.data).reduce((acc, item) => {
                const duplicates = allDuplicateNews[item.id] || []
                const updatedItem = {
                    ...item,
                    duplicate_news: duplicates,
                }

                // Compare and only update if there's a change
                if (JSON.stringify(prevNewsState[item.id]) !== JSON.stringify(updatedItem)) {
                    acc[item.id] = updatedItem
                } else {
                    acc[item.id] = prevNewsState[item.id] // Keep the previous state if unchanged
                }
                return acc
            }, {})

            // Return the new response with updated data
            return {
                ...prevResponse,
                data: Object.values(updatedNewsState),
                size: data.size,
                next: data.next,
                total: data.total,
            }
        })
    }

    const weviateSearch = (dropdownValue, type) => {
        setIsLoading(true)

        const completionTime = 10000
        const responseTime = 10000

        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)
        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })
        setRunAllSearches({ data: [] })
        setBasicSearchDrop(false)
        // setQuickSummary(null)
        // setShowFullText(false)
        // setSearchName('')
        // setAdvanceResponse({ data: [], next: null })
        var startDate = new Date()
        var endDate = new Date()

        const date = selectedOption === '1' || selectedOption === 1 ? 0 : selectedOption
        setCurrentDate(Number(selectedOption) + 1)

        if (date !== 'custom') {
            startDate.setDate(startDate.getDate() - date)
            startDate = getYMD(startDate)
            setSelectedDateFrom(startDate)
            endDate = getYMD(endDate)
            setSelectedDateTo(endDate)
        }

        const keyName =
            type === 'topic'
                ? 'topics'
                : type === 'SubCategory'
                ? 'category'
                : type === 'Company'
                ? 'company'
                : 'sectors'
        // setIsLoading(true)
        if (dropdownValue !== undefined) {
            setLoadMoreFilter({ value: dropdownValue, type: keyName })
        } else {
            setLoadMoreFilter({ value: null, type: null })
        }

        const dropbasic = dropdownValue !== undefined ? dropdownValue : basicSearchInput

        const industryDrop =
            allIndustries.filter(val => val.label === dropbasic).length > 0 && dropbasic !== ''
                ? [
                      ...new Set(
                          allIndustries
                              .filter(val => val.label === dropbasic)
                              .map(val => val.label)
                              .concat(selectedSectors)
                      ),
                  ]
                : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc))
        const companyDrop =
            defaultData.filter(val => val.label === dropbasic).length > 0 && dropbasic !== ''
                ? [
                      ...new Set(
                          defaultData
                              .filter(val => val.label === dropbasic)
                              .map(val => val.label)
                              .concat(selectedCompanies)
                      ),
                  ]
                : selectedCompanies
        const categoryDrop =
            allSubCategories.filter(val => val.label === dropbasic).length > 0 && dropbasic !== ''
                ? [
                      ...new Set(
                          allSubCategories
                              .filter(val => val.label === dropbasic)
                              .map(val => val.label)
                              .concat(selectedCategories)
                      ),
                  ]
                : [
                      ...new Set(
                          practiceSubCategory.reduce((allitems, item) => {
                              const selList = allitems
                              if (selectedCategories.includes(item.id)) {
                                  if (!selList.includes(item.name)) {
                                      selList.push(item.name)
                                  }
                              }
                              item.subcategories.forEach(subItem => {
                                  if (
                                      Boolean(selectedCategories.includes(subItem.id)) &&
                                      !selList.includes(subItem.name)
                                  ) {
                                      selList.push(subItem.name)
                                  }
                              })
                              return selList
                          }, [])
                      ),
                  ]
        const topicDrop =
            allTopics.filter(val => val.label === dropbasic).length > 0 && dropbasic !== ''
                ? [
                      ...new Set(
                          allTopics
                              .filter(val => val.label === dropbasic)
                              .map(val => val.label)
                              .concat(selectedSectors)
                      ),
                  ]
                : allTopicsName.filter(acc => selectedSectors.includes(acc))

        const payload = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from:
                        date === 'custom' && customDate.startDate !== null
                            ? dateFormatChanges(new Date(customDate.startDate))
                            : startDate,
                    to:
                        date === 'custom' && customDate.endDate !== null
                            ? dateFormatChanges(new Date(customDate.endDate))
                            : endDate,
                },
                sectors: {
                    operator: 'IS',
                    values: industryDrop,
                },
                company: {
                    operator: 'IS',
                    values: companyDrop,
                },
                category: {
                    operator: 'IS',
                    values: [...categoryDrop, ...selectedSubCategories],
                },
                topics: {
                    operator: 'IS',
                    values: topicDrop,
                },
            },
            relevance_cutoff: calculateCutOff(selectedOption, customDate),

            semantic_preference: 0.5,
            // type: dropdownValue !== undefined ? 'level2' : 'level0',
            type: 'level0',
        }
        if (basicSearchInput !== '') {
            payload.query = dropdownValue ? dropdownValue : basicSearchInput
            // setSearchName(basicSearchInput)
            payload.prompt_type =
                type === 'Company'
                    ? 'Company Quick Search News Validation'
                    : type === undefined || type === 'SubCategory'
                    ? 'Quick Search News Validation'
                    : 'Sector Quick Search News Validation'
        }
        if (basicSearchInput !== '' && type !== undefined) {
            payload.filters[keyName] = {
                operator: 'IS',
                values: [dropdownValue],
            }
        }

        // if (basicSearchInput !== '' && dropdownValue && dropdownValue.length > 0) {
        //     payload.non_entity_query = dropdownValue
        //     // setSearchName(dropdownValue)
        // }

        payload.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSectorsName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
            industries: allIndustriesName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
            companies: [...selectedCompanies, ...companyDrop],
            topics: allTopicsName.filter(acc => [...topicDrop, ...selectedSectors].includes(acc)),
            subcategories: [...selectedSubCategories, ...categoryDrop],
            source_name: selectedSources,
        }

        const savedFilters = [...topicDrop, ...companyDrop, ...industryDrop, ...categoryDrop]

        const isFilterExist = savedFilters.length > 0 && savedFilters.filter(val => val === dropbasic).length === 1

        // const api =
        //     dropdownValue === undefined || dropdownValue === null
        //         ? '/semantic/quick-search/'
        //         : '/semantic/entities_tagged_news/'

        const isOpenSearch =
            basicSearchInput === '' ||
            dropdownValue ||
            (dropbasic !== '' && isFilterExist) ||
            (basicSearchInput === '' && filterLength > 0)
        let api

        if (isOpenSearch) {
            setProgressContent('Working on it...')
            setApproxLength(false)

            payload.search_type = 'advanced'
            payload.next = 0
            payload.size = 15
            api = `/news/level2/search/`
        } else {
            setProgressContent('Working on it...(5%)')
            setApproxLength(true)
            const initialTimeoutId = setTimeout(() => {
                progressContents(responseTime, completionTime)
                setIsLoading(true)
            }, 100)
            timeoutIds.push(initialTimeoutId)

            api = '/semantic/quick-search/'
        }

        axios
            .post(api, payload, { cancelToken: newCancelSource.token })
            .then(res => {
                // if (res.data.summary) {
                //     setQuickSummary(res.data.summary)
                // } else {
                //     setQuickSummary(null)
                // }

                if (isOpenSearch) {
                    const finalTotal = res.data.total === res.data.next ? res.data.data.length : res.data.total

                    setBasicSearchResponse({
                        total: finalTotal,
                        next: res.data.next,
                        data: res.data.data,
                        size: res.data.size,
                        scroll_id: res.data.scroll_id,
                    })
                } else {
                    const initialData = res.data.data.slice(0, 30)

                    setBasicSearchResponse({
                        total: res.data.total,
                        // next: 30, // Set the next pointer to 20
                        data: res.data.data,
                        size: res.data.size,
                        // scroll_id: res.data.scroll_id,
                        overallData: res.data.data,
                    })
                    // getduplicateUpdates(res.data, { cancelToken: newCancelSource.token })
                }
                if (res.data.message) {
                    setRelevantNews(true)
                } else {
                    setRelevantNews(false)
                }
                setIsLoading(false)
                setProgressContent(null)
                clearTimeouts()
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setIsLoading(false)
                    setProgressContent(null)
                    clearTimeouts()
                } else {
                    setIsLoading(false)
                    console.log('POST err:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                }
            })
            .finally(() => {
                setFromFilter([])
                setProgressContent(null)
            })
    }

    const saveSemanticDummy = () => {
        if (saveQueryName !== '') {
            const payload = {
                query_name: saveQueryName,
                quick_search_query: basicSearchInput,
                query: {
                    filters: {
                        source: {
                            operator: 'IS',
                            values: selectedSources,
                        },
                        countries: {
                            operator: 'IS',
                            values: selectedRegions,
                        },
                        company: {
                            operator: 'IS',
                            values: companyDrop,
                        },
                        sectors: {
                            operator: 'IS',
                            values: industryDrop,
                        },
                        category: {
                            operator: 'IS',
                            values: [...categoryDrop, ...selectedSubCategories],
                        },
                        topics: {
                            operator: 'IS',
                            values: topicDrop,
                        },
                        date: selectedOption,
                        // relavance: selectedRelavance,
                    },
                },
                section: selectedSection,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                emailSubject: emailSubject,
                query_string: null,
                // curation_level: loadmoreFilter.value !== null ? 'level2' : 'level0',
                curation_level: 'level0',
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(val => val.id) : [],
            }

            if (selectedOption === 'custom') {
                payload.query.filters.custom_date = {
                    from: customDate.startDate,
                    to: customDate.endDate,
                }
            }

            setSaveDummyData([...saveDummyData, ...[payload]])

            setSaveSub(false)
            setBasicSearchInput('')
            setSaveQueryName('')
            setDummyDataModal(false)
            setBasicSearchResponse({
                data: [],
                next: null,
            })
            setIsLoading(false)
        }
    }

    const editSemanticDummy = () => {
        if (saveQueryName !== '') {
            const payload = {
                query_name: saveQueryName,
                quick_search_query: basicSearchInput,
                query: {
                    filters: {
                        source: {
                            operator: 'IS',
                            values: selectedSources,
                        },
                        countries: {
                            operator: 'IS',
                            values: selectedRegions,
                        },
                        company: {
                            operator: 'IS',
                            values:
                                loadmoreFilter.type === 'company'
                                    ? [...selectedCompanies, ...[loadmoreFilter.value]]
                                    : selectedCompanies,
                        },
                        sectors: {
                            operator: 'IS',
                            values:
                                allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                                basicSearchInput !== ''
                                    ? [
                                          ...new Set(
                                              allIndustries
                                                  .filter(val => val.label === basicSearchInput)
                                                  .map(val => val.label)
                                                  .concat(selectedSectors)
                                          ),
                                      ]
                                    : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                        },
                        category: {
                            operator: 'IS',
                            values:
                                allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                                basicSearchInput !== ''
                                    ? [
                                          ...new Set(
                                              allSubCategories
                                                  .filter(val => val.label === basicSearchInput)
                                                  .map(val => val.label)
                                                  .concat(selectedCategories)
                                          ),
                                      ]
                                    : [
                                          ...new Set(
                                              practiceSubCategory.reduce((allitems, item) => {
                                                  const selList = allitems

                                                  if (selectedCategories.includes(item.id)) {
                                                      if (!selList.includes(item.name)) {
                                                          selList.push(item.name)
                                                      }
                                                  }
                                                  item.subcategories.forEach(subItem => {
                                                      if (
                                                          Boolean(selectedCategories.includes(subItem.id)) &&
                                                          !selList.includes(subItem.name)
                                                      ) {
                                                          selList.push(subItem.name)
                                                      }
                                                  })

                                                  return selList
                                              }, [])
                                          ),
                                      ],
                        },
                        topics: {
                            operator: 'IS',
                            values:
                                allTopics.filter(val => val.label === basicSearchInput).length > 0 &&
                                basicSearchInput !== ''
                                    ? [
                                          ...new Set(
                                              allTopics
                                                  .filter(val => val.label === basicSearchInput)
                                                  .map(val => val.label)
                                                  .concat(selectedSectors)
                                          ),
                                      ]
                                    : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                        },
                        date: selectedOption,
                        // relavance: selectedRelavance,
                    },
                },
                section: selectedSection,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                emailSubject: emailSubject,
                query_string: null,
                // curation_level: loadmoreFilter.value !== null ? 'level2' : 'level0',
                curation_level: 'level0',
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(val => val.id) : [],
            }
            if (selectedOption === 'custom') {
                payload.query.filters.custom_date = {
                    from: customDate.startDate,
                    to: customDate.endDate,
                }
            }
            const newData = [...saveDummyData]
            newData[dummyEditQuery] = payload

            setSaveDummyData(newData)
            setSaveSub(false)
            setBasicSearchInput('')
            setSaveQueryName('')
            setDummyDataModal(false)
            setBasicSearchResponse({
                data: [],
                next: null,
            })
            setAdvanceResponse({
                data: [],
                next: null,
            })
            setIsLoading(false)
        }
    }

    const saveAdvanceDummy = () => {
        if (saveQueryName !== '') {
            const payLoad = {
                filters: {
                    source: {
                        operator: selectedQueries.sourceOperator,
                        values: selectedQueries.source.map(val => val.value),
                    },
                    articleDate: {
                        operator: selectedQueries.articleOperator,
                        from:
                            selectedQueries.articleDateFrom !== ''
                                ? selectedQueries.articleDateFrom
                                : getYMD(startDate),
                        to: selectedQueries.articleDateTo !== '' ? selectedQueries.articleDateTo : getYMD(new Date()),
                    },
                    countries: {
                        operator: selectedQueries.regionOperator,
                        values: selectedQueries.region.map(value => value.label),
                    },
                    sectors: {
                        operator: selectedQueries.industryOperator,
                        values: selectedQueries.sectors.map(val => val.label),
                    },
                    company: {
                        operator: selectedQueries.companyOperator,
                        values: selectedQueries.company.map(val => val.label),
                    },
                    category: {
                        operator: selectedQueries.categoryOperator,
                        values: selectedQueries.category.map(val => val.label),
                    },
                    topics: {
                        operator: selectedQueries.topicOperator,
                        values: selectedQueries.topic.map(val => val.label),
                    },
                    newsTitle: {
                        operator: selectedQueries.newsTitleOperator,
                        value: selectedQueries.newsTitle,
                        fuzziness: 3,
                    },
                    newsContent: {
                        operator: selectedQueries.newsContentOperator,
                        regex: selectedQueries.newsContent,
                    },
                },
                search_type: 'advanced',
                next: 0, // For Next Batch
                size: 15, // Batch size
            }
            const saveAdvanceSearchQuery = {
                query_name: saveQueryName,
                query: payLoad,
                section: selectedSection === null ? '' : selectedSection,
                recipients: selectedUsers.length === 0 ? '' : selectedUsers.map(val => val.email),
                subject: emailSubject,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                curation_level: 'level2',
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
                quick_search_query: null,
            }
            setSaveDummyData([...saveDummyData, ...[saveAdvanceSearchQuery]])
            setSaveSub(false)
            setBasicSearchInput('')
            setSaveQueryName('')
            setDummyDataModal(false)
            setBasicSearchResponse({
                data: [],
                next: null,
            })
            setAdvanceResponse({
                data: [],
                next: null,
            })
            setIsLoading(false)
            setSelectedQueries({
                ...selectedQueries,
                articleDateFrom: '',
                articleOperator: 'BETWEEN',
                articleDateTo: '',
                region: [],
                regionOperator: 'IS',
                sectors: [],
                industryOperator: 'IS',
                company: [],
                companyOperator: 'IS',
                category: [],
                categoryOperator: 'IS',
                topic: [],
                topicOperator: 'IS',
                newsTitle: '',
                newsTitleOperator: 'CONTAINS',
                newsContent: '',
                newsContentOperator: 'CONTAINS',
                source: [],
                sourceOperator: 'IS',
            })
        }
    }

    const editAdvanceDummy = () => {
        if (saveQueryName !== '') {
            const payLoad = {
                filters: {
                    source: {
                        operator: selectedQueries.sourceOperator,
                        values: selectedQueries.source.map(val => val.value),
                    },
                    articleDate: {
                        operator: selectedQueries.articleOperator,
                        from:
                            selectedQueries.articleDateFrom !== ''
                                ? selectedQueries.articleDateFrom
                                : getYMD(startDate),
                        to: selectedQueries.articleDateTo !== '' ? selectedQueries.articleDateTo : getYMD(new Date()),
                    },
                    countries: {
                        operator: selectedQueries.regionOperator,
                        values: selectedQueries.region.map(value => value.label),
                    },
                    sectors: {
                        operator: selectedQueries.industryOperator,
                        values: selectedQueries.sectors.map(val => val.label),
                    },
                    company: {
                        operator: selectedQueries.companyOperator,
                        values: selectedQueries.company.map(val => val.label),
                    },
                    category: {
                        operator: selectedQueries.categoryOperator,
                        values: selectedQueries.category.map(val => val.label),
                    },
                    topics: {
                        operator: selectedQueries.topicOperator,
                        values: selectedQueries.topic.map(val => val.label),
                    },
                    newsTitle: {
                        operator: selectedQueries.newsTitleOperator,
                        value: selectedQueries.newsTitle,
                        fuzziness: 3,
                    },
                    newsContent: {
                        operator: selectedQueries.newsContentOperator,
                        regex: selectedQueries.newsContent,
                    },
                },
                search_type: 'advanced',
                next: 0, // For Next Batch
                size: 15, // Batch size
            }
            const saveAdvanceSearchQuery = {
                query_name: saveQueryName,
                query: payLoad,
                section: selectedSection === null ? '' : selectedSection,
                recipients: selectedUsers.length === 0 ? '' : selectedUsers.map(val => val.email),
                subject: emailSubject,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                curation_level: 'level2',
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
                quick_search_query: null,
            }
            const newData = [...saveDummyData]
            newData[dummyEditQuery] = saveAdvanceSearchQuery

            setSaveDummyData(newData)
            setSaveSub(false)
            setBasicSearchInput('')
            setSaveQueryName('')
            setDummyDataModal(false)
            setBasicSearchResponse({
                data: [],
                next: null,
            })
            setAdvanceResponse({
                data: [],
                next: null,
            })
            setIsLoading(false)
            setSelectedQueries({
                ...selectedQueries,
                articleDateFrom: '',
                articleOperator: 'BETWEEN',
                articleDateTo: '',
                region: [],
                regionOperator: 'IS',
                sectors: [],
                industryOperator: 'IS',
                company: [],
                companyOperator: 'IS',
                category: [],
                categoryOperator: 'IS',
                topic: [],
                topicOperator: 'IS',
                newsTitle: '',
                newsTitleOperator: 'CONTAINS',
                newsContent: '',
                newsContentOperator: 'CONTAINS',
                source: [],
                sourceOperator: 'IS',
            })
        }
    }

    const editSemanticQuery = () => {
        const payload = {
            query_name: directRun ? editqueryName : saveQueryName,
            quick_search_query: basicSearchInput,
            query: {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    company: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'company'
                                ? [...selectedCompanies, ...[loadmoreFilter.value]]
                                : selectedCompanies,
                    },
                    sectors: {
                        operator: 'IS',
                        values:
                            // allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                            // basicSearchInput !== ''
                            //     ? [
                            //           ...new Set(
                            //               allIndustries
                            //                   .filter(val => val.label === basicSearchInput)
                            //                   .map(val => val.label)
                            //                   .concat(selectedSectors)
                            //           ),
                            //       ]
                            //     : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                            [
                                ...allSectorsName.filter(acc => selectedSectors.includes(acc)),
                                ...allIndustriesName.filter(acc => selectedSectors.includes(acc)),
                            ],
                    },
                    category: {
                        operator: 'IS',
                        values:
                            allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allSubCategories
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedSubCategories)
                                      ),
                                  ]
                                : selectedSubCategories,
                        // [
                        //       ...new Set(
                        //           practiceSubCategory.reduce((allitems, item) => {
                        //               const selList = allitems

                        //               if (selectedCategories.includes(item.id)) {
                        //                   if (!selList.includes(item.name)) {
                        //                       selList.push(item.name)
                        //                   }
                        //               }
                        //               item.subcategories.forEach(subItem => {
                        //                   if (
                        //                       Boolean(selectedCategories.includes(subItem.id)) &&
                        //                       !selList.includes(subItem.name)
                        //                   ) {
                        //                       selList.push(subItem.name)
                        //                   }
                        //               })

                        //               return selList
                        //           }, [])
                        //       ),
                        //   ],
                    },
                    topics: {
                        operator: 'IS',
                        values:
                            // allTopics.filter(val => val.label === basicSearchInput).length > 0 &&
                            // basicSearchInput !== ''
                            //     ? [
                            //           ...new Set(
                            //               allTopics
                            //                   .filter(val => val.label === basicSearchInput)
                            //                   .map(val => val.label)
                            //                   .concat(selectedSectors)
                            //           ),
                            //       ]
                            //     :
                            allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                    date: selectedOption,
                    // relavance: selectedRelavance,
                },
            },
            query_string: null,
            section: selectedSection,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            subject: emailSubject,
            recipients: selectedUsers.map(val => val.email),
            // curation_level: loadmoreFilter.value !== null ? 'level2' : 'level0',
            curation_level: 'level0',
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            collection: collectionName && collectionName.value ? collectionName.value : null,
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        if (selectedOption === 'custom') {
            payload.query.filters.custom_date = {
                from: customDate.startDate,
                to: customDate.endDate,
            }
        }

        const updateEditQuickQuery = () => {
            axios
                .put(`/news/search-query/${editId}/`, payload)
                .then(res => {
                    setEditPage(false)
                    toast('Query has been successfully updated')
                    setBasicSearchInput('')
                    setEditQueryName('')
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setSemanticSaveModal(false)
                    setCollectionDescription('')
                    setCollectionName(null)
                    setCollectionUsers([])
                    setCollectionModal(false)
                    getAllCollections()
                    getAlerts()
                    getCollection()
                    clearFilters()
                })
        }
        if (editqueryName !== '') {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            payload.collection = res.data.id
                            updateEditQuickQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                updateEditQuickQuery()
            }
        }
    }

    const generateQuery = date => {
        setIsLoading(true)

        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })
        setRunAllSearches({ data: [] })
        setCurrentDate(Number(date) + 1)
        const completionTime = 10000
        const responseTime = 10000

        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)
        // setQuickSummary(null)
        // setShowFullText(false)

        // setDateSection(false)
        if (date !== 'custom') {
            setcustomDate({
                startDate: subDays(new Date(), 29),
                endDate: new Date(),
            })
        }
        if (date === 1 || date === '1') {
            date = 0
        }
        const relevance_cutoff = Number(date)

        var startDate = new Date()
        var endDate = new Date()
        if (date !== 1) {
            startDate.setDate(startDate.getDate() - date)
            startDate = getYMD(startDate)
            setSelectedDateFrom(startDate)
            endDate = getYMD(endDate)
            setSelectedDateTo(endDate)
        } else {
            startDate = getYMD(startDate)
            setSelectedDateFrom(startDate)
            endDate = getYMD(endDate)
            setSelectedDateTo(endDate)
        }

        const payLoad = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources.map(val => val),
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from:
                        date === 'custom' && customDate.startDate !== null
                            ? dateFormatChanges(new Date(customDate.startDate))
                            : startDate,
                    to:
                        date === 'custom' && customDate.endDate !== null
                            ? dateFormatChanges(new Date(customDate.endDate))
                            : endDate,
                },
                sectors: {
                    operator: 'IS',
                    values: industryDrop,
                },
                company: {
                    operator: 'IS',
                    values: companyDrop,
                },
                category: {
                    operator: 'IS',
                    values: [...categoryDrop, ...selectedSubCategories],
                },
                topics: {
                    operator: 'IS',
                    values: topicDrop,
                },
            },
            relevance_cutoff: calculateCutOff(date, customDate),
            semantic_preference: 0.5,
            // type: loadmoreFilter.value !== null ? 'level2' : 'level0',
            type: 'level0',
        }

        if (basicSearchInput !== '') {
            payLoad.query = loadmoreFilter.value ? loadmoreFilter.value : basicSearchInput
            payLoad.prompt_type =
                loadmoreFilter.type === 'company'
                    ? 'Company Quick Search News Validation'
                    : loadmoreFilter.type === undefined ||
                      loadmoreFilter.type === null ||
                      loadmoreFilter.type === 'category'
                    ? 'Quick Search News Validation'
                    : 'Sector Quick Search News Validation'
        }

        if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            payLoad.filters[loadmoreFilter.type] = {
                operator: 'IS',
                values: [loadmoreFilter.value],
            }
        }
        // if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
        //     payLoad.non_entity_query = loadmoreFilter.value
        // }

        payLoad.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSectorsName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
            industries: allIndustriesName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
            // companies: [...selectedCompanies, ...(loadmoreFilter.type === 'company' ? [loadmoreFilter.value] : [])],
            companies: [...selectedCompanies, ...companyDrop],
            topics: allTopicsName.filter(acc => [...topicDrop, ...selectedSectors].includes(acc)),
            subcategories: [...selectedSubCategories, ...categoryDrop],
            source_name: selectedSources,
        }
        const savedFilters = [...topicDrop, ...companyDrop, ...industryDrop, ...categoryDrop]

        const isFilterExist =
            savedFilters.length > 0 && savedFilters.filter(val => val === basicSearchInput).length === 1

        // const api =
        //     loadmoreFilter.value === null && !isFilterExist
        //         ? '/semantic/quick-search/'
        //         : '/semantic/entities_tagged_news/'
        const isOpenSearch =
            basicSearchInput === '' ||
            loadmoreFilter.value ||
            (basicSearchInput !== '' && isFilterExist) ||
            (basicSearchInput === '' && filterLength > 0)
        let api

        if (isOpenSearch) {
            setProgressContent('Working on it...')
            setApproxLength(false)
            payLoad.search_type = 'advanced'
            payLoad.next = 0
            payLoad.size = 15
            api = `/news/level2/search/`
        } else {
            setProgressContent('Working on it...(5%)')
            setApproxLength(true)
            const initialTimeoutId = setTimeout(() => {
                progressContents(responseTime, completionTime)
            }, 100)
            timeoutIds.push(initialTimeoutId) // Save the initial timeout ID
            api = '/semantic/quick-search/'
        }

        axios
            .post(api, payLoad, { cancelToken: newCancelSource.token })
            .then(res => {
                // if (res.data.summary) {
                //     setQuickSummary(res.data.summary)
                // } else {
                //     setQuickSummary(null)
                // }
                if (isOpenSearch) {
                    const finalTotal = res.data.total === res.data.next ? res.data.data.length : res.data.total

                    setBasicSearchResponse({
                        total: finalTotal,
                        next: res.data.next,
                        data: res.data.data,
                        size: res.data.size,
                        scroll_id: res.data.scroll_id,
                        overallData: res.data.data,
                    })
                } else {
                    const initialData = res.data.data.slice(0, 30)

                    setBasicSearchResponse({
                        total: res.data.total,
                        // next: 30, // Set the next pointer to 20
                        data: res.data.data,
                        size: res.data.size,
                        // scroll_id: res.data.scroll_id,
                        overallData: res.data.data,
                    })
                    // getduplicateUpdates(res.data, { cancelToken: newCancelSource.token })
                }
                if (res.data.message) {
                    setRelevantNews(true)
                } else {
                    setRelevantNews(false)
                }
                setIsLoading(false)
                setProgressContent(null)
                clearTimeouts()
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setIsLoading(false)
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(true)
                } else {
                    setIsLoading(false)
                    console.log('POST err:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                }
            })
            .finally(() => {
                setFromSavedData([])
            })
    }

    const reportcolumns = [
        {
            name: <label style={{ color: 'white' }}>Report Title</label>,
            cell: row => row.title,
            className: 'report_title_width',
            textAlign: 'center',
        },
        {
            name: <label style={{ color: 'white' }}>Report Type</label>,
            cell: row => row.report_type,
            // width: '20%',
            textAlign: 'center',
            hide: 'sm',
        },
        {
            name: <label style={{ color: 'white' }}>Status</label>,
            cell: row =>
                (row.status === 'delivered' && row.report_type === 'Quick Report') ||
                (row.status === 'delivered' && row.report_type === 'Detailed Report' && userReceiveDetailedReport) ? (
                    // row.status === 'delivered'
                    <span className="d-flex align-items-center" style={{ color: '#077D55', marginLeft: '-10px' }}>
                        {/* <span>
                            <CheckCircleIcon style={{ fontSize: 'medium ' }} />
                        </span> */}
                        <span style={{ marginLeft: '5px' }}>Delivered</span>
                    </span>
                ) : (
                    <span className="d-flex align-items-center" style={{ color: '#F2B10C', marginLeft: '-10px' }}>
                        {/* <span>
                            <AccessTimeIcon style={{ fontSize: 'medium ' }} />
                        </span> */}
                        <span style={{ marginLeft: '5px' }}> In Progress</span>{' '}
                    </span>
                ),
            // width: '15%',
            textAlign: 'center',
        },
        {
            name: <label style={{ color: 'white' }}>Date</label>,
            cell: row => <span style={{ marginLeft: '-10px' }}>{row.date}</span>,
            sortable: true,
            // width: '15%',
            textAlign: 'center',
            hide: 'sm',
        },
        {
            name: <label style={{ color: 'white' }}>Actions</label>,
            cell: row => (
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <Button
                        style={{
                            // color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            // marginLeft: '10px',
                            textDecoration: 'underline',
                            color:
                                (row.document && row.report_type === 'Quick Report') ||
                                (row.document && row.report_type === 'Detailed Report' && userReceiveDetailedReport)
                                    ? '#186ADE'
                                    : 'grey',
                            // color: row.document ? '#186ADE' : 'grey',
                            textTransform: 'none',
                            minWidth: '0px',
                        }}
                        disabled={
                            row.document === null ||
                            (row.report_type === 'Detailed Report' && !userReceiveDetailedReport)
                        }
                        // disabled={row.document === null}
                        onClick={() => {
                            if (row.document.includes('json')) {
                                setDocument(row.id)
                                if (row.report_type === 'Quick Report') {
                                    setQuickReportModal(true)
                                } else {
                                    setDetailedReportModal(true)
                                }
                            } else {
                                setIframeReport(true)
                                setDocument(row.document)
                            }
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <PreviewIcon
                                style={{
                                    fontSize: '1.2rem',
                                    color:
                                        (row.document && row.report_type === 'Quick Report') ||
                                        (row.document &&
                                            row.report_type === 'Detailed Report' &&
                                            userReceiveDetailedReport)
                                            ? 'black'
                                            : 'grey',
                                }}
                            />
                        </span>
                        <span className="d-none d-sm-block">view</span>
                    </Button>
                    {/* <ShareIcon
                        style={{ color: '#3048A6', cursor: 'pointer', fontSize: '20px', marginLeft: '10px' }}
                        title="edit"
                        disabled = {row.document === null}
                    /> */}
                    <RiDeleteBin6Line
                        style={{
                            marginLeft: '10px',
                            color: '#FC3B3B',
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                        title="delete"
                        onClick={() => {
                            setDeleteReportModal(!deleteReportModal)
                            setDeleteId({
                                id: row.id,
                                query: row.title,
                            })
                        }}
                    />
                </div>
            ),
            textAlign: 'center',
        },
    ]

    const runallQueries = row => {
        setIsLoading(true)
        setBasicSearchResponse({ data: [], next: null })
        setRunAllSearches({ data: [], next: null })

        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)

        return new Promise((resolve, reject) => {
            // setIsLoading(true)

            if (row.quick_search_query !== null) {
                const extractValues = key =>
                    row.query && row.query.filters && row.query.filters[key] && row.query.filters[key].values
                        ? row.query.filters[key].values.map(val => val)
                        : []
                const getDateRange = (dateFromSaved, customDate) => {
                    if (dateFromSaved === 'custom' && customDate && customDate.startDate && customDate.endDate) {
                        return {
                            from: dateFormatChanges(new Date(customDate.startDate)),
                            to: dateFormatChanges(new Date(customDate.endDate)),
                        }
                    }
                    const startDate = new Date()
                    startDate.setDate(startDate.getDate() - (dateFromSaved || 29)) // Default to 29
                    const endDate = new Date()
                    return {
                        from: getYMD(startDate),
                        to: getYMD(endDate),
                    }
                }

                const customRange = {
                    selection: {
                        startDate: new Date(
                            row.query &&
                                row.query.filters &&
                                row.query.filters.custom_date &&
                                row.query.filters.custom_date.from
                        ),
                        endDate: new Date(
                            row.query &&
                                row.query.filters &&
                                row.query.filters.custom_date &&
                                row.query.filters.custom_date.to
                        ),
                        key: 'selection',
                    },
                }

                if (row.query && row.query.filters && row.query.filters.date === 'custom') {
                    customOnchange(customRange)
                } else {
                    setcustomDate({ startDate: subDays(new Date(), 29), endDate: new Date() })
                }

                const filters = {
                    source: extractValues('source'),
                    countries: extractValues('countries'),
                    company: extractValues('company'),
                    sectors: extractValues('sectors'),
                    category: extractValues('category'),
                    topics: extractValues('topics'),
                    date: row.query && row.query.filters && row.query.filters.date ? row.query.filters.date : '29',
                }

                const dateRange = getDateRange(filters.date, customDate)
                setSelectedDateFrom(dateRange.from)
                setSelectedDateTo(dateRange.to)

                const payload = {
                    filters: {
                        source: { operator: 'IS', values: filters.source },
                        countries: { operator: 'IS', values: filters.countries },
                        articleDate: { operator: 'BETWEEN', ...dateRange },
                        sectors: { operator: 'IS', values: [...filters.sectors, ...filters.topics] },
                        company: { operator: 'IS', values: filters.company },
                        category: { operator: 'IS', values: filters.category },
                        topics: { operator: 'IS', values: filters.topics },
                    },
                    relevance_cutoff: calculateCutOff(filters.date, customDate),
                    semantic_preference: 0.5,
                    type: 'level0',
                    query: row.quick_search_query,
                    entities: {
                        regions: allRegionName.filter(r => selectedRegions.includes(r)),
                        provinces: allProvinceName.filter(r => selectedRegions.includes(r)),
                        countries: allCountryName.filter(r => selectedRegions.includes(r)),
                        sectors: allSectorsName.filter(s => [...industryDrop, ...selectedSectors].includes(s)),
                        industries: allIndustriesName.filter(i => [...industryDrop, ...selectedSectors].includes(i)),
                        companies: [...selectedCompanies, ...companyDrop],
                        topics: allTopicsName.filter(t => [...topicDrop, ...selectedSectors].includes(t)),
                        subcategories: [...selectedSubCategories, ...categoryDrop],
                        source_name: selectedSources,
                    },
                }

                axios
                    .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(row.quick_search_query)}`, {
                        cancelToken: newCancelSource.token,
                    })
                    .then(response => {
                        const filteredData = response.data.map(item => ({
                            value: item.id,
                            label: item.name,
                            url: item.url,
                            type: 'Company',
                            alias: item.alias,
                        }))

                        const companyPrompt = filteredData.filter(item => item.label === row.quick_search_query)

                        const sectorPrompt = [...allIndustries, ...allTopics].filter(
                            item => item.label === row.quick_search_query
                        )

                        payload.prompt_type =
                            companyPrompt.length > 0
                                ? 'Company Quick Search News Validation'
                                : sectorPrompt.length > 0
                                ? 'Sector Quick Search News Validation'
                                : 'Quick Search News Validation'

                        const allfilteredData = [
                            ...filters.company,
                            ...filters.sectors,
                            ...filters.category,
                            ...filters.topics,
                        ]

                        const isTagged = allfilteredData.includes(row.quick_search_query)
                        const api = isTagged ? '/news/level2/search/' : '/semantic/quick-search/'

                        if (isTagged) {
                            payload.search_type = 'advanced'
                            payload.next = 0
                            payload.size = 100
                        }

                        axios
                            .post(api, payload, {
                                cancelToken: newCancelSource.token,
                            })
                            .then(res => {
                                resolve(res.data) // Return the response data for merging
                            })
                            .catch(err => {
                                if (axios.isCancel(err)) {
                                    console.log('Request canceled:', err.message)
                                } else {
                                    console.error('GET error:', err.message)
                                    reject(err)
                                }
                            })
                    })
                    .catch(err => reject(err))
            } else {
                // reject(new Error('Invalid query'))
                if (row.quick_search_query === null && row.query && row.query.filters) {
                    const populate = row.query.filters
                    const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
                    const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
                    const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
                    const sectorsFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
                    const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
                    const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
                    const savedNewsContent = populate.newsContent ? populate.newsContent.regex : ''
                    const savedRegion = combinedRegCouPro.filter(val => regionfromSaved.includes(val.label))
                    const savedCompany = allCompanies.filter(val => companyfromSaved.includes(val.label))
                    const savedTopic = allTopics.filter(val => topicFromSaved.includes(val.label))
                    const savedCategory = allSubCategories.filter(val => categoryFromSaved.includes(val.label))
                    const savedSectors = allIndustries.filter(val => sectorsFromSaved.includes(val.label))
                    const savedSources = allSources.filter(val => sourcesFromSaved.includes(val.value))

                    // Define the start and end dates
                    var startDateCount = new Date(populate.articleDate.from)
                    var endDateCount = new Date(populate.articleDate.to)

                    // Calculate the difference in milliseconds
                    var diffInTime = endDateCount.getTime() - startDateCount.getTime()

                    // Convert the difference from milliseconds to days
                    var diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24))

                    const payLoad = {
                        filters: {
                            source: {
                                operator: populate.source.operator,
                                values: savedSources.map(val => val.value),
                            },
                            articleDate: {
                                operator: populate.articleDate.operator,
                                from: populate.articleDate.from !== '' ? populate.articleDate.from : getYMD(startDate),
                                to: populate.articleDate.to !== '' ? populate.articleDate.to : getYMD(new Date()),
                            },
                            countries: {
                                operator: populate.countries.operator,
                                values: savedRegion.map(value => value.label),
                            },
                            sectors: {
                                operator: populate.sectors.operator,
                                values: savedSectors.map(value => value.label),
                            },
                            company: {
                                operator: populate.company.operator,
                                values: savedCompany.map(value => value.label),
                            },
                            category: {
                                operator: populate.category.operator,
                                values: savedCategory.map(value => value.label),
                            },
                            topics: {
                                operator: populate.topics.operator,
                                values: savedTopic.map(value => value.label),
                            },
                            newsTitle: {
                                operator: selectedQueries.newsTitleOperator,
                                value: populate.newsTitle.value,
                                fuzziness: 3,
                            },
                            newsContent: {
                                operator: populate.newsContent.operator,
                                regex: savedNewsContent.substring(0, savedNewsContent.length - 1),
                            },
                        },
                        search_type: 'advanced',
                        next: 0, // For Next Batch
                        size: 100, // Batch size
                    }

                    axios
                        .post(`/news/level2/search/`, payLoad, {
                            cancelToken: newCancelSource.token,
                        })
                        .then(res => {
                            resolve(res.data) // Return the response data for merging
                        })
                        .catch(err => {
                            if (axios.isCancel(err)) {
                                console.log('Request canceled:', err.message)
                            } else {
                                console.error('GET error:', err.message)
                                reject(err)
                            }
                        })
                } else {
                    setIsLoading(false)
                }
            }
        })
    }

    const fetchAllQueriesInParallel = allqueries => {
        setIsLoading(true)
        const completionTime = 20000
        const responseTime = 20000
        setProgressContent('Working on it...(5%)')
        const initialTimeoutId = setTimeout(() => {
            progressContents(responseTime, completionTime)
        }, 1500)
        timeoutIds.push(initialTimeoutId)

        const allQueryPromises = allqueries.map((row, index) => {
            return runallQueries(row)
                .then(res => {
                    console.log(`Query ${index + 1} completed`, res)
                    return res // Return the result for merging
                })
                .catch(err => {
                    console.error(`Query ${index + 1} failed`, err)
                    return null // Return null if the query fails
                })
        })

        Promise.all(allQueryPromises)
            .then(allResults => {
                // Merge all the results here
                // const mergedResults = allResults.reduce((acc, result) => {
                //     if (result && result.data) {
                //         acc.push(...result.data)
                //     }
                //     return acc
                // }, [])

                const mergedResults = allResults.flatMap(result => (result && result.data ? result.data : []))

                const uniqueMergedResults = Array.from(new Map(mergedResults.map(item => [item.id, item])).values())

                setRunAllSearches({
                    total: uniqueMergedResults.length,
                    next: null,
                    data: uniqueMergedResults,
                })

                setIsLoading(false)
                setProgressContent(null)
                clearTimeouts()
            })
            .catch(error => {
                console.error('Error fetching all queries:', error)
                setIsLoading(false)
                setProgressContent(null)
                clearTimeouts()
            })
    }

    const EditQueries = (row, btntype = null) => {
        btntype === null && setIsLoading(true)

        setRunAllSearches({ data: [] })

        const completionTime = 10000
        const responseTime = 10000
        if (btntype === null) {
            setProgressContent('Working on it...(5%)')
            const initialTimeoutId = setTimeout(() => {
                progressContents(responseTime, completionTime)
            }, 1500)
            timeoutIds.push(initialTimeoutId)
        }
        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)

        setEditQuery(row)
        setBasicSearchResponse({ data: [], next: null })
        setBasicSearchInput(row.quick_search_query)
        setSaveQueryName(row.query_name)
        setEditQueryName(row.query_name)

        setEditPage(true)
        // if (row.quick_search_query !== null) {
        //     setSubTab(0)
        // } else {
        //     setSubTab(1)
        // }

        if (row.cron_expression) {
            setCronTabValue(LastCronValue(row.cron_expression, '+'))
        } else {
            setCronTabValue('0 0 * * 1')
        }
        if (row.scheduled) {
            setIsEnabled(true)
        } else {
            setIsEnabled(false)
        }
        if (row.subject) {
            setEmailSubject(row.subject)
        }
        if (row.has_section) {
            setIncludeSection(row.has_section)
        }
        if (row.include_subcategory) {
            setIncludeBusiness(row.include_subcategory)
        }
        if (row.collection) {
            setCollectionId(row.collection)
        }
        if (row.collection_name) {
            setCollectionName(allCollectionName.filter(val => val.label === row.collection_name)[0])
        }
        if (row.collection_description) {
            setCollectionDescription(row.collection_description)
        }
        if (row.collection_privacy) {
            setCollectionPrivacy(collectionShareList.filter(val => val.row === row.collection_privacy)[0])
        }
        if (row.collection_users) {
            setCollectionUsers(users.filter(user => row.collection_users.some(f => f.row === user.id)))
        }

        if (row.template) {
            const currentTemplate = templates.filter(val => val.value === row.template)
            if (currentTemplate.length > 0) {
                setSelectedTemplate(currentTemplate[0])
            } else {
                setSelectedTemplate(null)
            }
        }

        if (row.recipients) {
            const resultArray = row.recipients.split(',')
            const filter = resultArray
            filter.forEach(email => {
                const existingUser = users.find(user => user.email === email)
                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }
                    users.push(newUser)
                }
            })
            const currentUser = users.filter(val => resultArray.includes(val.email))
            if (currentUser.length > 0) {
                setSelectedUsers(currentUser)
            }
        }
        if (row.quick_search_query !== null) {
            // OpenFilter()
            setNewsFilters(true)
            setSubTab(0)

            if (row.query && row.query.filters) {
                const populate = row.query.filters
                const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
                const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
                const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
                const sectorFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
                const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
                const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
                const dateFromSaved = populate.date ? numberMapping[populate.date] || populate.date : '29'
                setSelectedOption(dateFromSaved)
                setCurrentDate(Number(dateFromSaved) + 1)

                if (dateFromSaved !== 'custom') {
                    setcustomDate({
                        startDate: subDays(new Date(), 29),
                        endDate: new Date(),
                    })
                }

                setSelectedSources(sourcesFromSaved)
                setSelectedRegions(regionfromSaved)
                setSelectedCompanies(companyfromSaved)
                setSelectedSectors([...sectorFromSaved, ...topicFromSaved])
                setSelectedCategories(categoryFromSaved)
                if (dateFromSaved === 'custom' && populate.custom_date) {
                    const range = {
                        selection: {
                            startDate: new Date(populate.custom_date.from),
                            endDate: new Date(populate.custom_date.to),
                            key: 'selection',
                        },
                    }

                    customOnchange(range)
                }

                const fromSaved = [...sourcesFromSaved, ...companyfromSaved, ...sectorFromSaved, ...topicFromSaved]
                setFromSavedData(fromSaved)

                setTimeout(() => {
                    // setIsLoading(true)
                    setBasicSearchResponse({ data: [], next: null })
                    setBasicSearchDrop(false)
                    setEditId(row.id)

                    setLoadMoreFilter({ value: null, type: null })

                    var startDate = new Date()
                    var endDate = new Date()
                    if (dateFromSaved !== 'custom') {
                        startDate.setDate(startDate.getDate() - dateFromSaved)
                        startDate = getYMD(startDate)
                        setSelectedDateFrom(startDate)
                        endDate = getYMD(endDate)
                        setSelectedDateTo(endDate)
                    }

                    const payload = {
                        filters: {
                            source: {
                                operator: 'IS',
                                values: sourcesFromSaved,
                            },
                            countries: {
                                operator: 'IS',
                                values: regionfromSaved,
                            },
                            articleDate: {
                                operator: 'BETWEEN',
                                from:
                                    dateFromSaved === 'custom' && customDate.startDate !== null
                                        ? dateFormatChanges(new Date(customDate.startDate))
                                        : startDate,
                                to:
                                    dateFromSaved === 'custom' && customDate.endDate !== null
                                        ? dateFormatChanges(new Date(customDate.endDate))
                                        : endDate,
                            },
                            sectors: {
                                operator: 'IS',
                                values: [...sectorFromSaved, ...topicFromSaved],
                            },
                            company: {
                                operator: 'IS',
                                values: companyfromSaved,
                            },
                            category: {
                                operator: 'IS',
                                values: categoryFromSaved,
                            },
                            topics: {
                                operator: 'IS',
                                values: topicFromSaved,
                            },
                        },
                        relevance_cutoff: calculateCutOff(dateFromSaved, customDate),
                        semantic_preference: 0.5,
                        // type: fromSaved.length === 0 ? 'level0' : 'level2',
                        // type: selectedLevel,
                        type: 'level0',
                    }
                    if (row.quick_search_query !== null) {
                        payload.query = row.quick_search_query
                        payload.entities = {
                            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
                            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
                            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
                            sectors: allSectorsName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
                            industries: allIndustriesName.filter(acc =>
                                [...industryDrop, ...selectedSectors].includes(acc)
                            ),
                            companies: [...selectedCompanies, ...companyDrop],
                            topics: allTopicsName.filter(acc => [...topicDrop, ...selectedSectors].includes(acc)),
                            subcategories: [...selectedSubCategories, ...categoryDrop],
                            source_name: selectedSources,
                        }

                        axios
                            .get(
                                `/news/auto-complete/?type=company&string=${encodeURIComponent(row.quick_search_query)}`
                            )
                            .then(response => {
                                const filteredData = response.data.map(item => ({
                                    value: item.id,
                                    label: item.name,
                                    url: item.url,
                                    type: 'Company',
                                    alias: item.alias,
                                }))
                                const uniqueData = new Set([...filteredData, ...allCompanies])
                                const arrayFromSet = [...uniqueData]
                                const companyPrompt = arrayFromSet.filter(val => val.label === row.quick_search_query)

                                const sectorPrompt = [...allIndustries, ...allTopics].filter(
                                    val => val.label === row.quick_search_query
                                )

                                payload.prompt_type =
                                    companyPrompt.length > 0
                                        ? 'Company Quick Search News Validation'
                                        : sectorPrompt.length > 0
                                        ? 'Sector Quick Search News Validation'
                                        : 'Quick Search News Validation'

                                const isTagged = fromSaved.filter(item => item === row.quick_search_query).length === 1

                                const api = !isTagged ? '/semantic/quick-search/' : '/semantic/entities_tagged_news/'

                                // axios
                                //     .post(api, payload, {
                                //         cancelToken: newCancelSource.token,
                                //     })
                                //     .then(res => {
                                //         setBasicSearchResponse({
                                //             total: res.data.total,
                                //             next: res.data.next,
                                //             data: res.data.data,
                                //         })
                                //         if (res.data.message) {
                                //             setRelevantNews(true)
                                //         } else {
                                //             setRelevantNews(false)
                                //         }
                                //         setIsLoading(false)
                                //         setProgressContent(null)
                                //         clearTimeouts()
                                //     })
                                //     .catch(err => {
                                //         if (axios.isCancel(err)) {
                                //             console.log('POST Request canceled:', err.message)
                                //             setIsLoading(true)
                                //             setProgressContent(null)
                                //             clearTimeouts()
                                //         } else {
                                //             setIsLoading(false)
                                //             console.log('POST err:', err.message)
                                //             setProgressContent(null)
                                //             clearTimeouts()
                                //         }

                                //         console.log(err)
                                //     })
                            })
                            .catch(err => console.log(err))
                    }

                    // if (fromSaved && fromSaved.length !== 0) {
                    //     payload.non_entity_query = fromSaved[0]
                    // }
                }, 100)
            } else {
                setTimeout(() => {
                    // setIsLoading(true)
                    setBasicSearchResponse({ data: [], next: null })
                    setBasicSearchDrop(false)
                    setEditId(row.id)

                    setLoadMoreFilter({ value: null, type: null })
                    setCurrentDate(Number(selectedOption) + 1)

                    var startDate = new Date()
                    var endDate = new Date()

                    startDate.setDate(startDate.getDate() - selectedOption)
                    startDate = getYMD(startDate)
                    setSelectedDateFrom(startDate)
                    endDate = getYMD(endDate)
                    setSelectedDateTo(endDate)

                    const payload = {
                        filters: {
                            source: {
                                operator: 'IS',
                                values: [],
                            },
                            countries: {
                                operator: 'IS',
                                values: [],
                            },
                            articleDate: {
                                operator: 'BETWEEN',
                                from: startDate,
                                to: endDate,
                            },
                            sectors: {
                                operator: 'IS',
                                values: [],
                            },
                            company: {
                                operator: 'IS',
                                values: [],
                            },
                            category: {
                                operator: 'IS',
                                values: [],
                            },
                            topics: {
                                operator: 'IS',
                                values: [],
                            },
                        },
                        relevance_cutoff: calculateCutOff(selectedOption, customDate),
                        semantic_preference: 0.5,
                        type: 'level0',
                        // type: selectedLevel,
                    }
                    if (row.quick_search_query) {
                        payload.query = row.quick_search_query
                        payload.prompt_type = 'Quick Search News Validation'
                    }

                    payload.entities = {
                        regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
                        provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
                        countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
                        sectors: allSectorsName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
                        industries: allIndustriesName.filter(acc =>
                            [...industryDrop, ...selectedSectors].includes(acc)
                        ),
                        companies: [selectedCompanies, ...companyDrop],
                        topics: allTopicsName.filter(acc => [...industryDrop, ...selectedSectors].includes(acc)),
                        subcategories: [...selectedSubCategories, ...categoryDrop],
                        source_name: selectedSources,
                    }

                    if (btntype === null) {
                        axios
                            .post('/semantic/quick-search/', payload, { cancelToken: newCancelSource.token })
                            .then(res => {
                                setBasicSearchResponse({
                                    total: res.data.total,
                                    // next: res.data.next,
                                    data: res.data.data,
                                    size: res.data.size,
                                    // scroll_id: res.data.scroll_id,
                                    overallData: res.data.data,
                                })
                                if (res.data.message) {
                                    setRelevantNews(true)
                                } else {
                                    setRelevantNews(false)
                                }
                                setIsLoading(false)
                                setProgressContent(null)
                                clearTimeouts()
                            })
                            .catch(err => {
                                if (axios.isCancel(err)) {
                                    console.log('POST Request canceled:', err.message)
                                    setIsLoading(true)
                                    setProgressContent(null)
                                    clearTimeouts()
                                    setIsLoading(false)
                                } else {
                                    setIsLoading(false)
                                    console.log('POST err:', err.message)
                                    setProgressContent(null)
                                    clearTimeouts()
                                }

                                console.log(err)
                            })
                    }
                }, 100)
            }
        } else {
            // setIsLoading(true)

            setSubTab(1)
            setEditId(row.id)
            // setEditQueryName(value.query_name)

            if (row.quick_search_query === null && row.query && row.query.filters) {
                const populate = row.query.filters
                const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
                const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
                const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
                const sectorsFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
                const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
                const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
                const savedNewsContent = populate.newsContent ? populate.newsContent.regex : ''
                const savedRegion = combinedRegCouPro.filter(val => regionfromSaved.includes(val.label))
                const savedCompany = allCompanies.filter(val => companyfromSaved.includes(val.label))
                const savedTopic = allTopics.filter(val => topicFromSaved.includes(val.label))
                const savedCategory = allSubCategories.filter(val => categoryFromSaved.includes(val.label))
                const savedSectors = allIndustries.filter(val => sectorsFromSaved.includes(val.label))
                const savedSources = allSources.filter(val => sourcesFromSaved.includes(val.value))

                // Define the start and end dates
                var startDateCount = new Date(populate.articleDate.from)
                var endDateCount = new Date(populate.articleDate.to)

                // Calculate the difference in milliseconds
                var diffInTime = endDateCount.getTime() - startDateCount.getTime()

                // Convert the difference from milliseconds to days
                var diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24))

                setAdvanceCurrentDate(Number(isNaN(diffInDays)) === NaN ? 29 : diffInDays)

                setSelectedQueries({
                    ...selectedQueries,
                    articleDateFrom: populate.articleDate.from,
                    articleOperator: populate.articleDate.operator,
                    articleDateTo: populate.articleDate.to,
                    region: savedRegion,
                    regionOperator: populate.countries.operator,
                    sectors: savedSectors,
                    industryOperator: populate.sectors.operator,
                    company: savedCompany,
                    companyOperator: populate.company.operator,
                    category: savedCategory,
                    categoryOperator: populate.category.operator,
                    topic: savedTopic,
                    topicOperator: populate.topics.operator,
                    newsTitle: populate.newsTitle ? populate.newsTitle.value : '',
                    newsTitleOperator: populate.newsTitle ? populate.newsTitle.operator : 'CONTAINS',
                    newsContent: savedNewsContent.substring(0, savedNewsContent.length - 1),
                    newsContentOperator: populate.newsContent ? populate.newsContent.operator : 'CONTAINS',
                    source: savedSources,
                    sourceOperator: populate.source.operator,
                })

                setTimeout(() => {
                    const payLoad = {
                        filters: {
                            source: {
                                operator: populate.source.operator,
                                values: savedSources.map(val => val.value),
                            },
                            articleDate: {
                                operator: populate.articleDate.operator,
                                from: populate.articleDate.from !== '' ? populate.articleDate.from : getYMD(startDate),
                                to: populate.articleDate.to !== '' ? populate.articleDate.to : getYMD(new Date()),
                            },
                            countries: {
                                operator: populate.countries.operator,
                                values: savedRegion.map(value => value.label),
                            },
                            sectors: {
                                operator: populate.sectors.operator,
                                values: savedSectors.map(value => value.label),
                            },
                            company: {
                                operator: populate.company.operator,
                                values: savedCompany.map(value => value.label),
                            },
                            category: {
                                operator: populate.category.operator,
                                values: savedCategory.map(value => value.label),
                            },
                            topics: {
                                operator: populate.topics.operator,
                                values: savedTopic.map(value => value.label),
                            },
                            newsTitle: {
                                operator: selectedQueries.newsTitleOperator,
                                value: populate.newsTitle.value,
                                fuzziness: 3,
                            },
                            newsContent: {
                                operator: populate.newsContent.operator,
                                regex: savedNewsContent.substring(0, savedNewsContent.length - 1),
                            },
                        },
                        search_type: 'advanced',
                        next: 0, // For Next Batch
                        size: 15, // Batch size
                    }
                    if (btntype === null) {
                        axios
                            .post(`/news/level2/search/`, payLoad, { cancelToken: newCancelSource.token })
                            .then(res => {
                                setAdvanceResponse(res)
                                setIsLoading(false)
                                setProgressContent(null)
                                clearTimeouts()
                            })
                            .catch(err => {
                                if (axios.isCancel(err)) {
                                    console.log('POST Request canceled:', err.message)
                                    setIsLoading(false)
                                    setProgressContent(null)
                                    clearTimeouts()
                                } else {
                                    setIsLoading(false)
                                    console.log('POST err:', err.message)
                                    setProgressContent(null)
                                    clearTimeouts()
                                }

                                console.log(err)
                            })
                    }
                }, 1000)
            } else {
                setIsLoading(false)
            }
        }
    }

    const collectionEdit = (row, index, btntype = null) => {
        setBasicSearchInput(row.quick_search_query)
        setSaveQueryName(row.query_name)
        setDummyEdit(true)
        setSaveSub(true)
        setDummyEditQuery(index)

        if (row.quick_search_query !== null) {
            setSubPlace(0)
            if (row.query && row.query.filters) {
                const populate = row.query.filters
                const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
                const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
                const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
                const sectorFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
                const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
                const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
                const dateFromSaved = populate.date ? numberMapping[populate.date] || populate.date : '29'
                setSelectedOption(dateFromSaved)
                setCurrentDate(Number(dateFromSaved) + 1)
                setSelectedSources(sourcesFromSaved)
                setSelectedRegions(regionfromSaved)
                setSelectedCompanies(companyfromSaved)
                setSelectedSectors([...sectorFromSaved, ...topicFromSaved])
                setSelectedCategories(categoryFromSaved)

                if (dateFromSaved !== 'custom') {
                    setcustomDate({
                        startDate: subDays(new Date(), 29),
                        endDate: new Date(),
                    })
                }
                if (dateFromSaved === 'custom' && populate.custom_date) {
                    const range = {
                        selection: {
                            startDate: new Date(populate.custom_date.from),
                            endDate: new Date(populate.custom_date.to),
                            key: 'selection',
                        },
                    }

                    customOnchange(range)
                }

                const fromSaved = [...sourcesFromSaved, ...companyfromSaved, ...sectorFromSaved, ...topicFromSaved]
                setFromSavedData(fromSaved)

                setTimeout(() => {
                    btntype === null && setIsLoading(true)
                    setBasicSearchResponse({ data: [], next: null })
                    setBasicSearchDrop(false)

                    setLoadMoreFilter({ value: null, type: null })

                    var startDate = new Date()
                    var endDate = new Date()

                    if (dateFromSaved !== 'custom') {
                        startDate.setDate(startDate.getDate() - dateFromSaved)
                        startDate = getYMD(startDate)
                        setSelectedDateFrom(startDate)
                        endDate = getYMD(endDate)
                        setSelectedDateTo(endDate)
                    }

                    const payload = {
                        filters: {
                            source: {
                                operator: 'IS',
                                values: sourcesFromSaved,
                            },
                            countries: {
                                operator: 'IS',
                                values: regionfromSaved,
                            },
                            articleDate: {
                                operator: 'BETWEEN',
                                from:
                                    dateFromSaved === 'custom' && customDate.startDate !== null
                                        ? dateFormatChanges(new Date(customDate.startDate))
                                        : startDate,
                                to:
                                    dateFromSaved === 'custom' && customDate.endDate !== null
                                        ? dateFormatChanges(new Date(customDate.endDate))
                                        : endDate,
                            },
                            sectors: {
                                operator: 'IS',
                                values: [...sectorFromSaved, ...topicFromSaved],
                            },
                            company: {
                                operator: 'IS',
                                values: companyfromSaved,
                            },
                            category: {
                                operator: 'IS',
                                values: categoryFromSaved,
                            },
                            topics: {
                                operator: 'IS',
                                values: topicFromSaved,
                            },
                        },
                        relevance_cutoff: calculateCutOff(dateFromSaved, customDate),
                        semantic_preference: 0.5,
                        // type: fromSaved.length === 0 ? 'level0' : 'level2',
                        // type: selectedLevel,
                        type: 'level0',
                    }
                    if (row.quick_search_query) {
                        payload.query = row.quick_search_query
                        payload.entities = {
                            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
                            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
                            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
                            sectors: allSectorsName.filter(acc => selectedSectors.includes(acc)),
                            industries: allIndustriesName.filter(acc => selectedSectors.includes(acc)),
                            companies: selectedCompanies,
                            topics: allTopicsName.filter(acc => selectedSectors.includes(acc)),
                            subcategories: selectedSubCategories,
                            source_name: selectedSources,
                        }

                        axios
                            .get(
                                `/news/auto-complete/?type=company&string=${encodeURIComponent(row.quick_search_query)}`
                            )
                            .then(response => {
                                const filteredData = response.data.map(item => ({
                                    value: item.id,
                                    label: item.name,
                                    url: item.url,
                                    type: 'Company',
                                    alias: item.alias,
                                }))
                                const uniqueData = new Set([...filteredData, ...allCompanies])
                                const arrayFromSet = [...uniqueData]
                                const companyPrompt = arrayFromSet.filter(val => val.label === row.quick_search_query)

                                const sectorPrompt = [...allIndustries, ...allTopics].filter(
                                    val => val.label === row.quick_search_query
                                )

                                payload.prompt_type =
                                    companyPrompt.length > 0
                                        ? 'Company Quick Search News Validation'
                                        : sectorPrompt.length > 0
                                        ? 'Sector Quick Search News Validation'
                                        : 'Quick Search News Validation'

                                const isTagged = fromSaved.filter(item => item === row.quick_search_query).length === 1

                                const api = !isTagged ? '/semantic/quick-search/' : '/semantic/entities_tagged_news/'

                                // axios
                                //     .post(api, payload)
                                //     .then(res => {
                                //         setBasicSearchResponse({
                                //             total: res.data.total,
                                //             next: res.data.next,
                                //             data: res.data.data,
                                //         })
                                //         if (res.data.message) {
                                //             setRelevantNews(true)
                                //         } else {
                                //             setRelevantNews(false)
                                //         }
                                //     })
                                //     .catch(err => console.log(err))
                                //     .finally(() => {
                                //         setIsLoading(false)
                                //     })
                            })
                            .catch(err => console.log(err))
                    }

                    // if (fromSaved && fromSaved.length !== 0) {
                    //     payload.non_entity_query = fromSaved[0]
                    // }
                }, 100)
            } else {
                setTimeout(() => {
                    btntype === null && setIsLoading(true)
                    setBasicSearchResponse({ data: [], next: null })
                    setBasicSearchDrop(false)
                    setEditId(row.id)
                    setCurrentDate(Number(selectedOption) + 1)

                    var startDate = new Date()
                    var endDate = new Date()

                    startDate.setDate(startDate.getDate() - selectedOption)
                    startDate = getYMD(startDate)
                    setSelectedDateFrom(startDate)
                    endDate = getYMD(endDate)
                    setSelectedDateTo(endDate)

                    setLoadMoreFilter({ value: null, type: null })

                    const payload = {
                        filters: {
                            source: {
                                operator: 'IS',
                                values: [],
                            },
                            countries: {
                                operator: 'IS',
                                values: [],
                            },
                            articleDate: {
                                operator: 'BETWEEN',
                                from: startDate,
                                to: endDate,
                            },
                            sectors: {
                                operator: 'IS',
                                values: [],
                            },
                            company: {
                                operator: 'IS',
                                values: [],
                            },
                            category: {
                                operator: 'IS',
                                values: [],
                            },
                            topics: {
                                operator: 'IS',
                                values: [],
                            },
                        },
                        relevance_cutoff: calculateCutOff(selectedOption, customDate),
                        semantic_preference: 0.5,
                        type: 'level0',
                        // type: selectedLevel,
                    }
                    if (row.quick_search_query) {
                        payload.query = row.quick_search_query
                        payload.prompt_type = 'Quick Search News Validation'
                    }

                    payload.entities = {
                        regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
                        provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
                        countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
                        sectors: allSectorsName.filter(acc => selectedSectors.includes(acc)),
                        industries: allIndustriesName.filter(acc => selectedSectors.includes(acc)),
                        companies: selectedCompanies,
                        topics: allTopicsName.filter(acc => selectedSectors.includes(acc)),
                        subcategories: selectedSubCategories,
                        source_name: selectedSources,
                    }

                    if (btntype === null) {
                        axios
                            .post('/semantic/quick-search/', payload)
                            .then(res => {
                                setBasicSearchResponse({
                                    total: res.data.total,
                                    // next: res.data.next,
                                    data: res.data.data,
                                    overallData: res.data.data,
                                })
                                if (res.data.message) {
                                    setRelevantNews(true)
                                } else {
                                    setRelevantNews(false)
                                }
                            })
                            .catch(err => console.log(err))
                            .finally(() => {
                                setIsLoading(false)
                            })
                    }
                }, 100)
            }
        }
        if (row.quick_search_query === null) {
            setSubPlace(1)
            btntype === null && setIsLoading(true)

            if (row.quick_search_query === null && row.query && row.query.filters) {
                const populate = row.query.filters
                const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
                const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
                const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
                const sectorsFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
                const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
                const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
                const savedNewsContent = populate.newsContent ? populate.newsContent.regex : ''
                const savedRegion = combinedRegCouPro.filter(val => regionfromSaved.includes(val.label))
                const savedCompany = allCompanies.filter(val => companyfromSaved.includes(val.label))
                const savedTopic = allTopics.filter(val => topicFromSaved.includes(val.label))
                const savedCategory = allSubCategories.filter(val => categoryFromSaved.includes(val.label))
                const savedSectors = allIndustries.filter(val => sectorsFromSaved.includes(val.label))
                const savedSources = allSources.filter(val => sourcesFromSaved.includes(val.value))

                // Define the start and end dates
                var startDateCount = new Date(populate.articleDate.from)
                var endDateCount = new Date(populate.articleDate.to)

                // Calculate the difference in milliseconds
                var diffInTime = endDateCount.getTime() - startDateCount.getTime()

                // Convert the difference from milliseconds to days
                var diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24))

                setAdvanceCurrentDate(Number(isNaN(diffInDays)) === NaN ? 29 : diffInDays)

                setSelectedQueries({
                    ...selectedQueries,
                    articleDateFrom: populate.articleDate.from,
                    articleOperator: populate.articleDate.operator,
                    articleDateTo: populate.articleDate.to,
                    region: savedRegion,
                    regionOperator: populate.countries.operator,
                    sectors: savedSectors,
                    industryOperator: populate.sectors.operator,
                    company: savedCompany,
                    companyOperator: populate.company.operator,
                    category: savedCategory,
                    categoryOperator: populate.category.operator,
                    topic: savedTopic,
                    topicOperator: populate.topics.operator,
                    newsTitle: populate.newsTitle ? populate.newsTitle.value : '',
                    newsTitleOperator: populate.newsTitle ? populate.newsTitle.operator : 'CONTAINS',
                    newsContent: savedNewsContent.substring(0, savedNewsContent.length - 1),
                    newsContentOperator: populate.newsContent ? populate.newsContent.operator : 'CONTAINS',
                    source: savedSources,
                    sourceOperator: populate.source.operator,
                })

                setTimeout(() => {
                    const payLoad = {
                        filters: {
                            source: {
                                operator: populate.source.operator,
                                values: savedSources.map(val => val.value),
                            },
                            articleDate: {
                                operator: populate.articleDate.operator,
                                from: populate.articleDate.from !== '' ? populate.articleDate.from : getYMD(startDate),
                                to: populate.articleDate.to !== '' ? populate.articleDate.to : getYMD(new Date()),
                            },
                            countries: {
                                operator: populate.countries.operator,
                                values: savedRegion.map(value => value.label),
                            },
                            sectors: {
                                operator: populate.sectors.operator,
                                values: savedSectors.map(value => value.label),
                            },
                            company: {
                                operator: populate.company.operator,
                                values: savedCompany.map(value => value.label),
                            },
                            category: {
                                operator: populate.category.operator,
                                values: savedCategory.map(value => value.label),
                            },
                            topics: {
                                operator: populate.topics.operator,
                                values: savedTopic.map(value => value.label),
                            },
                            newsTitle: {
                                operator: selectedQueries.newsTitleOperator,
                                value: populate.newsTitle.value,
                                fuzziness: 3,
                            },
                            newsContent: {
                                operator: populate.newsContent.operator,
                                regex: savedNewsContent.substring(0, savedNewsContent.length - 1),
                            },
                        },
                        search_type: 'advanced',
                        next: 0, // For Next Batch
                        size: 15, // Batch size
                    }
                    if (btntype === null) {
                        axios
                            .post(`/news/level2/search/`, payLoad)
                            .then(res => {
                                setAdvanceResponse(res)
                            })
                            .catch(err => console.log(err))
                            .finally(() => {
                                setIsLoading(false)
                            })
                    }
                }, 1000)
            } else {
                setIsLoading(false)
            }
        }
    }

    const alertcolumns = [
        {
            name: <label style={{ color: 'white' }}> Name</label>,
            cell: row => row.query_name,
            width: '40%',
        },
        {
            name: <label style={{ color: 'white' }}>Status</label>,
            cell: row =>
                row.scheduled ? (
                    <span className="d-flex align-items-center ml_status" style={{ color: '#077D55' }}>
                        <span>
                            <ToggleOnIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }} className="d-none d-sm-block">
                            Enabled
                        </span>
                    </span>
                ) : (
                    <span className="d-flex align-items-center ml_status" style={{ color: 'lightgray' }}>
                        <span>
                            <ToggleOffIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }} className="d-none d-sm-block">
                            Disabled
                        </span>{' '}
                    </span>
                ),
            // width: '20%',
            textAlign: 'center',
        },
        {
            name: <label style={{ color: 'white' }}>Actions</label>,
            cell: row => (
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                        }}
                        onClick={() => {
                            setEditOnly(false)

                            EditQueries(row)
                        }}
                        className="d-flex align-items-center"
                    >
                        <span className="d-block d-sm-none">
                            <GrEdit style={{ color: '#186ADE' }} />
                        </span>
                        <span className="d-none d-sm-block">Run</span>
                    </span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            marginLeft: '10px',
                        }}
                        onClick={() => {
                            setEditOnly(true)
                            EditQueries(row, 'edit')
                        }}
                        className="d-flex align-items-center"
                    >
                        <span className="d-block d-sm-none">
                            <GrEdit style={{ color: '#186ADE' }} />
                        </span>
                        <span className="d-none d-sm-block">Edit</span>
                    </span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            marginLeft: '10px',
                        }}
                        onClick={() => scheduling(row)}
                        className="d-flex align-items-center"
                    >
                        <span className="d-block d-sm-none">
                            <AiOutlineSchedule />
                        </span>
                        <span className="d-none d-sm-block">Scheduling Settings</span>
                    </span>
                    <RiDeleteBin6Line
                        style={{
                            marginLeft: '10px',
                            color: '#FC3B3B',
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                        title="delete"
                        onClick={() => {
                            setDeleteModalOpen(!deleteModalOpen)
                            setDeleteId({
                                id: row.id,
                                query: row.query_name,
                            })
                        }}
                    />
                </div>
            ),
        },
    ]

    const subExistingColumns = [
        {
            name: <label style={{ color: 'white' }}>Title</label>,
            cell: row => (
                <div className="d-flex align-items-center">
                    <input
                        type="checkbox"
                        checked={saveDummyData.some(selectedRow => selectedRow.id === row.id)}
                        onChange={() => toggleRow(row)}
                    />
                    <span style={{ marginLeft: '10px' }}> {row.query_name}</span>
                </div>
            ),
            width: '40%',
        },
        {
            name: <label style={{ color: 'white' }}>Status</label>,
            cell: row =>
                row.scheduled ? (
                    <span className="d-flex align-items-center ml_status" style={{ color: '#077D55' }}>
                        <span>
                            <ToggleOnIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }} className="d-none d-sm-block">
                            Enabled
                        </span>
                    </span>
                ) : (
                    <span className="d-flex align-items-center ml_status" style={{ color: 'lightgray' }}>
                        <span>
                            <ToggleOffIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }} className="d-none d-sm-block">
                            Disabled
                        </span>{' '}
                    </span>
                ),
            // width: '20%',
            textAlign: 'center',
        },
        {
            name: <label style={{ color: 'white' }}>Actions</label>,
            cell: row => (
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    {/* <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                        }}
                        onClick={() => {
                            EditQueries(row)
                        }}
                    >
                        Run/Edit
                    </span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            marginLeft: '10px',
                        }}
                        onClick={() => scheduling(row)}
                    >
                        Scheduling Settings
                    </span> */}
                    <RiDeleteBin6Line
                        style={{
                            marginLeft: '10px',
                            color: '#FC3B3B',
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                        title="delete"
                        onClick={() => {
                            setDeleteModalOpen(!deleteModalOpen)
                            setDeleteId({
                                id: row.id,
                                query: row.query_name,
                            })
                        }}
                    />
                </div>
            ),
        },
    ]

    const selectedOptionsChip = (label, value, setValue) => {
        return (
            <div className="row m-2">
                <label className="col-2">{label + ' : '}</label>
                <div className="col">
                    {value.map(val => (
                        <Chip
                            size="small"
                            key={val}
                            label={val}
                            onDelete={() => setValue(value.filter(curr => curr !== val))}
                            style={{ margin: '3px' }}
                        />
                    ))}
                </div>
            </div>
        )
    }

    const collectionsWithQueries = collectionQueries.filter(
        collection => collection.queries.filter(q => q.query_string === null).length > 0
    )

    const deleteSavedQuery = () => {
        axios
            .delete(`/news/search-query/${deleteId.id}/`)
            .then(res => {
                getCollection()
                getAlerts()
                toast('Query was successfully deleted.')
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteModalOpen(false)
            })
    }

    const deleteSavedCollection = () => {
        axios
            .delete(`/news/collection/${collectionId}/`)
            .then(res => {
                getAllCollections()
                getCollection()
                getAlerts()
                toast('Newsletter was successfully deleted.')
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteCollection(false)
            })
    }

    const deleteReport = () => {
        axios
            .delete(`/news/report-request/?id=${deleteId.id}`)
            .then(res => {
                toast('Report was successfully deleted.')
                getData('/news/report-request/', setReports)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteReportModal(false)
            })
    }

    const [selectedCollection, setSelectedCollection] = useState(null)

    const handleCollectionClick = collectionId => {
        setSelectedCollection(collectionId)
        setSaveDummyData([])
    }

    const Collections = ({ collection, onCollectionClick }) => {
        const handleClick = () => {
            onCollectionClick(collection.id)
            setCollectionName(allCollectionName.filter(val => val.label === collection.name)[0])

            // setCollectionName(collection.name)
            setCollectionDescription(collection.description)
            setCollectionPrivacy(collectionShareList.filter(val => val.value === collection.shared)[0])
            setCollectionId(collection.id)
            setCollectionUsers(users.filter(item => collection.users.includes(item.id)))

            //collections
            setIsEnabled(collection.scheduled)
            const currentTemplate = templates.filter(val => val.value === collection.template)
            if (currentTemplate.length > 0) {
                setSelectedTemplate(currentTemplate[0])
            } else {
                setSelectedTemplate(null)
            }
            if (collection.section) {
                const currentsection = sections.filter(val => val.value === collection.section)
                setSelectedSection(currentsection[0])
            } else {
                setSelectedSection(null)
            }
            if (collection.has_section) {
                setIncludeSection(true)
            } else {
                setIncludeSection(false)
            }
            if (collection.include_subcategory) {
                setIncludeBusiness(true)
            } else {
                setIncludeBusiness(false)
            }
            if (collection.subject) {
                if (collection.subject !== '') {
                    setEmailSubject(collection.subject)
                } else {
                    setEmailSubject(`${collection.name + '-insights'}`)
                }
            } else {
                setEmailSubject(`${collection.name + '-insights'}`)
            }
            if (collection.recipients) {
                const resultArray = collection.recipients.split(',')

                const filter = resultArray

                filter.forEach(email => {
                    const existingUser = users.find(user => user.email === email)

                    if (!existingUser) {
                        const newUser = {
                            id: email,
                            email: email,
                            full_name: email,
                        }

                        users.push(newUser)
                    }
                })

                const currentUser = users.filter(val => resultArray.includes(val.email))

                if (currentUser.length > 0) {
                    setSelectedUsers(currentUser)
                }
            } else {
                setSelectedUsers([])
            }
            if (collection.cron_expression) {
                setCronTabValue(LastCronValue(collection.cron_expression, '+'))
            } else {
                setCronTabValue('0 0 * * 1')
            }
            if (collection.apscheduler_id) {
                setEditCron(collection.apscheduler_id)
            } else {
                setEditCron(null)
            }
        }

        return (
            <div
                style={{
                    width: '100%',
                    padding: '15px 10px',
                    textAlign: 'left',
                    display: 'block',
                    background: 'white',
                    borderRadius: '0px',
                    textTransform: 'none',
                    borderTop: '1px solid lightgray',
                }}
            >
                <div className="d-flex align-items-center" style={{ fontSize: '13px' }}>
                    <span className="col-5" style={{ marginLeft: '10px' }}>
                        {collection.name}
                        {/* <span>({collection.queries.length})</span> */}
                    </span>

                    <span className="col-4">
                        {collection.scheduled ? (
                            <span className="d-flex align-items-center ml_status" style={{ color: '#077D55' }}>
                                <span>
                                    <ToggleOnIcon style={{ fontSize: 'x-large' }} />
                                </span>
                                <span style={{ marginLeft: '5px' }} className="d-none d-sm-block">
                                    Enabled
                                </span>
                            </span>
                        ) : (
                            <span className="d-flex align-items-center ml_status" style={{ color: 'lightgray' }}>
                                <span>
                                    <ToggleOffIcon style={{ fontSize: 'x-large' }} />
                                </span>
                                <span style={{ marginLeft: '5px' }} className="d-none d-sm-block">
                                    Disabled
                                </span>
                            </span>
                        )}
                    </span>

                    <span className="col d-flex align-items-center" style={{ textAlign: 'center', marginRight: '10%' }}>
                        <span className="d-flex align-items-center">
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    color: '#186ADE',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    textDecoration: 'underline',
                                }}
                                onClick={handleClick}
                            >
                                <span className="d-block d-sm-none">
                                    <GrEdit style={{ color: '#186ADE' }} />
                                </span>
                                <span className="d-none d-sm-block">View/Edit</span>
                            </span>
                            {/* <span
                                style={{
                                    textDecoration: 'underline',
                                    color: collection.is_editable ? '#186ADE' : 'lightgray',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    textDecoration: 'underline',
                                    marginLeft: '10px',
                                }}
                                onClick={() => {
                                    if (collection.is_editable) {
                                        setCollectionName(collection.name)
                                        setCollectionDescription(collection.description)
                                        setCollectionPrivacy(
                                            collectionShareList.filter(val => val.value === collection.shared)[0]
                                        )
                                        setCollectionId(collection.id)
                                        setCollectionUsers(users.filter(item => collection.users.includes(item.id)))
                                        setEditCollection(!editCollection)
                                    }
                                }}
                            >
                                Edit
                            </span> */}
                        </span>

                        <span>
                            <span
                                onClick={() => {
                                    setCollectionName(collection.name)
                                    setCollectionId(collection.id)
                                    setDeleteCollection(!deleteCollection)
                                }}
                            >
                                <RiDeleteBin6Line
                                    style={{
                                        color: '#FC3B3B',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        marginLeft: '10px',
                                    }}
                                    className={'delete_action_btn'}
                                    title="delete"
                                />{' '}
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        )
    }

    const QueryTable = ({ queries }) => {
        const columns = [
            {
                name: <label style={{ color: 'white' }}>Title</label>,
                cell: row => (
                    // <span className="d-flex align-items-center">
                    //     <span>
                    //         <CgNotes style={{ color: 'black', fontSize: '20px' }} />
                    //     </span>
                    //     <span style={{ marginLeft: '15px' }}> {row.query_name}</span>
                    // </span>
                    // <div className="d-flex align-items-center">
                    //     <input
                    //         type="checkbox"
                    //         checked={selectedSavedSearch.some(selectedRow => selectedRow.id === row.id)}
                    //         onChange={() => toggleRow(row)}
                    //     />
                    //     <span style={{ marginLeft: '10px' }}> {row.query_name}</span>
                    // </div>
                    <div className="d-flex align-items-center">
                        <input
                            type="checkbox"
                            checked={saveDummyData.some(selectedRow => selectedRow.id === row.id)}
                            onChange={() => toggleRow(row)}
                        />
                        <span className="d-flex align-items-center">
                            <span style={{ marginLeft: '15px' }}> {row.query_name}</span>
                        </span>
                    </div>
                ),
                width: '40%',
            },

            {
                name: <label style={{ color: 'white' }}>Search Type</label>,
                cell: row =>
                    row.quick_search_query !== null ? (
                        <span
                            className="d-flex align-items-center p-sm-2"
                            style={{
                                color: 'rgb(57, 150, 218)',
                                background: 'rgb(178 210 233)',
                                // padding: '5px',
                                // marginLeft: '-10px',
                                borderRadius: '25px',
                            }}
                        >
                            Quick Search
                        </span>
                    ) : (
                        <span
                            className="d-flex align-items-center"
                            style={{
                                color: 'rgb(57, 150, 218)',
                                background: 'rgb(178 210 233)',
                                padding: '5px',
                                marginLeft: '-10px',
                                borderRadius: '25px',
                            }}
                        >
                            Advanced Search
                        </span>
                    ),
                textAlign: 'center',
                width: '30%',
            },
            {
                name: <label style={{ color: 'white' }}>Action</label>,
                cell: row => (
                    <div style={{ cursor: 'pointer' }}>
                        <span className="col">
                            <span className="d-flex align-items-center">
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        color: '#186ADE',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        // marginLeft: '10px',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        setEditOnly(false)
                                        EditQueries(row)
                                    }}
                                    className="d-flex align-items-center"
                                >
                                    <span className="d-block d-sm-none">
                                        <GrEdit style={{ color: '#186ADE' }} />
                                    </span>
                                    <span className="d-none d-sm-block">Run</span>
                                </span>

                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        color: '#186ADE',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        marginLeft: '10px',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        setEditOnly(true)
                                        EditQueries(row, 'edit')
                                    }}
                                    className="d-flex align-items-center"
                                >
                                    <span className="d-block d-sm-none">
                                        <GrEdit style={{ color: '#186ADE' }} />
                                    </span>
                                    <span className="d-none d-sm-block">Edit</span>
                                </span>

                                <RiDeleteBin6Line
                                    style={{
                                        marginLeft: '10px',
                                        color: '#FC3B3B',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        marginLeft: '10px',
                                    }}
                                    className="delete_action_btn"
                                    onClick={() => {
                                        setDeleteModalOpen(!deleteModalOpen)
                                        setDeleteId({
                                            id: row.id,
                                            query: row.query_name,
                                        })
                                    }}
                                    title="delete"
                                />
                            </span>
                        </span>
                    </div>
                ),
            },
        ]

        return (
            <div className="">
                <DataTable
                    style={{ marginTop: '15px' }}
                    customStyles={customStyles}
                    columns={columns}
                    data={queries}
                    noHeader={true}
                    pagination
                />
            </div>
        )
    }

    const DummyQueryTable = ({ queries }) => {
        const columns = [
            {
                // name: <label>Name</label>,
                cell: row => (
                    <span className="d-flex align-items-center">
                        <span>
                            <CgNotes style={{ color: 'black', fontSize: '20px' }} />
                        </span>
                        <span style={{ marginLeft: '15px' }}> {row.query_name}</span>
                    </span>
                ),
                width: '85%',
            },

            {
                cell: (row, index) => (
                    <div style={{ cursor: 'pointer' }}>
                        <span className="col">
                            <span className="d-flex align-items-center">
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        color: '#186ADE',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        // marginLeft: '10px',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        setEditOnly(false)
                                        collectionEdit(row, index)
                                    }}
                                    className="d-flex align-items-center"
                                >
                                    <span className="d-block d-sm-none">
                                        <GrEdit style={{ color: '#186ADE' }} />
                                    </span>
                                    <span className="d-none d-sm-block">Run</span>
                                </span>
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        color: '#186ADE',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        marginLeft: '10px',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        setEditOnly(true)
                                        collectionEdit(row, index, 'edit')
                                    }}
                                    className="d-flex align-items-center"
                                >
                                    <span className="d-block d-sm-none">
                                        <GrEdit style={{ color: '#186ADE' }} />
                                    </span>
                                    <span className="d-none d-sm-block">Edit</span>
                                </span>

                                <RiDeleteBin6Line
                                    style={{
                                        marginLeft: '10px',
                                        color: '#FC3B3B',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        marginLeft: '10px',
                                    }}
                                    className="delete_action_btn"
                                    onClick={() => {
                                        setDeleteModalOpen(!deleteModalOpen)
                                        setDeleteId({
                                            id: index,
                                            query: row.query_name,
                                        })
                                    }}
                                    title="delete"
                                />
                            </span>
                        </span>
                    </div>
                ),
                textAlign: 'end',
            },
        ]

        return (
            <div className="collection_queries" style={{ width: '90%', margin: 'auto' }}>
                <DataTable
                    style={{ marginTop: '15px' }}
                    customStyles={customStyles}
                    columns={columns}
                    data={queries}
                    noHeader={true}
                />
            </div>
        )
    }

    const deleteDummyData = () => {
        const filterDummyData = saveDummyData.filter((v, i) => i !== deleteId.id)
        setSaveDummyData(filterDummyData)
        setDeleteModalOpen(!deleteModalOpen)
    }

    const SendNewsLetterButton = () => {
        return (
            <Button
                variant="contained"
                endIcon={<SendIcon style={{ fontSize: '18px' }} className="ml_icons" />}
                style={{
                    background: '#186ADE',
                    borderRadius: '4px',
                    width: 'max-content',
                    marginRight: '40px',
                    fontSize: '14px',
                    height: '40px',
                    color: 'white',
                    opacity: selectedStories.length === 0 || summaryLoader ? 0.6 : 1,
                    textTransform: 'none',
                }}
                disabled={selectedStories.length === 0 || summaryLoader}
                onClick={() => {
                    setActiveStep(1)
                }}
            >
                <span className="d-none d-sm-block">Send Newsletter</span>
            </Button>
        )
    }

    const NewsSearchSection = () => {
        return (
            <div className="search_prompts m-3" style={{ padding: '0px 20px' }}>
                {newsFilters && (
                    <div ref={filterInputRef}>
                        <div
                            className="news_filter_section"
                            style={{
                                height: '100vh',
                                transition: 'opacity 3s ease',
                                overflow: 'auto',
                                position: 'fixed',
                                width: '240px',
                                top: 0,
                                left: 0,
                                zIndex: 10,
                                background: '#051340',
                                color: 'white',
                            }}
                        >
                            <div style={{}}>
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: '80%', margin: '21.5px auto' }}
                                >
                                    <label style={{ fontSize: '16px', color: 'white' }}>Filters</label>
                                    <span style={{ cursor: 'pointer' }} onClick={() => setNewsFilters(false)}>
                                        <IoIosClose style={{ fontSize: '26px' }} />
                                    </span>
                                </div>
                                <hr style={{ background: 'lightgray' }} />
                                <div style={{ margin: 'auto' }}>
                                    <QuickAdvanceFilters
                                        allRegions={allRegions}
                                        setAllRegions={setAllRegions}
                                        allCompanies={allCompanies}
                                        setAllCompanies={setAllCompanies}
                                        allIndustries={allIndustries}
                                        setAllIndustries={setAllIndustries}
                                        allCategories={allCategories}
                                        setAllCategories={setAllCategories}
                                        allSubCategories={allSubCategories}
                                        setAllSubCategories={setAllSubCategories}
                                        allTopics={allTopics}
                                        setAllTopics={setAllTopics}
                                        allSources={allSources}
                                        setAllSources={setAllSources}
                                        basicSearchInput={basicSearchInput}
                                        basicSearchResponse={basicSearchResponse}
                                        setBasicSearchResponse={setBasicSearchResponse}
                                        // btnColour={btnColour}
                                        // setBtnColour={setBtnColour}
                                        selectedCategories={selectedCategories}
                                        setSelectedCategories={setSelectedCategories}
                                        selectedCompanies={selectedCompanies}
                                        setSelectedCompanies={setSelectedCompanies}
                                        selectedTopics={selectedTopics}
                                        setSelectedTopics={setSelectedTopics}
                                        selectedSources={selectedSources}
                                        setSelectedSources={setSelectedSources}
                                        selectedRegions={selectedRegions}
                                        setSelectedRegions={setSelectedRegions}
                                        selectedSectors={selectedSectors}
                                        setSelectedSectors={setSelectedSectors}
                                        selectedDateTo={selectedDateTo}
                                        setSelectedDateTo={setSelectedDateTo}
                                        selectedDatefrom={selectedDatefrom}
                                        setSelectedDateFrom={setSelectedDateFrom}
                                        setIsLoading={setIsLoading}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        allHierarchy={allHierarchy}
                                        setAllHierarchy={setAllHierarchy}
                                        practiceSubCategory={practiceSubCategory}
                                        setPracticeSubCategory={setPracticeSubCategory}
                                        regionHierarchy={regionHierarchy}
                                        setRegionHierarchy={setRegionHierarchy}
                                        // apiLevel={apiLevel}
                                        // popularBtns={popularsearch.length}
                                        currentPath={currentPath}
                                        // getAllCompanies={getAllCompanies}
                                        usertype={usertype}
                                        allIndustriesName={allIndustriesName}
                                        allSectorsName={allSectorsName}
                                        allTopicsName={allTopicsName}
                                        addingOfIndustriesSectorsNames={addingOfIndustriesSectorsNames}
                                        defaultData={defaultData}
                                        setDefaultData={setDefaultData}
                                        // dateFormatChanges={dateFormatChanges}
                                        customDate={customDate}
                                        setcustomDate={setcustomDate}
                                        dateSection={dateSection}
                                        setDateSection={setDateSection}
                                        generateQuery={generateQuery}
                                        // selectedRelavance={selectedRelavance}
                                        // setSelectedRelavance={setSelectedRelavance}
                                        // selectedAlpha={selectedAlpha}
                                        // setSelectedAlpha={setSelectedAlpha}
                                        selectedLevel={selectedLevel}
                                        setSelectedLevel={setSelectedLevel}
                                        clearFilters={clearQuickFilters}
                                        selectedSubCategories={selectedSubCategories}
                                        setSelectedSubCategories={setSelectedSubCategories}
                                        customOnchange={customOnchange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <label className="d-flex align-items-center" style={{ fontWeight: 400, fontSize: '16px' }}>
                    Search Prompt
                </label>

                <NewSearch
                    basicSearch={weviateSearch}
                    basicSearchInput={basicSearchInput}
                    setBasicSearchInput={setBasicSearchInput}
                    clearFilters={clearFilters}
                    selectedOption={selectedOption}
                    usertype={usertype}
                    allCompanies={allCompanies}
                    allTopics={allTopics}
                    allSubCategories={allSubCategories}
                    allIndustries={allIndustries}
                    basicSearchDrop={basicSearchDrop}
                    setBasicSearchDrop={setBasicSearchDrop}
                    setSaveQueryName={setSaveQueryName}
                    defaultData={defaultData}
                    setDefaultData={setDefaultData}
                    setNewsFilters={setNewsFilters}
                    filterRef={filterRef}
                    wrapperRef={wrapperRef}
                    OpenFilter={OpenFilter}
                />

                {/* <div className="col mt-2 text-end">
                    <Button
                        variant="contained"
                        endIcon={<SendIcon style={{ fontSize: '18px' }} />}
                        style={{
                            background: '#186ADE',
                            borderRadius: '4px',
                            width: 'max-content',
                            // marginRight: '20px',
                            fontSize: '14px',
                            height: '40px',
                            color: 'white',
                            opacity: selectedStories.length > 0 ? 1 : 0.6,
                            textTransform: 'none',
                        }}
                        disabled={selectedStories.length > 0 ? false : true}
                        onClick={() => {
                            setActiveStep(1)
                        }}
                    >
                        Send Newsletter
                    </Button>
                </div> */}

                {filterLength !== 0 && (
                    <JustShowFilters
                        combinedFilters={combinedFilters}
                        filterLength={filterLength}
                        setNewsFilters={setNewsFilters}
                        selectedRegions={selectedRegions}
                        setSelectedRegions={setSelectedRegions}
                        selectedSectors={selectedSectors}
                        setSelectedSectors={setSelectedSectors}
                        regionHierarchy={regionHierarchy}
                        allHierarchy={allHierarchy}
                    />
                )}
                {isLoading && (
                    <div style={{ marginTop: '10%' }} className="d-flex align-items-center justify-content-center">
                        {/* <Circles key={0} type="Circles" color="#adadad" height={80} width={80} /> */}
                        <CustomLoader progressContent={progressContent} />
                    </div>
                )}

                {basicSearchResponse.data && basicSearchResponse.data.length === 0 && !isLoading && !editOnly && (
                    <div style={{ textAlign: 'center', marginTop: '100px' }}>
                        <NewNoResults />
                    </div>
                )}

                {basicSearchResponse.data && basicSearchResponse.data.length === 0 && !isLoading && editOnly && (
                    <div style={{ textAlign: 'center', marginTop: '100px' }}>
                        <FaSearch style={{ fontSize: '100px', color: 'lightgray' }} />
                        <p style={{ color: 'lightgray' }} className="mt-2">
                            Start typing relevant keywords to see results
                        </p>
                    </div>
                )}

                {basicSearchResponse.data.length !== 0 &&
                    basicSearchResponse.data.filter(
                        val => (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
                    ).length > 0 && (
                        <>
                            <div style={{ width: '99%', marginTop: '15px' }}>
                                <NewsHeaderSection
                                    newsData={basicSearchResponse}
                                    isAllSelected={isAllSelected}
                                    setAllSelected={setAllSelected}
                                    isMyRequestPage={true}
                                    currentDate={
                                        isNaN(currentDate) &&
                                        customDate.startDate !== null &&
                                        customDate.endDate !== null
                                            ? // ? calculateDaysBetween(customDate.startDate, customDate.endDate)
                                              customDate
                                            : currentDate
                                    }
                                    filterLength={filterLength}
                                    setNewsFilters={setNewsFilters}
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    relevantNews={relevantNews}
                                    searchName={basicSearchInput}
                                    // customDate={
                                    //     isNaN(currentDate) &&
                                    //     customDate.startDate !== null &&
                                    //     customDate.endDate !== null
                                    // }
                                    customDate={selectedOption}
                                    // approxLength={approxLength && basicSearchResponse.next}
                                />
                                <Grid item>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={!loadMoreLoader && handleLoadQuick}
                                        hasMore={basicSearchResponse.next < basicSearchResponse.total}
                                        loader={
                                            loadMoreLoader && (
                                                <div className="mt-1 d-flex align-items-center justify-content-center">
                                                    <CustomLoader />
                                                </div>
                                            )
                                        }
                                    >
                                        {basicSearchResponse.data
                                            .sort((a, b) =>
                                                sortBy === 'date'
                                                    ? a.date.split('T')[0] > b.date.split('T')[0]
                                                        ? -1
                                                        : 1
                                                    : sortBy === 'relevance'
                                                    ? a.score > b.score
                                                        ? -1
                                                        : 1
                                                    : 0
                                            )
                                            .map(story => {
                                                return (
                                                    // <NewSearchQueryResult
                                                    //     key={story.id}
                                                    //     EachrawNews={story}
                                                    //     isBasicSearch={true}
                                                    //     isStorySelected={false}
                                                    //     allCategories={allCategories}
                                                    //     allSubCategories={allSubCategories}
                                                    //     allIndustries={allIndustries}
                                                    //     allCompanies={allCompanies}
                                                    //     setAllCompanies={setAllCompanies}
                                                    //     allRegions={allRegions}
                                                    //     allLinkedInGroups={[]}
                                                    //     allTopics={allTopics}
                                                    //     collapsed={!expandedView}
                                                    //     usertype={usertype}
                                                    //     currentPath={currentPath}
                                                    //     isScore={true}
                                                    //     isAssisstant={true}
                                                    // />
                                                    <NewSearchQueryResult
                                                        isFindAndShare={true}
                                                        key={story.id}
                                                        EachrawNews={story}
                                                        isBasicSearch={true}
                                                        selectedStories={selectedStories}
                                                        isStorySelected={
                                                            selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                                ? false
                                                                : true
                                                        }
                                                        addToSelection={addToSelection}
                                                        allCategories={allCategories}
                                                        allSubCategories={allSubCategories}
                                                        allIndustries={allIndustries}
                                                        allCompanies={allCompanies}
                                                        setAllCompanies={setAllCompanies}
                                                        allRegions={allRegions}
                                                        allLinkedInGroups={[]}
                                                        removeFromSelection={removeFromSelection}
                                                        allTopics={allTopics}
                                                        collapsed={!expandedView}
                                                        usertype={usertype}
                                                        selectAll={isAllSelected}
                                                        currentPath={currentPath}
                                                        selectedNewsOnly={selectedNewsOnly}
                                                        isScore={true}
                                                        selectedLevel={selectedLevel}
                                                        isAssisstant={createNews ? true : false}
                                                        generateSummary={generateSummary}
                                                        duplicateCollapsed={!duplicateCollapsed}
                                                    />
                                                )
                                            })}
                                    </InfiniteScroll>
                                </Grid>
                            </div>
                        </>
                    )}
            </div>
        )
    }

    const SearchField = () => {
        return (
            <TextField
                value={search}
                onChange={e => {
                    setSearch(e.target.value)
                }}
                placeholder="Search here..."
                variant="outlined"
                sx={{
                    // width: '25%',
                    background: 'white',
                    borderRadius: '5px',
                }}
                className="filter_width"
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        )
    }

    const addToSelection = story => {
        setSelectedStories([...selectedStories, story])
    }

    const removeFromSelection = story => {
        let selectedCopy = selectedStories.filter(arr => arr.id !== story.id)
        setSelectedStories(selectedCopy)
    }

    const generateSummary = news => {
        setSummaryLoader(news)
    }

    const combinedFilters = [
        ...new Set([
            ...selectedSources.map(item => ({
                name: item,
                value: selectedSources,
                setValue: setSelectedSources,
                type: 'source',
            })),
            ...selectedRegions.map(item => ({
                name: item,
                value: selectedRegions,
                setValue: setSelectedRegions,
                type: 'region',
            })),
            ...selectedSubCategories.map(item => ({
                name: item,
                value: selectedSubCategories,
                setValue: setSelectedSubCategories,
                type: 'subCategory',
            })),
            ...selectedCompanies.map(item => ({
                name: item,
                value: selectedCompanies,
                setValue: setSelectedCompanies,
                type: 'company',
            })),
            ...selectedSectors.map(item => ({
                name: item,
                value: selectedSectors,
                setValue: setSelectedSectors,
                type: 'sector',
            })),
        ]),
    ]

    const filterLength = combinedFilters.length

    return (
        <BaseScreen>
            {activeStep !== 0 && (
                <div style={{ width: '98%', margin: 'auto', marginBottom: '10px' }}>
                    <div class="d-flex align-items-center justify-content-between">
                        <div />

                        <div
                            style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<MdNavigateBefore />}
                                onClick={() => {
                                    setActiveStep(activeStep => activeStep - 1)
                                    activeStep === 2 && setIncludeSection(false)
                                }}
                                style={{
                                    background: '#cfcfcf00',
                                    color: 'gray',
                                    boxShadow: 'none',
                                    borderRadius: '2px',
                                    marginRight: '20px',
                                }}
                            >
                                Back
                            </Button>

                            {activeStep !== 3 && activeStep !== 4 && activeStep !== 1 && (
                                <Button
                                    variant="contained"
                                    endIcon={<MdNavigateNext />}
                                    onClick={() => {
                                        setActiveStep(activeStep => activeStep + 1)
                                    }}
                                    disabled={
                                        selectedPreviewTempalte === undefined ||
                                        selectedPreviewTempalte === null ||
                                        selectedPreviewTempalte === ''
                                            ? true
                                            : false
                                    }
                                    style={{
                                        background: '#186ADE',
                                        color: 'white',
                                        borderRadius: '2px',
                                        opacity:
                                            (activeStep === 1 && selectedPreviewTempalte === undefined) ||
                                            selectedPreviewTempalte === null ||
                                            selectedPreviewTempalte === '' ||
                                            (selectedPreviewTempalte !== null &&
                                                selectedPreviewTempalte.has_sections &&
                                                activeStep === 2 &&
                                                categorizedNews.length === 0)
                                                ? 0.6
                                                : 1,
                                    }}
                                >
                                    {activeStep === 3 ? 'Send' : 'Next'}
                                </Button>
                            )}
                        </div>
                    </div>
                    {/* <hr /> */}
                </div>
            )}

            {activeStep === 0 && (
                <>
                    <div style={{ width: '99%' }}>
                        <div className="row">
                            <div className="col">
                                {!EditPage ? (
                                    <>
                                        {!isLoader ? (
                                            <>
                                                <div style={{ background: 'white', width: '100%', marginTop: '-19px' }}>
                                                    {pathName === '/app/request/' &&
                                                        !createNews &&
                                                        selectedCollection === null && (
                                                            <Tabs
                                                                value={activeTab}
                                                                onChange={(event, newValue) => {
                                                                    setActiveTab(newValue)
                                                                    setSearch('')
                                                                }}
                                                                style={{ marginTop: '-5px', background: 'white' }}
                                                            >
                                                                <Tab
                                                                    label="Newsletters"
                                                                    style={{ marginLeft: '30px' }}
                                                                />
                                                                <Tab label="Saved Searches" />
                                                                <Tab label="Reports" />
                                                            </Tabs>
                                                        )}
                                                </div>

                                                <div className="tables" style={{ marginTop: '10px' }}>
                                                    {!createNews ? (
                                                        <>
                                                            {activeTab === 2 && (
                                                                <>
                                                                    {reports.length !== 0 && (
                                                                        <div
                                                                            style={{
                                                                                padding: '20px 30px 0px 30px',
                                                                                margin: 'auto',
                                                                            }}
                                                                        >
                                                                            {SearchField()}
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            padding: '20px 30px 0px 30px',
                                                                            margin: 'auto',
                                                                        }}
                                                                    >
                                                                        <DataTable
                                                                            columns={reportcolumns}
                                                                            data={
                                                                                search === ''
                                                                                    ? reports
                                                                                    : reports.filter(each =>
                                                                                          each.title
                                                                                              .toLowerCase()
                                                                                              .includes(
                                                                                                  search.toLowerCase()
                                                                                              )
                                                                                      )
                                                                            }
                                                                            pagination
                                                                            paginationPerPage={rowsPerPage.reports}
                                                                            onChangeRowsPerPage={currentRowsPerPage =>
                                                                                handleChangeRowsPerPage(
                                                                                    currentRowsPerPage,
                                                                                    'reports'
                                                                                )
                                                                            }
                                                                            fixedHeader
                                                                            noHeader={true}
                                                                            customStyles={customStyles}
                                                                            noDataComponent={
                                                                                <div className="m-auto">
                                                                                    <div className="d-flex justify-content-center bg-white m-4">
                                                                                        There are no reports to display
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            {activeTab === 1 && (
                                                                <>
                                                                    {alerts.length !== 0 && (
                                                                        <div
                                                                            style={{
                                                                                padding: '20px 30px 0px 30px',
                                                                                margin: 'auto',
                                                                            }}
                                                                        >
                                                                            {SearchField()}
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            padding: '20px 30px 0px 30px',
                                                                            margin: 'auto',
                                                                        }}
                                                                    >
                                                                        <DataTable
                                                                            columns={alertcolumns}
                                                                            data={
                                                                                search === ''
                                                                                    ? alerts
                                                                                    : alerts.filter(each =>
                                                                                          each.query_name
                                                                                              .toLowerCase()
                                                                                              .includes(
                                                                                                  search.toLowerCase()
                                                                                              )
                                                                                      )
                                                                            }
                                                                            pagination
                                                                            paginationPerPage={rowsPerPage.alerts}
                                                                            onChangeRowsPerPage={currentRowsPerPage =>
                                                                                handleChangeRowsPerPage(
                                                                                    currentRowsPerPage,
                                                                                    'alerts'
                                                                                )
                                                                            }
                                                                            fixedHeader
                                                                            noHeader={true}
                                                                            customStyles={customStyles}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            {activeTab === 0 && (
                                                                <>
                                                                    {!selectedCollection &&
                                                                        collectionsWithQueries.length !== 0 && (
                                                                            <div
                                                                                style={{
                                                                                    padding: '20px 30px 0px 30px',
                                                                                    margin: 'auto',
                                                                                }}
                                                                            >
                                                                                {SearchField()}
                                                                            </div>
                                                                        )}

                                                                    {selectedCollection === null &&
                                                                        collectionsWithQueries.filter(each =>
                                                                            each.name
                                                                                .toLowerCase()
                                                                                .includes(search.toLowerCase())
                                                                        ).length !== 0 && (
                                                                            <div
                                                                                style={{
                                                                                    padding: '20px 30px 0px 30px',
                                                                                    margin: 'auto',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="d-flex align-items-center p-3 text-white"
                                                                                    style={{ background: '#00193C' }}
                                                                                >
                                                                                    <span
                                                                                        className="col-5"
                                                                                        style={{ marginLeft: '15px' }}
                                                                                    >
                                                                                        <label
                                                                                            style={{ color: 'white' }}
                                                                                        >
                                                                                            Title
                                                                                        </label>
                                                                                    </span>

                                                                                    <span className="col-4">
                                                                                        <label
                                                                                            style={{ color: 'white' }}
                                                                                        >
                                                                                            Status
                                                                                        </label>
                                                                                    </span>

                                                                                    <span
                                                                                        className="col"
                                                                                        style={{
                                                                                            textAlign: 'end',
                                                                                            marginRight: '15%',
                                                                                        }}
                                                                                    >
                                                                                        <label
                                                                                            style={{ color: 'white' }}
                                                                                        >
                                                                                            Actions
                                                                                        </label>
                                                                                    </span>
                                                                                </div>
                                                                                {collectionsWithQueries
                                                                                    .filter(each =>
                                                                                        each.name
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                search.toLowerCase()
                                                                                            )
                                                                                    )
                                                                                    .map((collection, index) => (
                                                                                        <Collections
                                                                                            key={index}
                                                                                            collection={collection}
                                                                                            onCollectionClick={
                                                                                                handleCollectionClick
                                                                                            }
                                                                                        />
                                                                                    ))}
                                                                            </div>
                                                                        )}

                                                                    <div
                                                                        style={{
                                                                            padding: '20px 30px 0px 30px',
                                                                            margin: 'auto',
                                                                        }}
                                                                    >
                                                                        {!selectedCollection && (
                                                                            <Button
                                                                                variant="contained"
                                                                                endIcon={<AddIcon />}
                                                                                style={{
                                                                                    background: '#186ADE',
                                                                                    borderRadius: '4px',
                                                                                    width: 'max-content',
                                                                                    // marginRight: '40px',
                                                                                    // marginTop: '20px',
                                                                                    fontSize: '14px',
                                                                                    height: '40px',
                                                                                    color: 'white',
                                                                                    boxShadow: 'none',
                                                                                    textTransform: 'none',
                                                                                }}
                                                                                onClick={() => {
                                                                                    setBasicSearchInput('')
                                                                                    setBasicSearchResponse({
                                                                                        data: [],
                                                                                        next: null,
                                                                                    })
                                                                                    setCreateNews(true)
                                                                                    setSaveDummyData([])
                                                                                    setSelectedStories([])
                                                                                    setSelectedSources([])
                                                                                    setSelectedOption('29')
                                                                                    setSearch('')
                                                                                    clearQuickFilters()
                                                                                    setEditOnly(true)
                                                                                }}
                                                                            >
                                                                                Create a new Newsletter
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                    {collectionsWithQueries.length === 0 && (
                                                                        <div
                                                                            style={{
                                                                                padding: '20px 30px 0px 30px',
                                                                                margin: 'auto',
                                                                            }}
                                                                        >
                                                                            <div className="d-flex justify-content-center bg-white">
                                                                                <p style={{ marginTop: '20px' }}>
                                                                                    There are no records to display
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    {selectedCollection && (
                                                                        <>
                                                                            <div style={{ marginTop: '20px' }}>
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <div>
                                                                                        <ArrowBackIcon
                                                                                            onClick={() => {
                                                                                                setCollectionTab(0)
                                                                                                setSelectedCollection(
                                                                                                    null
                                                                                                )
                                                                                                setSelectedStories([])
                                                                                                setBasicSearchResponse({
                                                                                                    data: [],
                                                                                                    next: null,
                                                                                                })
                                                                                                setRunAllSearches({
                                                                                                    data: [],
                                                                                                })
                                                                                                setSaveDummyData([])
                                                                                                cancelRequest()
                                                                                            }}
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />

                                                                                        <label
                                                                                            style={{
                                                                                                fontSize: '16px',
                                                                                                marginLeft: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Newsletters /{' '}
                                                                                            {
                                                                                                collectionsWithQueries
                                                                                                    .filter(
                                                                                                        c =>
                                                                                                            c.id ===
                                                                                                            selectedCollection
                                                                                                    )
                                                                                                    .map(c => c.name)[0]
                                                                                            }
                                                                                        </label>
                                                                                    </div>

                                                                                    {selectedStories.length > 0 && (
                                                                                        <div>
                                                                                            {SendNewsLetterButton()}
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                <Tabs
                                                                                    value={collectionTab}
                                                                                    onChange={(event, newValue) => {
                                                                                        setCollectionTab(newValue)
                                                                                    }}
                                                                                    style={{
                                                                                        background: 'white',
                                                                                        marginTop: '20px',
                                                                                    }}
                                                                                >
                                                                                    <Tab
                                                                                        label="Searches"
                                                                                        style={{ marginLeft: '40px' }}
                                                                                    />
                                                                                    <Tab label="Newsletter Settings" />
                                                                                </Tabs>
                                                                                {collectionTab === 0 && (
                                                                                    <div
                                                                                        style={{
                                                                                            width: '95%',
                                                                                            margin: 'auto',
                                                                                        }}
                                                                                    >
                                                                                        {collectionsWithQueries.filter(
                                                                                            c =>
                                                                                                c.id ===
                                                                                                selectedCollection
                                                                                        ).length !== 0 ? (
                                                                                            <>
                                                                                                <QueryTable
                                                                                                    queries={
                                                                                                        collectionsWithQueries.find(
                                                                                                            c =>
                                                                                                                c.id ===
                                                                                                                selectedCollection
                                                                                                        ).queries
                                                                                                    }
                                                                                                />
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <Button
                                                                                                        variant="contained"
                                                                                                        // endIcon={
                                                                                                        //     // isLoading ? (
                                                                                                        //     //     <Spinner
                                                                                                        //     //         style={{
                                                                                                        //     //             fontSize:
                                                                                                        //     //                 '12px',
                                                                                                        //     //             width:
                                                                                                        //     //                 '20px',
                                                                                                        //     //             height:
                                                                                                        //     //                 '20px',
                                                                                                        //     //         }}
                                                                                                        //     //     />
                                                                                                        //     // ) : (
                                                                                                        //     //     <MdNavigateNext />
                                                                                                        //     // )
                                                                                                        // }
                                                                                                        style={{
                                                                                                            background:
                                                                                                                '#186ADE',
                                                                                                            borderRadius:
                                                                                                                '4px',
                                                                                                            width:
                                                                                                                'max-content',
                                                                                                            // marginRight: '40px',
                                                                                                            marginTop:
                                                                                                                '20px',
                                                                                                            fontSize:
                                                                                                                '14px',
                                                                                                            height:
                                                                                                                '40px',
                                                                                                            color:
                                                                                                                'white',
                                                                                                            boxShadow:
                                                                                                                'none',
                                                                                                            textTransform:
                                                                                                                'none',
                                                                                                            opacity: isLoading
                                                                                                                ? 0.6
                                                                                                                : 1,
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            saveDummyData.length ===
                                                                                                                0 &&
                                                                                                                setSaveDummyData(
                                                                                                                    collectionsWithQueries.find(
                                                                                                                        c =>
                                                                                                                            c.id ===
                                                                                                                            selectedCollection
                                                                                                                    )
                                                                                                                        .queries
                                                                                                                )
                                                                                                            const allqueries =
                                                                                                                saveDummyData.length >
                                                                                                                0
                                                                                                                    ? saveDummyData
                                                                                                                    : collectionsWithQueries.find(
                                                                                                                          c =>
                                                                                                                              c.id ===
                                                                                                                              selectedCollection
                                                                                                                      )
                                                                                                                          .queries

                                                                                                            fetchAllQueriesInParallel(
                                                                                                                allqueries
                                                                                                            )
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            isLoading
                                                                                                        }
                                                                                                    >
                                                                                                        Run All Searches
                                                                                                    </Button>

                                                                                                    <Button
                                                                                                        variant="contained"
                                                                                                        style={{
                                                                                                            background:
                                                                                                                'lightgray',
                                                                                                            borderRadius:
                                                                                                                '4px',
                                                                                                            width:
                                                                                                                'max-content',
                                                                                                            marginTop:
                                                                                                                '20px',
                                                                                                            fontSize:
                                                                                                                '14px',
                                                                                                            height:
                                                                                                                '40px',
                                                                                                            color:
                                                                                                                'black',
                                                                                                            boxShadow:
                                                                                                                'none',
                                                                                                            textTransform:
                                                                                                                'none',
                                                                                                            marginLeft:
                                                                                                                '10px',
                                                                                                            display:
                                                                                                                runAllSearches
                                                                                                                    .data
                                                                                                                    .length >
                                                                                                                0
                                                                                                                    ? 'block'
                                                                                                                    : 'none',
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            cancelRequest()
                                                                                                            setRunAllSearches(
                                                                                                                {
                                                                                                                    data: [],
                                                                                                                }
                                                                                                            )
                                                                                                            setSaveDummyData(
                                                                                                                []
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        Clear
                                                                                                    </Button>
                                                                                                </div>

                                                                                                {isLoading && (
                                                                                                    <div className="mt-2 d-flex align-items-center justify-content-center">
                                                                                                        <CustomLoader
                                                                                                            progressContent={
                                                                                                                progressContent
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                                {runAllSearches.data
                                                                                                    .length > 0 && (
                                                                                                    <div className="runall_search">
                                                                                                        <NewsHeaderSection
                                                                                                            newsData={
                                                                                                                runAllSearches
                                                                                                            }
                                                                                                            isAllSelected={
                                                                                                                isAllSelected
                                                                                                            }
                                                                                                            setAllSelected={
                                                                                                                setAllSelected
                                                                                                            }
                                                                                                            isMyRequestPageRunAll={
                                                                                                                true
                                                                                                            }
                                                                                                            filterLength={
                                                                                                                filterLength
                                                                                                            }
                                                                                                            setNewsFilters={
                                                                                                                setNewsFilters
                                                                                                            }
                                                                                                            sortBy={
                                                                                                                sortBy
                                                                                                            }
                                                                                                            setSortBy={
                                                                                                                setSortBy
                                                                                                            }
                                                                                                            relevantNews={
                                                                                                                relevantNews
                                                                                                            }
                                                                                                            searchName={
                                                                                                                basicSearchInput
                                                                                                            }
                                                                                                        />
                                                                                                        <Grid item>
                                                                                                            <InfiniteScroll
                                                                                                                pageStart={
                                                                                                                    0
                                                                                                                }
                                                                                                                loadMore={
                                                                                                                    runAllSearches.next
                                                                                                                }
                                                                                                                hasMore={
                                                                                                                    runAllSearches.next
                                                                                                                }
                                                                                                                // loader={
                                                                                                                //     // loadMoreLoader && (
                                                                                                                //     //     <div className="mt-1 d-flex align-items-center justify-content-center">
                                                                                                                //     //         <CustomLoader />
                                                                                                                //     //     </div>
                                                                                                                //     // )
                                                                                                                // }
                                                                                                            >
                                                                                                                {runAllSearches.data
                                                                                                                    .sort(
                                                                                                                        (
                                                                                                                            a,
                                                                                                                            b
                                                                                                                        ) =>
                                                                                                                            sortBy ===
                                                                                                                            'date'
                                                                                                                                ? a.date.split(
                                                                                                                                      'T'
                                                                                                                                  )[0] >
                                                                                                                                  b.date.split(
                                                                                                                                      'T'
                                                                                                                                  )[0]
                                                                                                                                    ? -1
                                                                                                                                    : 1
                                                                                                                                : sortBy ===
                                                                                                                                  'relevance'
                                                                                                                                ? a.score >
                                                                                                                                  b.score
                                                                                                                                    ? -1
                                                                                                                                    : 1
                                                                                                                                : 0
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        story => {
                                                                                                                            return (
                                                                                                                                <NewSearchQueryResult
                                                                                                                                    isFindAndShare={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    key={
                                                                                                                                        story.id
                                                                                                                                    }
                                                                                                                                    EachrawNews={
                                                                                                                                        story
                                                                                                                                    }
                                                                                                                                    isBasicSearch={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    selectedStories={
                                                                                                                                        selectedStories
                                                                                                                                    }
                                                                                                                                    isStorySelected={
                                                                                                                                        selectedStories.findIndex(
                                                                                                                                            arr =>
                                                                                                                                                arr.id ===
                                                                                                                                                story.id
                                                                                                                                        ) ===
                                                                                                                                        -1
                                                                                                                                            ? false
                                                                                                                                            : true
                                                                                                                                    }
                                                                                                                                    addToSelection={
                                                                                                                                        addToSelection
                                                                                                                                    }
                                                                                                                                    allCategories={
                                                                                                                                        allCategories
                                                                                                                                    }
                                                                                                                                    allSubCategories={
                                                                                                                                        allSubCategories
                                                                                                                                    }
                                                                                                                                    allIndustries={
                                                                                                                                        allIndustries
                                                                                                                                    }
                                                                                                                                    allCompanies={
                                                                                                                                        allCompanies
                                                                                                                                    }
                                                                                                                                    setAllCompanies={
                                                                                                                                        setAllCompanies
                                                                                                                                    }
                                                                                                                                    allRegions={
                                                                                                                                        allRegions
                                                                                                                                    }
                                                                                                                                    allLinkedInGroups={[]}
                                                                                                                                    removeFromSelection={
                                                                                                                                        removeFromSelection
                                                                                                                                    }
                                                                                                                                    allTopics={
                                                                                                                                        allTopics
                                                                                                                                    }
                                                                                                                                    collapsed={
                                                                                                                                        !expandedView
                                                                                                                                    }
                                                                                                                                    usertype={
                                                                                                                                        usertype
                                                                                                                                    }
                                                                                                                                    selectAll={
                                                                                                                                        isAllSelected
                                                                                                                                    }
                                                                                                                                    currentPath={
                                                                                                                                        currentPath
                                                                                                                                    }
                                                                                                                                    selectedNewsOnly={
                                                                                                                                        selectedNewsOnly
                                                                                                                                    }
                                                                                                                                    isScore={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    selectedLevel={
                                                                                                                                        selectedLevel
                                                                                                                                    }
                                                                                                                                    isMyRequestPageRunAll={
                                                                                                                                        true
                                                                                                                                    }
                                                                                                                                    generateSummary={
                                                                                                                                        generateSummary
                                                                                                                                    }
                                                                                                                                    duplicateCollapsed={
                                                                                                                                        !duplicateCollapsed
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                    )}
                                                                                                            </InfiniteScroll>
                                                                                                        </Grid>
                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <div />
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                                {collectionTab === 1 && (
                                                                                    <div
                                                                                        style={{ padding: '30px 30px' }}
                                                                                        className="d-block d-sm-flex  justify-content-between"
                                                                                    >
                                                                                        <div className="scheduling_settings_overall_width">
                                                                                            <div className="d-block d-sm-flex justify-content-between">
                                                                                                <div className="scheduling_settings_width">
                                                                                                    <label
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    >
                                                                                                        Newsletter Name
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color:
                                                                                                                    'red',
                                                                                                                marginLeft:
                                                                                                                    '3px',
                                                                                                            }}
                                                                                                        >
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <br />
                                                                                                    <TextField
                                                                                                        value={
                                                                                                            collectionName &&
                                                                                                            collectionName.label
                                                                                                                ? collectionName.label
                                                                                                                : collectionName
                                                                                                        }
                                                                                                        onChange={e => {
                                                                                                            setCollectionName(
                                                                                                                e.target
                                                                                                                    .value
                                                                                                            )
                                                                                                        }}
                                                                                                        // label="Title"
                                                                                                        placeholder="Newsletter Name"
                                                                                                        variant="outlined"
                                                                                                        sx={{
                                                                                                            width:
                                                                                                                '100%',
                                                                                                            marginTop:
                                                                                                                '10px',
                                                                                                            background:
                                                                                                                'white',
                                                                                                        }}
                                                                                                        size="small"
                                                                                                    />
                                                                                                </div>

                                                                                                <div className="scheduling_settings_width">
                                                                                                    <label
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    >
                                                                                                        Description
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color:
                                                                                                                    'red',
                                                                                                                marginLeft:
                                                                                                                    '3px',
                                                                                                            }}
                                                                                                        >
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <br />
                                                                                                    <TextField
                                                                                                        value={
                                                                                                            collectionDescription
                                                                                                        }
                                                                                                        onChange={e => {
                                                                                                            setCollectionDescription(
                                                                                                                e.target
                                                                                                                    .value
                                                                                                            )
                                                                                                        }}
                                                                                                        // label="Newsletter Description"
                                                                                                        placeholder="Newsletter Description"
                                                                                                        variant="outlined"
                                                                                                        sx={{
                                                                                                            width:
                                                                                                                '100%',
                                                                                                            marginTop:
                                                                                                                '10px',
                                                                                                            background:
                                                                                                                'white',
                                                                                                        }}
                                                                                                        size="small"
                                                                                                    />
                                                                                                    <br />
                                                                                                    <div
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '14px',
                                                                                                            marginTop:
                                                                                                                '10px',
                                                                                                        }}
                                                                                                    >
                                                                                                        The description
                                                                                                        should have at
                                                                                                        least 2-3 lines
                                                                                                        describing the
                                                                                                        scope of the
                                                                                                        newsletter.
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-block d-sm-flex justify-content-between align-items-center mt-3">
                                                                                                <div className="scheduling_settings_width">
                                                                                                    <label
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    >
                                                                                                        Subject Line
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color:
                                                                                                                    'red',
                                                                                                                marginLeft:
                                                                                                                    '3px',
                                                                                                            }}
                                                                                                        >
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <br />
                                                                                                    <div>
                                                                                                        <TextField
                                                                                                            // disabled={isEnabled ? false : true}
                                                                                                            sx={{
                                                                                                                width:
                                                                                                                    '100%',
                                                                                                                background:
                                                                                                                    'white',
                                                                                                                marginTop:
                                                                                                                    '10px',
                                                                                                            }}
                                                                                                            multiline
                                                                                                            rows={1}
                                                                                                            value={
                                                                                                                emailSubject
                                                                                                            }
                                                                                                            onChange={e => {
                                                                                                                setEmailSubject(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                )
                                                                                                            }}
                                                                                                            variant="outlined"
                                                                                                            placeholder="Subject Line"
                                                                                                            size="small"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="scheduling_settings_width">
                                                                                                    <label
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    >
                                                                                                        Choose a
                                                                                                        template
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color:
                                                                                                                    'red',
                                                                                                                marginLeft:
                                                                                                                    '3px',
                                                                                                            }}
                                                                                                        >
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <br />
                                                                                                    <Autocomplete
                                                                                                        // disabled={isEnabled ? false : true}
                                                                                                        sx={{
                                                                                                            width:
                                                                                                                '100%',
                                                                                                            marginTop:
                                                                                                                '10px',
                                                                                                        }}
                                                                                                        options={
                                                                                                            templates
                                                                                                        }
                                                                                                        autoHighlight
                                                                                                        value={
                                                                                                            selectedTemplate
                                                                                                        }
                                                                                                        getOptionLabel={option =>
                                                                                                            option.label
                                                                                                        }
                                                                                                        renderOption={(
                                                                                                            props,
                                                                                                            option
                                                                                                        ) => (
                                                                                                            <li
                                                                                                                {...props}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        option.imageUrl
                                                                                                                    }
                                                                                                                    alt={
                                                                                                                        option.label
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        width:
                                                                                                                            '24px',
                                                                                                                        marginRight:
                                                                                                                            '8px',
                                                                                                                    }}
                                                                                                                />
                                                                                                                {
                                                                                                                    option.label
                                                                                                                }
                                                                                                            </li>
                                                                                                        )}
                                                                                                        renderInput={params => (
                                                                                                            <TextField
                                                                                                                {...params}
                                                                                                                sx={{
                                                                                                                    height:
                                                                                                                        '40px',
                                                                                                                    background:
                                                                                                                        'white',
                                                                                                                }}
                                                                                                                style={{
                                                                                                                    height: 40,
                                                                                                                }}
                                                                                                                placeholder="Choose a template"
                                                                                                                size="small"
                                                                                                            />
                                                                                                        )}
                                                                                                        onChange={(
                                                                                                            event,
                                                                                                            value
                                                                                                        ) => {
                                                                                                            setSelectedTemplate(
                                                                                                                value
                                                                                                            )
                                                                                                            if (
                                                                                                                value ===
                                                                                                                    null ||
                                                                                                                value.has_sections ===
                                                                                                                    false
                                                                                                            ) {
                                                                                                                setIncludeSection(
                                                                                                                    false
                                                                                                                )
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-block d-sm-flex justify-content-between mt-4">
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            '10px',
                                                                                                    }}
                                                                                                    className="scheduling_settings_width"
                                                                                                >
                                                                                                    <label
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    >
                                                                                                        Recipients
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color:
                                                                                                                    'red',
                                                                                                                marginLeft:
                                                                                                                    '3px',
                                                                                                            }}
                                                                                                        >
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <br />
                                                                                                    <Autocomplete
                                                                                                        // disabled={isEnabled ? false : true}
                                                                                                        sx={{
                                                                                                            width:
                                                                                                                '100%',
                                                                                                            marginTop:
                                                                                                                '10px',
                                                                                                        }}
                                                                                                        multiple
                                                                                                        options={users}
                                                                                                        value={
                                                                                                            selectedUsers
                                                                                                        }
                                                                                                        getOptionLabel={option => {
                                                                                                            if (
                                                                                                                option.label
                                                                                                            ) {
                                                                                                                return option.label
                                                                                                            }

                                                                                                            return option.email
                                                                                                        }}
                                                                                                        onChange={(
                                                                                                            event,
                                                                                                            newValue
                                                                                                        ) => {
                                                                                                            if (
                                                                                                                newValue &&
                                                                                                                newValue.length
                                                                                                            ) {
                                                                                                                const results = []

                                                                                                                newValue.forEach(
                                                                                                                    value => {
                                                                                                                        if (
                                                                                                                            typeof value ===
                                                                                                                            'string'
                                                                                                                        ) {
                                                                                                                            function ValidateEmail(
                                                                                                                                mail
                                                                                                                            ) {
                                                                                                                                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                                                                                    mail
                                                                                                                                )
                                                                                                                            }

                                                                                                                            if (
                                                                                                                                !ValidateEmail(
                                                                                                                                    value
                                                                                                                                )
                                                                                                                            ) {
                                                                                                                                toast.error(
                                                                                                                                    `You have entered an invalid email address! ${value}`
                                                                                                                                )
                                                                                                                                return
                                                                                                                            }

                                                                                                                            return results.push(
                                                                                                                                {
                                                                                                                                    email: value,
                                                                                                                                }
                                                                                                                            )
                                                                                                                        }

                                                                                                                        return results.push(
                                                                                                                            value
                                                                                                                        )
                                                                                                                    }
                                                                                                                )

                                                                                                                setSelectedUsers(
                                                                                                                    results
                                                                                                                )
                                                                                                            }
                                                                                                        }}
                                                                                                        renderInput={params => (
                                                                                                            <TextField
                                                                                                                {...params}
                                                                                                                variant="outlined"
                                                                                                                placeholder="Recipients"
                                                                                                                size="small"
                                                                                                                sx={{
                                                                                                                    background:
                                                                                                                        'white',
                                                                                                                }}
                                                                                                            />
                                                                                                        )}
                                                                                                        renderTags={() =>
                                                                                                            null
                                                                                                        }
                                                                                                        freeSolo
                                                                                                        filterOptions={(
                                                                                                            options,
                                                                                                            params
                                                                                                        ) => {
                                                                                                            const filtered = filter(
                                                                                                                options,
                                                                                                                params
                                                                                                            )
                                                                                                            const {
                                                                                                                inputValue,
                                                                                                            } = params
                                                                                                            const isExisting = options.some(
                                                                                                                option =>
                                                                                                                    inputValue ===
                                                                                                                    option.email
                                                                                                            )
                                                                                                            if (
                                                                                                                inputValue !==
                                                                                                                    '' &&
                                                                                                                !isExisting
                                                                                                            ) {
                                                                                                                filtered.push(
                                                                                                                    {
                                                                                                                        id: `Add email :   ${inputValue}`,
                                                                                                                        email: inputValue,
                                                                                                                    }
                                                                                                                )
                                                                                                            }

                                                                                                            return filtered
                                                                                                        }}
                                                                                                        renderOption={(
                                                                                                            props,
                                                                                                            option
                                                                                                        ) => (
                                                                                                            <li
                                                                                                                {...props}
                                                                                                                style={{
                                                                                                                    color:
                                                                                                                        option.email ===
                                                                                                                        userEmail
                                                                                                                            ? '#197bbd'
                                                                                                                            : '',
                                                                                                                    title:
                                                                                                                        option.email ===
                                                                                                                        userEmail
                                                                                                                            ? 'User'
                                                                                                                            : 'exiting user',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span>
                                                                                                                    {option.label ||
                                                                                                                        option.email}
                                                                                                                </span>
                                                                                                                {usertype !==
                                                                                                                    'Regular User' &&
                                                                                                                    option.user_category ===
                                                                                                                        'LiteUser' && (
                                                                                                                        <span
                                                                                                                            className="Lite"
                                                                                                                            style={{
                                                                                                                                marginLeft:
                                                                                                                                    '5px',
                                                                                                                                paddingLeft:
                                                                                                                                    '5px',
                                                                                                                                fontSize:
                                                                                                                                    'small',
                                                                                                                                background:
                                                                                                                                    'skyblue',
                                                                                                                                padding:
                                                                                                                                    '2px 5px',
                                                                                                                                borderRadius:
                                                                                                                                    '5px',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Lite*
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                            </li>
                                                                                                        )}
                                                                                                    />
                                                                                                    <br />
                                                                                                    {selectedUsers.map(
                                                                                                        user => (
                                                                                                            <Grid item>
                                                                                                                <Chip
                                                                                                                    size="small"
                                                                                                                    key={
                                                                                                                        user.id
                                                                                                                    }
                                                                                                                    label={
                                                                                                                        user.email
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        background:
                                                                                                                            typeof user.id ===
                                                                                                                            'number'
                                                                                                                                ? ''
                                                                                                                                : '#cdc8c7',
                                                                                                                    }}
                                                                                                                    title={
                                                                                                                        typeof user.id ===
                                                                                                                        'number'
                                                                                                                            ? 'Existing user'
                                                                                                                            : 'Custom user'
                                                                                                                    }
                                                                                                                    // disabled={
                                                                                                                    //     isEnabled
                                                                                                                    //         ? false
                                                                                                                    //         : true
                                                                                                                    // }
                                                                                                                    onDelete={() =>
                                                                                                                        setSelectedUsers(
                                                                                                                            selectedUsers.filter(
                                                                                                                                currUser =>
                                                                                                                                    currUser.id !==
                                                                                                                                    user.id
                                                                                                                            )
                                                                                                                        )
                                                                                                                    }
                                                                                                                />
                                                                                                            </Grid>
                                                                                                        )
                                                                                                    )}
                                                                                                </div>
                                                                                                <div className="cron_tab scheduling_settings_width">
                                                                                                    <div>
                                                                                                        <CronTab
                                                                                                            isEnabled={
                                                                                                                isEnabled
                                                                                                            }
                                                                                                            setIsEnabled={
                                                                                                                setIsEnabled
                                                                                                            }
                                                                                                            cronTabValue={
                                                                                                                cronTabValue
                                                                                                            }
                                                                                                            setCronTabValue={
                                                                                                                setCronTabValue
                                                                                                            }
                                                                                                            isMyRequest={
                                                                                                                true
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            onClick={() => {
                                                                                                setBasicSearchDrop(
                                                                                                    false
                                                                                                )
                                                                                                const payload = {
                                                                                                    subject: emailSubject,
                                                                                                    id: collectionId,
                                                                                                    name:
                                                                                                        collectionName &&
                                                                                                        collectionName.label
                                                                                                            ? collectionName.label
                                                                                                            : collectionName,
                                                                                                    description: collectionDescription,
                                                                                                    shared:
                                                                                                        collectionPrivacy.value,
                                                                                                    users:
                                                                                                        collectionUsers.length >
                                                                                                        0
                                                                                                            ? collectionUsers.map(
                                                                                                                  v =>
                                                                                                                      v.id
                                                                                                              )
                                                                                                            : [],
                                                                                                    scheduled: isEnabled,
                                                                                                    cron_expression: LastCronValue(
                                                                                                        cronTabValue,
                                                                                                        '-'
                                                                                                    ),
                                                                                                    recipients: selectedUsers.map(
                                                                                                        val => val.email
                                                                                                    ),
                                                                                                    newsletter:
                                                                                                        'saved_search_newsletter',
                                                                                                    template:
                                                                                                        selectedTemplate &&
                                                                                                        selectedTemplate.value
                                                                                                            ? selectedTemplate.value
                                                                                                            : null,
                                                                                                    section: null,
                                                                                                    has_section: false,
                                                                                                    include_subcategory: false,
                                                                                                }
                                                                                                if (editCron !== null) {
                                                                                                    payload.apscheduler_id = editCron
                                                                                                }

                                                                                                if (
                                                                                                    selectedTemplate &&
                                                                                                    selectedTemplate.value &&
                                                                                                    selectedUsers.length >
                                                                                                        0 &&
                                                                                                    emailSubject !==
                                                                                                        '' &&
                                                                                                    collectionDescription !==
                                                                                                        '' &&
                                                                                                    collectionName !==
                                                                                                        ''
                                                                                                ) {
                                                                                                    axios
                                                                                                        .put(
                                                                                                            `/news/collection/${collectionId}/`,
                                                                                                            payload
                                                                                                        )
                                                                                                        .then(res => {
                                                                                                            getAllCollections()
                                                                                                            setEditCollection(
                                                                                                                false
                                                                                                            )
                                                                                                            toast(
                                                                                                                'Newsletter successfully scheduled'
                                                                                                            )
                                                                                                            clearFilters()
                                                                                                            getCollection()
                                                                                                        })
                                                                                                        .catch(err =>
                                                                                                            console.log(
                                                                                                                err
                                                                                                            )
                                                                                                        )
                                                                                                } else {
                                                                                                    toast.warn(
                                                                                                        'Please fill out all the fields.'
                                                                                                    )
                                                                                                }
                                                                                            }}
                                                                                            className="request_schedule_schedule_save_btn"
                                                                                        >
                                                                                            Save
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {createNewsTab === null && (
                                                                <div
                                                                    style={{
                                                                        position: 'fixed',
                                                                        right: '20px',
                                                                        top: '100px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        setSearch('')
                                                                        setCreateNews(false)
                                                                    }}
                                                                >
                                                                    <CloseIcon />
                                                                </div>
                                                            )}
                                                            <div
                                                                className=""
                                                                style={{
                                                                    width: '70%',
                                                                    margin: 'auto',
                                                                    paddingTop: createNewsTab === null ? '10%' : '',
                                                                }}
                                                            >
                                                                <Steppers
                                                                    activeStep={createNewsTab}
                                                                    isNewsLetters={true}
                                                                    steps={[
                                                                        'Create Newsletter',
                                                                        'Add your searches',
                                                                        'Schedule settings',
                                                                    ]}
                                                                />
                                                                {createNewsTab === null && (
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <h3
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '20px',
                                                                            }}
                                                                        >
                                                                            Step 1
                                                                        </h3>
                                                                        <h3
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '20px',
                                                                            }}
                                                                        >
                                                                            Step 2
                                                                        </h3>
                                                                        <h3
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '20px',
                                                                            }}
                                                                        >
                                                                            Step 3
                                                                        </h3>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {createNewsTab === null && (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            width: '80%',
                                                                            margin: 'auto',
                                                                            paddingTop: '20px',
                                                                        }}
                                                                        className="d-flex align-items-center justify-content-between"
                                                                    >
                                                                        <label
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '18px',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            Create Newsletter
                                                                        </label>
                                                                        <label
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '18px',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            Add your searches
                                                                        </label>
                                                                        <label
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '18px',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            Schedule Settings
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            width: '90%',
                                                                            margin: 'auto',
                                                                            paddingTop: '20px',
                                                                            fontSize: '16px',
                                                                        }}
                                                                        className="d-flex align-items-center justify-content-between"
                                                                    >
                                                                        <span className="text-center">
                                                                            Set up newsletter name, description and
                                                                            automation settings
                                                                        </span>
                                                                        <span className="text-center">
                                                                            Set up searches to fuel your newsletter. You
                                                                            can set up multiple searches.
                                                                        </span>
                                                                        <span className="text-center">
                                                                            Edit your newsletter frequency and
                                                                            recipients
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {createNewsTab !== null ? (
                                                                <>
                                                                    <div>
                                                                        {createNewsTab === 0 && (
                                                                            <>
                                                                                <div className="d-flex align-items-center justify-content-between m-3 ">
                                                                                    <div>
                                                                                        <ArrowBackIcon
                                                                                            onClick={() => {
                                                                                                setCreateNewsTab(null)
                                                                                                setCollectionName('')
                                                                                                setCollectionDescription(
                                                                                                    ''
                                                                                                )
                                                                                            }}
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <label
                                                                                            style={{
                                                                                                fontSize: '17px',
                                                                                                fontWeight: 600,
                                                                                                marginLeft: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Create Newsletter
                                                                                        </label>
                                                                                    </div>
                                                                                    <Button
                                                                                        endIcon={<MdNavigateNext />}
                                                                                        style={{
                                                                                            background: '#186ADE',
                                                                                            borderRadius: '4px',
                                                                                            width: 'max-content',
                                                                                            marginRight: '40px',
                                                                                            // marginTop: '20px',
                                                                                            fontSize: '14px',
                                                                                            height: '40px',
                                                                                            color: 'white',
                                                                                            boxShadow: 'none',
                                                                                            textTransform: 'none',
                                                                                            // margin: 'auto',
                                                                                            // display: 'flex',
                                                                                            opacity:
                                                                                                createNewsTab === 0 &&
                                                                                                collectionName !==
                                                                                                    null &&
                                                                                                collectionDescription !==
                                                                                                    ''
                                                                                                    ? 1
                                                                                                    : 0.7,
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            if (createNewsTab === 0) {
                                                                                                if (
                                                                                                    collectionName !==
                                                                                                        null &&
                                                                                                    collectionDescription !==
                                                                                                        ''
                                                                                                ) {
                                                                                                    setCreateNewsTab(
                                                                                                        prevActiveStep =>
                                                                                                            prevActiveStep +
                                                                                                            1
                                                                                                    )
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        disabled={
                                                                                            createNewsTab === 0 &&
                                                                                            collectionName !== null &&
                                                                                            collectionDescription !== ''
                                                                                                ? false
                                                                                                : true
                                                                                        }
                                                                                    >
                                                                                        Next
                                                                                    </Button>
                                                                                </div>

                                                                                <div className="create_newsletter_step1_width">
                                                                                    <div>
                                                                                        <label
                                                                                            style={{
                                                                                                fontSize: '16px',
                                                                                                fontWeight: 400,
                                                                                            }}
                                                                                        >
                                                                                            Newsletter Name
                                                                                        </label>
                                                                                        <br />
                                                                                        <TextField
                                                                                            value={collectionName}
                                                                                            onChange={e => {
                                                                                                setCollectionName(
                                                                                                    e.target.value
                                                                                                )
                                                                                            }}
                                                                                            // label="Title"
                                                                                            placeholder="Newsletter Name"
                                                                                            variant="outlined"
                                                                                            sx={{
                                                                                                width: '100%',
                                                                                                marginTop: '10px',
                                                                                                background: 'white',
                                                                                            }}
                                                                                            size="small"
                                                                                        />
                                                                                    </div>

                                                                                    <div style={{ marginTop: '20px' }}>
                                                                                        <label
                                                                                            style={{
                                                                                                fontSize: '16px',
                                                                                                fontWeight: 400,
                                                                                            }}
                                                                                        >
                                                                                            Description
                                                                                            <span
                                                                                                style={{ color: 'red' }}
                                                                                            >
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <br />
                                                                                        <TextField
                                                                                            value={
                                                                                                collectionDescription
                                                                                            }
                                                                                            onChange={e => {
                                                                                                setCollectionDescription(
                                                                                                    e.target.value
                                                                                                )
                                                                                            }}
                                                                                            // label="Newsletter Description"
                                                                                            placeholder="Newsletter Description"
                                                                                            variant="outlined"
                                                                                            sx={{
                                                                                                width: '100%',
                                                                                                marginTop: '10px',
                                                                                                background: 'white',
                                                                                            }}
                                                                                            size="small"
                                                                                        />
                                                                                        <br />
                                                                                        <div
                                                                                            style={{
                                                                                                fontSize: '14px',
                                                                                                marginTop: '10px',
                                                                                            }}
                                                                                        >
                                                                                            The description should have
                                                                                            at least 2-3 lines
                                                                                            describing the scope of the
                                                                                            newsletter.
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* <div style={{ marginTop: '20px' }}>
                                                                        <label
                                                                            style={{
                                                                                fontSize: '16px',
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            Newletter Mode
                                                                        </label>
                                                                        <br />
                                                                        <Autocomplete
                                                                            sx={{
                                                                                width: '450px',
                                                                                marginTop: '10px',
                                                                            }}
                                                                            disabled
                                                                            options={autoMate}
                                                                            autoHighlight
                                                                            value={autoMate[0]}
                                                                            getOptionLabel={option => option.label}
                                                                            renderInput={params => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    sx={{
                                                                                        height: '40px',
                                                                                        background: 'white',
                                                                                    }}
                                                                                    style={{ height: 40 }}
                                                                                    placeholder="Newsletter Mode"
                                                                                    size="small"
                                                                                    disabled
                                                                                />
                                                                            )}
                                                                            // onChange={(event, value) => {
                                                                            //     setSelectedTemplate(value)
                                                                            //     if (
                                                                            //         value === null ||
                                                                            //         value.has_sections === false
                                                                            //     ) {
                                                                            //         setIncludeSection(false)
                                                                            //     }
                                                                            // }}
                                                                        />
                                                                    </div>

                                                                    <div style={{ marginTop: '20px' }}>
                                                                        <label
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            Automate - Newsletters go out automatically
                                                                        </label>
                                                                        <br />
                                                                        <label
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 400,
                                                                                marginTop: '10px',
                                                                            }}
                                                                        >
                                                                            Manual - Newsletters would need approval
                                                                            before being sent
                                                                        </label>
                                                                    </div> */}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {createNewsTab === 1 && (
                                                                            <>
                                                                                {!saveSub && !saveExisSub && (
                                                                                    <>
                                                                                        <div className="d-flex align-items-center justify-content-between m-3">
                                                                                            <div>
                                                                                                <ArrowBackIcon
                                                                                                    onClick={() => {
                                                                                                        setCreateNewsTab(
                                                                                                            0
                                                                                                        )
                                                                                                    }}
                                                                                                    style={{
                                                                                                        cursor:
                                                                                                            'pointer',
                                                                                                    }}
                                                                                                />
                                                                                                <span>
                                                                                                    <label
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '16px',
                                                                                                            fontWeight: 600,
                                                                                                            marginLeft:
                                                                                                                '10px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {collectionName}
                                                                                                    </label>
                                                                                                </span>
                                                                                            </div>
                                                                                            <Button
                                                                                                endIcon={
                                                                                                    <MdNavigateNext
                                                                                                        style={{
                                                                                                            marginRight:
                                                                                                                '-5px',
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                style={{
                                                                                                    background:
                                                                                                        '#186ADE',
                                                                                                    borderRadius: '4px',
                                                                                                    width:
                                                                                                        'max-content',
                                                                                                    marginRight: '40px',
                                                                                                    // marginTop: '20px',
                                                                                                    fontSize: '14px',
                                                                                                    height: '40px',
                                                                                                    color: 'white',
                                                                                                    boxShadow: 'none',
                                                                                                    textTransform:
                                                                                                        'none',
                                                                                                    // margin: 'auto',
                                                                                                    // display: 'flex',
                                                                                                    opacity:
                                                                                                        saveDummyData.length !==
                                                                                                        0
                                                                                                            ? 1
                                                                                                            : 0.7,
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    setEmailSubject(
                                                                                                        `${collectionName +
                                                                                                            '-insights'}`
                                                                                                    )

                                                                                                    if (
                                                                                                        saveDummyData.length !==
                                                                                                        0
                                                                                                    ) {
                                                                                                        setCreateNewsTab(
                                                                                                            prevActiveStep =>
                                                                                                                prevActiveStep +
                                                                                                                1
                                                                                                        )
                                                                                                    }
                                                                                                }}
                                                                                                disabled={
                                                                                                    saveDummyData.length !==
                                                                                                    0
                                                                                                        ? false
                                                                                                        : true
                                                                                                }
                                                                                            >
                                                                                                Next
                                                                                            </Button>
                                                                                        </div>

                                                                                        <Tabs
                                                                                            value={collectionSub}
                                                                                            onChange={(
                                                                                                event,
                                                                                                newValue
                                                                                            ) => {
                                                                                                setCollectionSub(
                                                                                                    newValue
                                                                                                )
                                                                                            }}
                                                                                            style={{
                                                                                                background: 'white',
                                                                                                marginTop: '20px',
                                                                                            }}
                                                                                        >
                                                                                            <Tab
                                                                                                label="Searches"
                                                                                                style={{
                                                                                                    marginLeft: '50px',
                                                                                                }}
                                                                                            />
                                                                                        </Tabs>
                                                                                    </>
                                                                                )}
                                                                                {collectionSub === 0 && (
                                                                                    <>
                                                                                        {!saveSub &&
                                                                                            !saveExisSub &&
                                                                                            saveDummyData.length ===
                                                                                                0 && (
                                                                                                <div
                                                                                                    style={{
                                                                                                        background:
                                                                                                            'white',
                                                                                                        width: '90%',
                                                                                                        textAlign:
                                                                                                            'center',
                                                                                                        height: '60px',
                                                                                                        display: 'flex',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        justifyContent:
                                                                                                            'center',
                                                                                                        margin: 'auto',
                                                                                                        marginTop:
                                                                                                            '20px',
                                                                                                    }}
                                                                                                >
                                                                                                    No Saved Searches
                                                                                                    Yet.
                                                                                                </div>
                                                                                            )}

                                                                                        {!saveSub &&
                                                                                            !saveExisSub &&
                                                                                            saveDummyData.length !==
                                                                                                0 && (
                                                                                                <div>
                                                                                                    <DummyQueryTable
                                                                                                        queries={
                                                                                                            saveDummyData
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            )}

                                                                                        {!saveExisSub && saveSub && (
                                                                                            <>
                                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                                    <div>
                                                                                                        <ArrowBackIcon
                                                                                                            onClick={() => {
                                                                                                                cancelRequest()
                                                                                                                setSaveSub(
                                                                                                                    false
                                                                                                                )
                                                                                                                setBasicSearchResponse(
                                                                                                                    {
                                                                                                                        data: [],
                                                                                                                        next: null,
                                                                                                                    }
                                                                                                                )
                                                                                                                setIsLoading(
                                                                                                                    false
                                                                                                                )
                                                                                                            }}
                                                                                                            style={{
                                                                                                                cursor:
                                                                                                                    'pointer',
                                                                                                            }}
                                                                                                        />
                                                                                                        <span>
                                                                                                            <label
                                                                                                                style={{
                                                                                                                    fontSize:
                                                                                                                        '16px',
                                                                                                                    fontWeight: 600,
                                                                                                                    marginLeft:
                                                                                                                        '10px',
                                                                                                                }}
                                                                                                            >
                                                                                                                New
                                                                                                                Search
                                                                                                            </label>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <Button
                                                                                                        style={{
                                                                                                            background:
                                                                                                                '#186ADE',
                                                                                                            borderRadius:
                                                                                                                '4px',
                                                                                                            width:
                                                                                                                'max-content',
                                                                                                            marginRight:
                                                                                                                '40px',
                                                                                                            // marginTop: '20px',
                                                                                                            fontSize:
                                                                                                                '14px',
                                                                                                            height:
                                                                                                                '40px',
                                                                                                            color:
                                                                                                                'white',
                                                                                                            boxShadow:
                                                                                                                'none',
                                                                                                            textTransform:
                                                                                                                'none',
                                                                                                            // margin: 'auto',
                                                                                                            // display: 'flex',
                                                                                                            // opacity:
                                                                                                            //     basicSearchInput !== ''
                                                                                                            //         ? 1
                                                                                                            //         : 0.7,
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            setBasicSearchDrop(
                                                                                                                false
                                                                                                            )
                                                                                                            // setSaveQueryName('')

                                                                                                            // if (
                                                                                                            //     basicSearchInput !== ''
                                                                                                            // ) {
                                                                                                            setDummyDataModal(
                                                                                                                true
                                                                                                            )
                                                                                                            // }
                                                                                                        }}
                                                                                                        // disabled={
                                                                                                        //     basicSearchInput !== ''
                                                                                                        //         ? false
                                                                                                        //         : true
                                                                                                        // }
                                                                                                    >
                                                                                                        Save
                                                                                                    </Button>
                                                                                                </div>
                                                                                                {dummyEditQuery ===
                                                                                                    null && (
                                                                                                    <Tabs
                                                                                                        value={subPlace}
                                                                                                        onChange={(
                                                                                                            event,
                                                                                                            newValue
                                                                                                        ) => {
                                                                                                            setSubPlace(
                                                                                                                newValue
                                                                                                            )
                                                                                                            setBasicSearchResponse(
                                                                                                                {
                                                                                                                    data: [],
                                                                                                                    next: null,
                                                                                                                }
                                                                                                            )
                                                                                                            setAdvanceResponse(
                                                                                                                {
                                                                                                                    data: [],
                                                                                                                    next: null,
                                                                                                                }
                                                                                                            )
                                                                                                        }}
                                                                                                        style={{
                                                                                                            background:
                                                                                                                'white',
                                                                                                            marginTop:
                                                                                                                '20px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Tab
                                                                                                            label="Search"
                                                                                                            style={{
                                                                                                                marginLeft:
                                                                                                                    '50px',
                                                                                                            }}
                                                                                                        />
                                                                                                        <Tab label="Advance Search" />
                                                                                                    </Tabs>
                                                                                                )}
                                                                                                {subPlace === 0 &&
                                                                                                    NewsSearchSection()}
                                                                                                {subPlace === 1 && (
                                                                                                    <NewAdvanceSearchTab
                                                                                                        advanceResponse={
                                                                                                            advanceResponse
                                                                                                        }
                                                                                                        setAdvanceResponse={
                                                                                                            setAdvanceResponse
                                                                                                        }
                                                                                                        isAllSelected={
                                                                                                            isAllSelected
                                                                                                        }
                                                                                                        setAllSelected={
                                                                                                            setAllSelected
                                                                                                        }
                                                                                                        selectedArticles={
                                                                                                            setSelectedArticles
                                                                                                        }
                                                                                                        setSelectedArticles={
                                                                                                            setSelectedArticles
                                                                                                        }
                                                                                                        expandedView={
                                                                                                            expandedView
                                                                                                        }
                                                                                                        setExpandedView={
                                                                                                            setExpandedView
                                                                                                        }
                                                                                                        selectedNewsOnly={
                                                                                                            selectedNewsOnly
                                                                                                        }
                                                                                                        setSelectedNewsOnly={
                                                                                                            setSelectedNewsOnly
                                                                                                        }
                                                                                                        // editPage={editPage}
                                                                                                        // setEditPage={setEditPage}
                                                                                                        editId={editId}
                                                                                                        setEditId={
                                                                                                            setEditId
                                                                                                        }
                                                                                                        editqueryName={
                                                                                                            editqueryName
                                                                                                        }
                                                                                                        setEditQueryName={
                                                                                                            setEditQueryName
                                                                                                        }
                                                                                                        directRun={
                                                                                                            directRun
                                                                                                        }
                                                                                                        setDirectRun={
                                                                                                            setDirectRun
                                                                                                        }
                                                                                                        allCategories={
                                                                                                            allCategories
                                                                                                        }
                                                                                                        allLinkedInGroups={[]}
                                                                                                        addToSelection={
                                                                                                            addToSelection
                                                                                                        }
                                                                                                        removeFromSelection={
                                                                                                            removeFromSelection
                                                                                                        }
                                                                                                        usertype={
                                                                                                            usertype
                                                                                                        }
                                                                                                        isLoading={
                                                                                                            isLoading
                                                                                                        }
                                                                                                        handleLoadMore={
                                                                                                            handleLoadMore
                                                                                                        }
                                                                                                        selectedQueries={
                                                                                                            selectedQueries
                                                                                                        }
                                                                                                        setSelectedQueries={
                                                                                                            setSelectedQueries
                                                                                                        }
                                                                                                        allSources={
                                                                                                            allSources
                                                                                                        }
                                                                                                        setAllSources={
                                                                                                            setAllSources
                                                                                                        }
                                                                                                        // isQueryEditor={
                                                                                                        //     isQueryEditor
                                                                                                        // }
                                                                                                        // setIsQueryEditor={
                                                                                                        //     setIsQueryEditor
                                                                                                        // }
                                                                                                        // isQueryEditorJson={
                                                                                                        //     isQueryEditorJson
                                                                                                        // }
                                                                                                        // setIsQueryEditorJson={
                                                                                                        //     setIsQueryEditorJson
                                                                                                        // }
                                                                                                        startDate={
                                                                                                            startDate
                                                                                                        }
                                                                                                        // level={level}
                                                                                                        // setLevel={setLevel}
                                                                                                        // activeTab={activeTab}
                                                                                                        // runQuery={runQuery}
                                                                                                        // setRunQuery={setRunQuery}
                                                                                                        allRegions={
                                                                                                            allRegions
                                                                                                        }
                                                                                                        setAllRegions={
                                                                                                            setAllRegions
                                                                                                        }
                                                                                                        allTopics={
                                                                                                            allTopics
                                                                                                        }
                                                                                                        setAllTopics={
                                                                                                            setAllTopics
                                                                                                        }
                                                                                                        allCompanies={
                                                                                                            allCompanies
                                                                                                        }
                                                                                                        setAllCompanies={
                                                                                                            setAllCompanies
                                                                                                        }
                                                                                                        allIndustries={
                                                                                                            allIndustries
                                                                                                        }
                                                                                                        setAllIndustries={
                                                                                                            setAllIndustries
                                                                                                        }
                                                                                                        allSubCategories={
                                                                                                            allSubCategories
                                                                                                        }
                                                                                                        advanceSearch={
                                                                                                            advanceSearch
                                                                                                        }
                                                                                                        clearFilters={
                                                                                                            clearFilters
                                                                                                        }
                                                                                                        setAllSubCategories={
                                                                                                            setAllSubCategories
                                                                                                        }
                                                                                                        regionHierarchy={
                                                                                                            regionHierarchy
                                                                                                        }
                                                                                                        setRegionHierarchy={
                                                                                                            setRegionHierarchy
                                                                                                        }
                                                                                                        // apiLevel={apiLevel}
                                                                                                        currentPath={
                                                                                                            currentPath
                                                                                                        }
                                                                                                        selectedCompanies={
                                                                                                            selectedCompanies
                                                                                                        }
                                                                                                        setSelectedCompanies={
                                                                                                            setSelectedCompanies
                                                                                                        }
                                                                                                        // getAllCompanies={
                                                                                                        //     getAllCompanies
                                                                                                        // }
                                                                                                        selectedSection={
                                                                                                            selectedSection
                                                                                                        }
                                                                                                        setSelectedSection={
                                                                                                            setSelectedSection
                                                                                                        }
                                                                                                        selectedUsers={
                                                                                                            selectedUsers
                                                                                                        }
                                                                                                        setSelectedUsers={
                                                                                                            setSelectedUsers
                                                                                                        }
                                                                                                        emailSubject={
                                                                                                            emailSubject
                                                                                                        }
                                                                                                        setEmailSubject={
                                                                                                            setEmailSubject
                                                                                                        }
                                                                                                        includeBusiness={
                                                                                                            includeBusiness
                                                                                                        }
                                                                                                        setIncludeBusiness={
                                                                                                            setIncludeBusiness
                                                                                                        }
                                                                                                        includeSection={
                                                                                                            includeSection
                                                                                                        }
                                                                                                        setIncludeSection={
                                                                                                            setIncludeSection
                                                                                                        }
                                                                                                        selectedLevel={
                                                                                                            selectedLevel
                                                                                                        }
                                                                                                        setSelectedLevel={
                                                                                                            setSelectedLevel
                                                                                                        }
                                                                                                        collectionDescription={
                                                                                                            collectionDescription
                                                                                                        }
                                                                                                        setCollectionDescription={
                                                                                                            setCollectionDescription
                                                                                                        }
                                                                                                        collectionShared={
                                                                                                            collectionShared
                                                                                                        }
                                                                                                        setCollectionShared={
                                                                                                            setCollectionShared
                                                                                                        }
                                                                                                        // allCollectionUsers={
                                                                                                        //     allCollectionUsers
                                                                                                        // }
                                                                                                        collectionUsers={
                                                                                                            collectionUsers
                                                                                                        }
                                                                                                        setCollectionUsers={
                                                                                                            setCollectionUsers
                                                                                                        }
                                                                                                        collectionName={
                                                                                                            collectionName
                                                                                                        }
                                                                                                        setCollectionName={
                                                                                                            setCollectionName
                                                                                                        }
                                                                                                        allCollectionName={
                                                                                                            allCollectionName
                                                                                                        }
                                                                                                        saveModal={
                                                                                                            saveModal
                                                                                                        }
                                                                                                        setSaveModal={
                                                                                                            setSaveModal
                                                                                                        }
                                                                                                        // saveSemanticQuery={
                                                                                                        //     saveSemanticQuery
                                                                                                        // }
                                                                                                        editSemanticQuery={
                                                                                                            editSemanticQuery
                                                                                                        }
                                                                                                        saveQueryName={
                                                                                                            saveQueryName
                                                                                                        }
                                                                                                        setSaveQueryName={
                                                                                                            setSaveQueryName
                                                                                                        }
                                                                                                        collectionModal={
                                                                                                            collectionModal
                                                                                                        }
                                                                                                        setCollectionModal={
                                                                                                            setCollectionModal
                                                                                                        }
                                                                                                        collectionPrivacy={
                                                                                                            collectionPrivacy
                                                                                                        }
                                                                                                        setCollectionPrivacy={
                                                                                                            setCollectionPrivacy
                                                                                                        }
                                                                                                        collectionShareList={
                                                                                                            collectionShareList
                                                                                                        }
                                                                                                        collectionId={
                                                                                                            collectionId
                                                                                                        }
                                                                                                        getAllCollections={
                                                                                                            getAllCollections
                                                                                                        }
                                                                                                        isScore={true}
                                                                                                        // dateFilter={setDateFilter}
                                                                                                        // newFilterSource={
                                                                                                        //     newFilterSource
                                                                                                        // }
                                                                                                        // setNewsFilters={
                                                                                                        //     setNewsFilters
                                                                                                        // }
                                                                                                        // sortBy={sortBy}
                                                                                                        // setSortBy={setSortBy}
                                                                                                        setActiveStep={
                                                                                                            setActiveStep
                                                                                                        }
                                                                                                        setIsLoading={
                                                                                                            setIsLoading
                                                                                                        }
                                                                                                        selectedStories={
                                                                                                            selectedStories
                                                                                                        }
                                                                                                        setSelectedStories={
                                                                                                            setSelectedStories
                                                                                                        }
                                                                                                        isCreationMyRequest={
                                                                                                            true
                                                                                                        }
                                                                                                        generateSummary={
                                                                                                            generateSummary
                                                                                                        }
                                                                                                        combinedRegCouPro={
                                                                                                            combinedRegCouPro
                                                                                                        }
                                                                                                        advanceCurrentDate={
                                                                                                            advanceCurrentDate
                                                                                                        }
                                                                                                        allHierarchy={
                                                                                                            allHierarchy
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </>
                                                                                        )}

                                                                                        {saveExisSub && !saveSub && (
                                                                                            <>
                                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                                    <div>
                                                                                                        <ArrowBackIcon
                                                                                                            onClick={() => {
                                                                                                                setSearch(
                                                                                                                    ''
                                                                                                                )
                                                                                                                setSaveSub(
                                                                                                                    false
                                                                                                                )
                                                                                                                setSaveExisSub(
                                                                                                                    false
                                                                                                                )
                                                                                                                setBasicSearchResponse(
                                                                                                                    {
                                                                                                                        data: [],
                                                                                                                        next: null,
                                                                                                                    }
                                                                                                                )
                                                                                                                setAdvanceResponse(
                                                                                                                    {
                                                                                                                        data: [],
                                                                                                                        next: null,
                                                                                                                    }
                                                                                                                )
                                                                                                                setIsLoading(
                                                                                                                    false
                                                                                                                )
                                                                                                            }}
                                                                                                            style={{
                                                                                                                cursor:
                                                                                                                    'pointer',
                                                                                                            }}
                                                                                                        />
                                                                                                        <span>
                                                                                                            <label
                                                                                                                style={{
                                                                                                                    fontSize:
                                                                                                                        '16px',
                                                                                                                    fontWeight: 600,
                                                                                                                    marginLeft:
                                                                                                                        '10px',
                                                                                                                }}
                                                                                                            >
                                                                                                                Add from
                                                                                                                saved
                                                                                                                Search
                                                                                                            </label>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <Button
                                                                                                        style={{
                                                                                                            background:
                                                                                                                '#186ADE',
                                                                                                            borderRadius:
                                                                                                                '4px',
                                                                                                            width:
                                                                                                                'max-content',
                                                                                                            marginRight:
                                                                                                                '40px',
                                                                                                            // marginTop: '20px',
                                                                                                            fontSize:
                                                                                                                '14px',
                                                                                                            height:
                                                                                                                '40px',
                                                                                                            color:
                                                                                                                'white',
                                                                                                            boxShadow:
                                                                                                                'none',
                                                                                                            textTransform:
                                                                                                                'none',
                                                                                                            // margin: 'auto',
                                                                                                            // display: 'flex',
                                                                                                            opacity:
                                                                                                                saveDummyData.length !==
                                                                                                                0
                                                                                                                    ? 1
                                                                                                                    : 0.7,
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            setBasicSearchDrop(
                                                                                                                false
                                                                                                            )
                                                                                                            setBasicSearchResponse(
                                                                                                                {
                                                                                                                    data: [],
                                                                                                                    next: null,
                                                                                                                }
                                                                                                            )
                                                                                                            setAdvanceResponse(
                                                                                                                {
                                                                                                                    data: [],
                                                                                                                    next: null,
                                                                                                                }
                                                                                                            )

                                                                                                            if (
                                                                                                                saveDummyData.length !==
                                                                                                                0
                                                                                                            ) {
                                                                                                                setSaveSub(
                                                                                                                    false
                                                                                                                )
                                                                                                                setSaveExisSub(
                                                                                                                    false
                                                                                                                )
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            saveDummyData.length !==
                                                                                                            0
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                    >
                                                                                                        Add Search
                                                                                                    </Button>
                                                                                                </div>

                                                                                                {alerts.length !==
                                                                                                    0 && (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width:
                                                                                                                '95%',
                                                                                                            margin:
                                                                                                                'auto',
                                                                                                            marginTop:
                                                                                                                '10px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {SearchField()}
                                                                                                    </div>
                                                                                                )}
                                                                                                <div
                                                                                                    style={{
                                                                                                        width: '95%',
                                                                                                        margin: 'auto',
                                                                                                        marginTop:
                                                                                                            '25px',
                                                                                                    }}
                                                                                                >
                                                                                                    <DataTable
                                                                                                        columns={
                                                                                                            subExistingColumns
                                                                                                        }
                                                                                                        data={
                                                                                                            search ===
                                                                                                            ''
                                                                                                                ? alerts
                                                                                                                : alerts.filter(
                                                                                                                      each =>
                                                                                                                          each.query_name
                                                                                                                              .toLowerCase()
                                                                                                                              .includes(
                                                                                                                                  search.toLowerCase()
                                                                                                                              )
                                                                                                                  )
                                                                                                        }
                                                                                                        pagination
                                                                                                        fixedHeader
                                                                                                        noHeader={true}
                                                                                                        customStyles={
                                                                                                            customStyles
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </>
                                                                                        )}

                                                                                        {!saveSub && !saveExisSub && (
                                                                                            <div
                                                                                                style={{
                                                                                                    width: '90%',
                                                                                                    margin: 'auto',
                                                                                                    display: 'flex',
                                                                                                }}
                                                                                            >
                                                                                                <Button
                                                                                                    endIcon={
                                                                                                        <AddCircleOutlineIcon />
                                                                                                    }
                                                                                                    style={{
                                                                                                        background:
                                                                                                            '#186ADE',
                                                                                                        borderRadius:
                                                                                                            '4px',
                                                                                                        width: '200px',
                                                                                                        // marginRight: '40px',
                                                                                                        marginTop:
                                                                                                            '30px',
                                                                                                        fontSize:
                                                                                                            '14px',
                                                                                                        height: '40px',
                                                                                                        color: 'white',
                                                                                                        boxShadow:
                                                                                                            'none',
                                                                                                        textTransform:
                                                                                                            'none',
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        setDummyEdit(
                                                                                                            false
                                                                                                        )
                                                                                                        setSaveSub(true)
                                                                                                        setSubPlace(0)
                                                                                                        setDummyEditQuery(
                                                                                                            null
                                                                                                        )
                                                                                                    }}
                                                                                                >
                                                                                                    Add New Search
                                                                                                </Button>

                                                                                                <Button
                                                                                                    endIcon={
                                                                                                        <AddCircleOutlineIcon />
                                                                                                    }
                                                                                                    style={{
                                                                                                        background:
                                                                                                            '#186ADE',
                                                                                                        borderRadius:
                                                                                                            '4px',
                                                                                                        width: '200px',
                                                                                                        marginLeft:
                                                                                                            '20px',
                                                                                                        marginTop:
                                                                                                            '30px',
                                                                                                        fontSize:
                                                                                                            '14px',
                                                                                                        height: '40px',
                                                                                                        color: 'white',
                                                                                                        boxShadow:
                                                                                                            'none',
                                                                                                        textTransform:
                                                                                                            'none',
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        setSearch('')
                                                                                                        setDummyEdit(
                                                                                                            false
                                                                                                        )
                                                                                                        setSaveExisSub(
                                                                                                            true
                                                                                                        )
                                                                                                    }}
                                                                                                >
                                                                                                    Add Saved Search
                                                                                                </Button>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {createNewsTab === 2 && (
                                                                            <>
                                                                                <div className="d-flex align-items-center justify-content-between m-3">
                                                                                    <div>
                                                                                        <ArrowBackIcon
                                                                                            onClick={() => {
                                                                                                setCreateNewsTab(1)
                                                                                            }}
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <span>
                                                                                            <label
                                                                                                style={{
                                                                                                    fontSize: '16px',
                                                                                                    fontWeight: 600,
                                                                                                    marginLeft: '10px',
                                                                                                }}
                                                                                            >
                                                                                                Newsletter Settings
                                                                                            </label>
                                                                                        </span>
                                                                                    </div>
                                                                                    <Button
                                                                                        style={{
                                                                                            background: '#186ADE',
                                                                                            borderRadius: '4px',
                                                                                            width: 'max-content',
                                                                                            marginRight: '40px',
                                                                                            // marginTop: '20px',
                                                                                            fontSize: '14px',
                                                                                            height: '40px',
                                                                                            color: 'white',
                                                                                            boxShadow: 'none',
                                                                                            textTransform: 'none',
                                                                                            // margin: 'auto',
                                                                                            // display: 'flex',
                                                                                            opacity:
                                                                                                selectedUsers.length !==
                                                                                                    0 &&
                                                                                                emailSubject !== '' &&
                                                                                                selectedTemplate !==
                                                                                                    null
                                                                                                    ? 1
                                                                                                    : 0.7,
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                selectedUsers.length !==
                                                                                                    0 &&
                                                                                                emailSubject !== ''
                                                                                            ) {
                                                                                                const collectionPayload = {
                                                                                                    name: collectionName,
                                                                                                    description: collectionDescription,
                                                                                                    shared:
                                                                                                        collectionPrivacy &&
                                                                                                        collectionPrivacy.value,
                                                                                                    users:
                                                                                                        selectedUsers.length >
                                                                                                        0
                                                                                                            ? selectedUsers.map(
                                                                                                                  v =>
                                                                                                                      v.id
                                                                                                              )
                                                                                                            : [],
                                                                                                    scheduled: isEnabled,
                                                                                                    cron_expression: LastCronValue(
                                                                                                        cronTabValue,
                                                                                                        '-'
                                                                                                    ),
                                                                                                    recipients: selectedUsers.map(
                                                                                                        val => val.email
                                                                                                    ),
                                                                                                    newsletter:
                                                                                                        'saved_search_newsletter',
                                                                                                    template:
                                                                                                        selectedTemplate &&
                                                                                                        selectedTemplate.value
                                                                                                            ? selectedTemplate.value
                                                                                                            : null,
                                                                                                    section: null,
                                                                                                    has_section: false,
                                                                                                    include_subcategory: false,
                                                                                                }

                                                                                                const clearCollection = () => {
                                                                                                    setCreateNewsTab(0)
                                                                                                    setCreateNews(false)
                                                                                                    getCollection()
                                                                                                    setSaveDummyData([])
                                                                                                    setCollectionName(
                                                                                                        null
                                                                                                    )
                                                                                                    setCollectionDescription(
                                                                                                        ''
                                                                                                    )
                                                                                                    setSelectedQueries({
                                                                                                        ...selectedQueries,
                                                                                                        articleDateFrom:
                                                                                                            '',
                                                                                                        articleOperator:
                                                                                                            'BETWEEN',
                                                                                                        articleDateTo:
                                                                                                            '',
                                                                                                        region: [],
                                                                                                        regionOperator:
                                                                                                            'IS',
                                                                                                        sectors: [],
                                                                                                        industryOperator:
                                                                                                            'IS',
                                                                                                        company: [],
                                                                                                        companyOperator:
                                                                                                            'IS',
                                                                                                        category: [],
                                                                                                        categoryOperator:
                                                                                                            'IS',
                                                                                                        topic: [],
                                                                                                        topicOperator:
                                                                                                            'IS',
                                                                                                        newsTitle: '',
                                                                                                        newsTitleOperator:
                                                                                                            'CONTAINS',
                                                                                                        newsContent: '',
                                                                                                        newsContentOperator:
                                                                                                            'CONTAINS',
                                                                                                        source: [],
                                                                                                        sourceOperator:
                                                                                                            'IS',
                                                                                                    })
                                                                                                    setBasicSearchInput(
                                                                                                        ''
                                                                                                    )
                                                                                                }

                                                                                                const saveQuickQueries = id => {
                                                                                                    saveDummyData.map(
                                                                                                        (
                                                                                                            queries,
                                                                                                            index
                                                                                                        ) => {
                                                                                                            queries.collection = id
                                                                                                            queries.recipients =
                                                                                                                queries.recipients ===
                                                                                                                null
                                                                                                                    ? ''
                                                                                                                    : queries.recipients
                                                                                                            if (
                                                                                                                queries.id
                                                                                                            ) {
                                                                                                                axios
                                                                                                                    .put(
                                                                                                                        `/news/search-query/${queries.id}/`,
                                                                                                                        queries
                                                                                                                    )
                                                                                                                    .then(
                                                                                                                        res => {
                                                                                                                            clearCollection()
                                                                                                                        }
                                                                                                                    )
                                                                                                                    .catch(
                                                                                                                        err =>
                                                                                                                            console.log(
                                                                                                                                err
                                                                                                                            )
                                                                                                                    )
                                                                                                            } else {
                                                                                                                axios
                                                                                                                    .post(
                                                                                                                        '/news/search-query/',
                                                                                                                        queries
                                                                                                                    )
                                                                                                                    .then(
                                                                                                                        res => {
                                                                                                                            clearCollection()
                                                                                                                        }
                                                                                                                    )
                                                                                                                    .catch(
                                                                                                                        err =>
                                                                                                                            console.log(
                                                                                                                                err
                                                                                                                            )
                                                                                                                    )
                                                                                                            }
                                                                                                        }
                                                                                                    )
                                                                                                }

                                                                                                axios
                                                                                                    .post(
                                                                                                        '/news/collection/',
                                                                                                        collectionPayload
                                                                                                    )
                                                                                                    .then(res => {
                                                                                                        if (
                                                                                                            res.data &&
                                                                                                            res.data.id
                                                                                                        ) {
                                                                                                            saveQuickQueries(
                                                                                                                res.data
                                                                                                                    .id
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                    .catch(err =>
                                                                                                        console.log(err)
                                                                                                    )
                                                                                                    .finally(() => {
                                                                                                        toast(
                                                                                                            'Collection created successfully'
                                                                                                        )
                                                                                                    })
                                                                                            }
                                                                                        }}
                                                                                        disabled={
                                                                                            selectedUsers.length !==
                                                                                                0 &&
                                                                                            emailSubject !== '' &&
                                                                                            selectedTemplate !== null
                                                                                                ? false
                                                                                                : true
                                                                                        }
                                                                                    >
                                                                                        Finish
                                                                                    </Button>
                                                                                </div>
                                                                                <div style={{ padding: '0px 40px' }}>
                                                                                    <div
                                                                                        className="d-flex"
                                                                                        style={{
                                                                                            padding: '10px',
                                                                                            marginTop: '10px',
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <label
                                                                                                style={{
                                                                                                    fontSize: '16px',
                                                                                                    fontWeight: 400,
                                                                                                }}
                                                                                            >
                                                                                                Subject Line
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: 'red',
                                                                                                        marginLeft:
                                                                                                            '3px',
                                                                                                    }}
                                                                                                >
                                                                                                    *
                                                                                                </span>
                                                                                            </label>
                                                                                            <br />
                                                                                            <div
                                                                                                style={{
                                                                                                    marginTop: '10px',
                                                                                                }}
                                                                                            >
                                                                                                <TextField
                                                                                                    sx={{
                                                                                                        width: '400px',
                                                                                                        background:
                                                                                                            'white',
                                                                                                    }}
                                                                                                    multiline
                                                                                                    rows={1}
                                                                                                    value={emailSubject}
                                                                                                    onChange={e => {
                                                                                                        setEmailSubject(
                                                                                                            e.target
                                                                                                                .value
                                                                                                        )
                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    placeholder="Subject Line"
                                                                                                    size="small"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    marginTop: '15px',
                                                                                                }}
                                                                                            >
                                                                                                <label
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '16px',
                                                                                                        fontWeight: 400,
                                                                                                    }}
                                                                                                >
                                                                                                    Choose a template
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color:
                                                                                                                'red',
                                                                                                            marginLeft:
                                                                                                                '3px',
                                                                                                        }}
                                                                                                    >
                                                                                                        *
                                                                                                    </span>
                                                                                                </label>
                                                                                                <br />
                                                                                                <Autocomplete
                                                                                                    sx={{
                                                                                                        width: '400px',
                                                                                                        marginTop:
                                                                                                            '10px',
                                                                                                    }}
                                                                                                    options={templates}
                                                                                                    autoHighlight
                                                                                                    value={
                                                                                                        selectedTemplate
                                                                                                    }
                                                                                                    getOptionLabel={option =>
                                                                                                        option.label
                                                                                                    }
                                                                                                    renderOption={(
                                                                                                        props,
                                                                                                        option
                                                                                                    ) => (
                                                                                                        <li {...props}>
                                                                                                            <img
                                                                                                                src={
                                                                                                                    option.imageUrl
                                                                                                                }
                                                                                                                alt={
                                                                                                                    option.label
                                                                                                                }
                                                                                                                style={{
                                                                                                                    width:
                                                                                                                        '24px',
                                                                                                                    marginRight:
                                                                                                                        '8px',
                                                                                                                }}
                                                                                                            />
                                                                                                            {
                                                                                                                option.label
                                                                                                            }
                                                                                                        </li>
                                                                                                    )}
                                                                                                    renderInput={params => (
                                                                                                        <TextField
                                                                                                            {...params}
                                                                                                            sx={{
                                                                                                                height:
                                                                                                                    '40px',
                                                                                                                background:
                                                                                                                    'white',
                                                                                                            }}
                                                                                                            style={{
                                                                                                                height: 40,
                                                                                                            }}
                                                                                                            placeholder="Choose a template"
                                                                                                            size="small"
                                                                                                        />
                                                                                                    )}
                                                                                                    onChange={(
                                                                                                        event,
                                                                                                        value
                                                                                                    ) => {
                                                                                                        setSelectedTemplate(
                                                                                                            value
                                                                                                        )
                                                                                                        if (
                                                                                                            value ===
                                                                                                                null ||
                                                                                                            value.has_sections ===
                                                                                                                false
                                                                                                        ) {
                                                                                                            setIncludeSection(
                                                                                                                false
                                                                                                            )
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <div
                                                                                                style={{
                                                                                                    marginTop: '15px',
                                                                                                }}
                                                                                            >
                                                                                                <label
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '16px',
                                                                                                        fontWeight: 400,
                                                                                                    }}
                                                                                                >
                                                                                                    Recipients
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color:
                                                                                                                'red',
                                                                                                            marginLeft:
                                                                                                                '3px',
                                                                                                        }}
                                                                                                    >
                                                                                                        *
                                                                                                    </span>
                                                                                                </label>
                                                                                                <br />
                                                                                                <Autocomplete
                                                                                                    sx={{
                                                                                                        width: '400px',
                                                                                                        marginTop:
                                                                                                            '10px',
                                                                                                    }}
                                                                                                    multiple
                                                                                                    options={users}
                                                                                                    value={
                                                                                                        selectedUsers
                                                                                                    }
                                                                                                    getOptionLabel={option => {
                                                                                                        if (
                                                                                                            option.label
                                                                                                        ) {
                                                                                                            return option.label
                                                                                                        }

                                                                                                        return option.email
                                                                                                    }}
                                                                                                    onChange={(
                                                                                                        event,
                                                                                                        newValue
                                                                                                    ) => {
                                                                                                        if (
                                                                                                            newValue &&
                                                                                                            newValue.length
                                                                                                        ) {
                                                                                                            const results = []

                                                                                                            newValue.forEach(
                                                                                                                value => {
                                                                                                                    if (
                                                                                                                        typeof value ===
                                                                                                                        'string'
                                                                                                                    ) {
                                                                                                                        function ValidateEmail(
                                                                                                                            mail
                                                                                                                        ) {
                                                                                                                            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                                                                                mail
                                                                                                                            )
                                                                                                                        }

                                                                                                                        if (
                                                                                                                            !ValidateEmail(
                                                                                                                                value
                                                                                                                            )
                                                                                                                        ) {
                                                                                                                            toast.error(
                                                                                                                                `You have entered an invalid email address! ${value}`
                                                                                                                            )
                                                                                                                            return
                                                                                                                        }

                                                                                                                        return results.push(
                                                                                                                            {
                                                                                                                                email: value,
                                                                                                                            }
                                                                                                                        )
                                                                                                                    }

                                                                                                                    return results.push(
                                                                                                                        value
                                                                                                                    )
                                                                                                                }
                                                                                                            )

                                                                                                            setSelectedUsers(
                                                                                                                results
                                                                                                            )
                                                                                                        }
                                                                                                    }}
                                                                                                    renderInput={params => (
                                                                                                        <TextField
                                                                                                            {...params}
                                                                                                            variant="outlined"
                                                                                                            placeholder="Recipients"
                                                                                                            size="small"
                                                                                                            sx={{
                                                                                                                background:
                                                                                                                    'white',
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                    renderTags={() =>
                                                                                                        null
                                                                                                    }
                                                                                                    freeSolo
                                                                                                    filterOptions={(
                                                                                                        options,
                                                                                                        params
                                                                                                    ) => {
                                                                                                        const filtered = filter(
                                                                                                            options,
                                                                                                            params
                                                                                                        )
                                                                                                        const {
                                                                                                            inputValue,
                                                                                                        } = params
                                                                                                        const isExisting = options.some(
                                                                                                            option =>
                                                                                                                inputValue ===
                                                                                                                option.email
                                                                                                        )
                                                                                                        if (
                                                                                                            inputValue !==
                                                                                                                '' &&
                                                                                                            !isExisting
                                                                                                        ) {
                                                                                                            filtered.push(
                                                                                                                {
                                                                                                                    id: `Add email :   ${inputValue}`,
                                                                                                                    email: inputValue,
                                                                                                                }
                                                                                                            )
                                                                                                        }

                                                                                                        return filtered
                                                                                                    }}
                                                                                                    renderOption={(
                                                                                                        props,
                                                                                                        option
                                                                                                    ) => (
                                                                                                        <li
                                                                                                            {...props}
                                                                                                            style={{
                                                                                                                color:
                                                                                                                    option.email ===
                                                                                                                    userEmail
                                                                                                                        ? '#197bbd'
                                                                                                                        : '',
                                                                                                                title:
                                                                                                                    option.email ===
                                                                                                                    userEmail
                                                                                                                        ? 'User'
                                                                                                                        : 'exiting user',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span>
                                                                                                                {option.label ||
                                                                                                                    option.email}
                                                                                                            </span>
                                                                                                            {usertype !==
                                                                                                                'Regular User' &&
                                                                                                                option.user_category ===
                                                                                                                    'LiteUser' && (
                                                                                                                    <span
                                                                                                                        className="Lite"
                                                                                                                        style={{
                                                                                                                            marginLeft:
                                                                                                                                '5px',
                                                                                                                            paddingLeft:
                                                                                                                                '5px',
                                                                                                                            fontSize:
                                                                                                                                'small',
                                                                                                                            background:
                                                                                                                                'skyblue',
                                                                                                                            padding:
                                                                                                                                '2px 5px',
                                                                                                                            borderRadius:
                                                                                                                                '5px',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Lite*
                                                                                                                    </span>
                                                                                                                )}
                                                                                                        </li>
                                                                                                    )}
                                                                                                />
                                                                                                <br />
                                                                                                {selectedUsers.map(
                                                                                                    user => (
                                                                                                        <Grid item>
                                                                                                            <Chip
                                                                                                                size="small"
                                                                                                                key={
                                                                                                                    user.id
                                                                                                                }
                                                                                                                label={
                                                                                                                    user.email
                                                                                                                }
                                                                                                                style={{
                                                                                                                    background:
                                                                                                                        typeof user.id ===
                                                                                                                        'number'
                                                                                                                            ? ''
                                                                                                                            : '#cdc8c7',
                                                                                                                }}
                                                                                                                title={
                                                                                                                    typeof user.id ===
                                                                                                                    'number'
                                                                                                                        ? 'Existing user'
                                                                                                                        : 'Custom user'
                                                                                                                }
                                                                                                                // disabled={
                                                                                                                //     isEnabled
                                                                                                                //         ? false
                                                                                                                //         : true
                                                                                                                // }
                                                                                                                onDelete={() =>
                                                                                                                    setSelectedUsers(
                                                                                                                        selectedUsers.filter(
                                                                                                                            currUser =>
                                                                                                                                currUser.id !==
                                                                                                                                user.id
                                                                                                                        )
                                                                                                                    )
                                                                                                                }
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    )
                                                                                                )}
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* {selectedUsers.length > 0 && (
                                                                                <div
                                                                                    style={{
                                                                                        marginLeft: '20px',
                                                                                        padding: '10px',
                                                                                        height: 'auto',
                                                                                        overflow: 'auto',
                                                                                        border: '1px solid lightgray',
                                                                                        borderRadius: '4px',
                                                                                        width: '100%',
                                                                                    }}
                                                                                >
                                                                                    <label
                                                                                        style={{
                                                                                            fontSize: '16px',
                                                                                            fontWeight: 400,
                                                                                        }}
                                                                                    >
                                                                                        Selected Users
                                                                                    </label>
                                                                                    <Grid item>
                                                                                        <Grid
                                                                                            container
                                                                                            direction="row"
                                                                                            spacing={1}
                                                                                            style={{
                                                                                                marginTop: '5px',
                                                                                                borderTop:
                                                                                                    '1px solid lightgray',
                                                                                            }}
                                                                                        >
                                                                                            {selectedUsers.map(user => (
                                                                                                <Grid item>
                                                                                                    <Chip
                                                                                                        key={user.id}
                                                                                                        label={
                                                                                                            user.email
                                                                                                        }
                                                                                                        style={{
                                                                                                            background:
                                                                                                                typeof user.id ===
                                                                                                                'number'
                                                                                                                    ? ''
                                                                                                                    : '#cdc8c7',
                                                                                                        }}
                                                                                                        title={
                                                                                                            typeof user.id ===
                                                                                                            'number'
                                                                                                                ? 'Existing user'
                                                                                                                : 'Custom user'
                                                                                                        }
                                                                                                        onDelete={() =>
                                                                                                            setSelectedUsers(
                                                                                                                selectedUsers.filter(
                                                                                                                    currUser =>
                                                                                                                        currUser.id !==
                                                                                                                        user.id
                                                                                                                )
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </Grid>
                                                                                            ))}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            )} */}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="cron_tab">
                                                                                            <div
                                                                                                style={{
                                                                                                    marginTop: '10px',
                                                                                                }}
                                                                                            >
                                                                                                <CronTab
                                                                                                    isEnabled={
                                                                                                        isEnabled
                                                                                                    }
                                                                                                    setIsEnabled={
                                                                                                        setIsEnabled
                                                                                                    }
                                                                                                    cronTabValue={
                                                                                                        cronTabValue
                                                                                                    }
                                                                                                    setCronTabValue={
                                                                                                        setCronTabValue
                                                                                                    }
                                                                                                    isMyRequest={true}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <Button
                                                                    style={{
                                                                        background: '#186ADE',
                                                                        borderRadius: '4px',
                                                                        width: '200px',
                                                                        // marginRight: '40px',
                                                                        fontSize: '14px',
                                                                        height: '40px',
                                                                        color: 'white',
                                                                        boxShadow: 'none',
                                                                        textTransform: 'none',
                                                                        margin: 'auto',
                                                                        display: 'flex',
                                                                        marginTop: '5%',
                                                                    }}
                                                                    onClick={() => {
                                                                        setCreateNewsTab(0)
                                                                        setCollectionName('')
                                                                        setCollectionDescription('')
                                                                        setSelectedTemplate(null)
                                                                        setSelectedUsers([])
                                                                    }}
                                                                >
                                                                    Start
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '80vh',
                                                    position: 'relative',
                                                }}
                                            >
                                                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                                            </div>
                                        )}

                                        {deleteModalOpen && (
                                            <DeleteNews
                                                deleteModalOpen={deleteModalOpen}
                                                queryName={deleteId.query}
                                                setDeleteModalOpen={setDeleteModalOpen}
                                                savedQueryDelete={true}
                                                handleBatchDeleteStories={
                                                    createNews ? deleteDummyData : deleteSavedQuery
                                                }
                                            />
                                        )}
                                        {deleteCollection && (
                                            <DeleteNews
                                                deleteModalOpen={deleteCollection}
                                                queryName={collectionName}
                                                setDeleteModalOpen={setDeleteCollection}
                                                savedQueryDelete={true}
                                                handleBatchDeleteStories={deleteSavedCollection}
                                            />
                                        )}
                                        {deleteReportModal && (
                                            <DeleteNews
                                                deleteModalOpen={deleteReportModal}
                                                queryName={deleteId.query}
                                                setDeleteModalOpen={setDeleteReportModal}
                                                savedQueryDelete={true}
                                                handleBatchDeleteStories={deleteReport}
                                            />
                                        )}
                                        <Modal
                                            isOpen={editCollection}
                                            toggle={() => {
                                                setEditCollection(!editCollection)
                                            }}
                                        >
                                            <div
                                                style={{ padding: '10px', background: 'black' }}
                                                className="d-flex justify-content-between"
                                            >
                                                <h3 style={{ paddingLeft: '2%', color: 'white', fontWeight: 600 }}>
                                                    Edit Newsletter
                                                </h3>

                                                <span
                                                    style={{ paddingRight: '2%', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setEditCollection(!editCollection)
                                                    }}
                                                >
                                                    <CancelIcon style={{ color: 'white' }} />
                                                </span>
                                            </div>
                                            <ModalBody>
                                                <TextField
                                                    value={collectionName}
                                                    onChange={e => {
                                                        setCollectionName(e.target.value)
                                                    }}
                                                    label="Title"
                                                    variant="outlined"
                                                    sx={{ width: '450px', marginTop: '20px' }}
                                                />

                                                <TextField
                                                    value={collectionDescription}
                                                    onChange={e => {
                                                        setCollectionDescription(e.target.value)
                                                    }}
                                                    label="Description"
                                                    variant="outlined"
                                                    sx={{ width: '450px', marginTop: '20px' }}
                                                />
                                                <Autocomplete
                                                    sx={{ width: '450px', marginTop: '20px' }}
                                                    options={collectionShareList}
                                                    autoHighlight
                                                    value={collectionPrivacy}
                                                    getOptionLabel={option => option.label}
                                                    // renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            style={{ borderRadius: '10px' }}
                                                            variant="outlined"
                                                            label="Privacy"
                                                        />
                                                    )}
                                                    onChange={(event, value) => {
                                                        if (value !== null) {
                                                            setCollectionPrivacy(value)
                                                        }
                                                    }}
                                                />
                                                {collectionPrivacy && collectionPrivacy.value === false && (
                                                    <Autocomplete
                                                        multiple
                                                        sx={{ width: '450px', marginTop: '20px' }}
                                                        options={users}
                                                        autoHighlight
                                                        value={collectionUsers}
                                                        getOptionLabel={option => option.email}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>{option.email}</li>
                                                        )}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                // sx={{ height: '40px' }}
                                                                style={{ borderRadius: '10px' }}
                                                                variant="outlined"
                                                                label="Users"
                                                            />
                                                        )}
                                                        onChange={(event, value) => {
                                                            setCollectionUsers(value)
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        margin: 'auto',
                                                        paddingTop: '20px',
                                                        marginLeft: '269px',
                                                    }}
                                                >
                                                    <Button
                                                        className="Cancel_btn"
                                                        onClick={() => {
                                                            setEditCollection(false)
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        onClick={() => {
                                                            const payload = {
                                                                name: collectionName,
                                                                description: collectionDescription,
                                                                shared: collectionPrivacy.value,
                                                                users:
                                                                    collectionUsers.length > 0
                                                                        ? collectionUsers.map(v => v.id)
                                                                        : [],
                                                            }
                                                            axios
                                                                .put(`/news/collection/${collectionId}/`, payload)
                                                                .then(res => {
                                                                    getAllCollections()
                                                                    setEditCollection(false)
                                                                    toast('Newsletter successfully updated')
                                                                    clearFilters()
                                                                    getCollection()
                                                                })
                                                                .catch(err => console.log(err))
                                                        }}
                                                        className="save_btn"
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        <Modal
                                            isOpen={iframeReport}
                                            toggle={() => {
                                                setIframeReport(!iframeReport)
                                            }}
                                            style={{ height: '100%' }}
                                            size="lg"
                                            className="pdf_viewer"
                                        >
                                            <ModalBody style={{ height: '100%' }}>
                                                <div class="container">
                                                    <iframe title="PDF Viewer" src={documents} />
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        {quickReportModal && (
                                            <QuickReportPreview
                                                quickReportModal={quickReportModal}
                                                setQuickReportModal={setQuickReportModal}
                                                jsonURLID={documents}
                                            />
                                        )}
                                        {detailedReportModal && (
                                            <DetailedReportPreview
                                                detailedReportModal={detailedReportModal}
                                                setDetailedReportModal={setDetailedReportModal}
                                                jsonURLID={documents}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="" style={{}}>
                                            <div className="">
                                                <div
                                                    className="d-flex align-items-center justify-content-between"
                                                    style={{ fontSize: '20px', paddingLeft: '10px', fontWeight: 700 }}
                                                >
                                                    <span>
                                                        <ArrowBackIcon
                                                            onClick={() => {
                                                                // clearFilters()
                                                                setEditPage(false)
                                                                cancelRequest()
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        />

                                                        <span style={{ marginLeft: '10px' }}>
                                                            <label style={{ fontSize: '18px' }}>
                                                                {editQuery.query_name}
                                                            </label>
                                                        </span>
                                                    </span>

                                                    <div>
                                                        <Button
                                                            variant="contained"
                                                            endIcon={
                                                                <BiSave
                                                                    style={{ fontSize: '18px' }}
                                                                    className="ml_icons"
                                                                />
                                                            }
                                                            style={{
                                                                background: '#186ADE',
                                                                borderRadius: '4px',
                                                                width: 'max-content',
                                                                marginRight: '10px',
                                                                fontSize: '14px',
                                                                height: '40px',
                                                                color: 'white',
                                                                boxShadow: 'none',
                                                                textTransform: 'none',
                                                                opacity: selectedStories.length === 0 ? 1 : 0.7,
                                                            }}
                                                            onClick={() => {
                                                                setBasicSearchDrop(false)
                                                                if (subTab === 0) {
                                                                    setSemanticSaveModal(true)
                                                                } else {
                                                                    setSaveModal(true)
                                                                }
                                                            }}
                                                            disabled={selectedStories.length !== 0}
                                                        >
                                                            <span className="d-none d-sm-block">Save Search</span>
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            endIcon={
                                                                <SendIcon
                                                                    style={{ fontSize: '18px' }}
                                                                    className="ml_icons"
                                                                />
                                                            }
                                                            style={{
                                                                background: '#186ADE',
                                                                borderRadius: '4px',
                                                                width: 'max-content',
                                                                marginRight: '40px',
                                                                fontSize: '14px',
                                                                height: '40px',
                                                                color: 'white',
                                                                opacity:
                                                                    selectedStories.length === 0 || summaryLoader
                                                                        ? 0.6
                                                                        : 1,
                                                                textTransform: 'none',
                                                            }}
                                                            disabled={selectedStories.length === 0 || summaryLoader}
                                                            onClick={() => {
                                                                setActiveStep(1)
                                                            }}
                                                        >
                                                            <span className="d-none d-sm-block">Send Newsletter</span>
                                                        </Button>
                                                    </div>
                                                </div>

                                                {subTab === 0 && <>{NewsSearchSection()}</>}
                                                {subTab === 1 && (
                                                    <>
                                                        <NewAdvanceSearchTab
                                                            advanceResponse={advanceResponse}
                                                            setAdvanceResponse={setAdvanceResponse}
                                                            isAllSelected={isAllSelected}
                                                            setAllSelected={setAllSelected}
                                                            selectedArticles={setSelectedArticles}
                                                            setSelectedArticles={setSelectedArticles}
                                                            expandedView={expandedView}
                                                            setExpandedView={setExpandedView}
                                                            selectedNewsOnly={selectedNewsOnly}
                                                            setSelectedNewsOnly={setSelectedNewsOnly}
                                                            basicSearchResponse={basicSearchResponse}
                                                            // editPage={editPage}
                                                            // setEditPage={setEditPage}
                                                            editId={editId}
                                                            setEditId={setEditId}
                                                            editqueryName={editqueryName}
                                                            setEditQueryName={setEditQueryName}
                                                            directRun={directRun}
                                                            setDirectRun={setDirectRun}
                                                            allCategories={allCategories}
                                                            allLinkedInGroups={[]}
                                                            addToSelection={addToSelection}
                                                            removeFromSelection={removeFromSelection}
                                                            usertype={usertype}
                                                            isLoading={isLoading}
                                                            handleLoadMore={handleLoadMore}
                                                            selectedQueries={selectedQueries}
                                                            setSelectedQueries={setSelectedQueries}
                                                            allSources={allSources}
                                                            setAllSources={setAllSources}
                                                            generateSummary={generateSummary}
                                                            // isQueryEditor={
                                                            //     isQueryEditor
                                                            // }
                                                            // setIsQueryEditor={
                                                            //     setIsQueryEditor
                                                            // }
                                                            // isQueryEditorJson={
                                                            //     isQueryEditorJson
                                                            // }
                                                            // setIsQueryEditorJson={
                                                            //     setIsQueryEditorJson
                                                            // }
                                                            startDate={startDate}
                                                            // level={level}
                                                            // setLevel={setLevel}
                                                            // activeTab={activeTab}
                                                            // runQuery={runQuery}
                                                            // setRunQuery={setRunQuery}
                                                            allRegions={allRegions}
                                                            setAllRegions={setAllRegions}
                                                            allTopics={allTopics}
                                                            setAllTopics={setAllTopics}
                                                            allCompanies={allCompanies}
                                                            setAllCompanies={setAllCompanies}
                                                            allIndustries={allIndustries}
                                                            setAllIndustries={setAllIndustries}
                                                            allSubCategories={allSubCategories}
                                                            advanceSearch={advanceSearch}
                                                            clearFilters={clearFilters}
                                                            setAllSubCategories={setAllSubCategories}
                                                            regionHierarchy={regionHierarchy}
                                                            setRegionHierarchy={setRegionHierarchy}
                                                            // apiLevel={apiLevel}
                                                            currentPath={currentPath}
                                                            selectedCompanies={selectedCompanies}
                                                            setSelectedCompanies={setSelectedCompanies}
                                                            // getAllCompanies={
                                                            //     getAllCompanies
                                                            // }
                                                            selectedSection={selectedSection}
                                                            setSelectedSection={setSelectedSection}
                                                            selectedUsers={selectedUsers}
                                                            setSelectedUsers={setSelectedUsers}
                                                            emailSubject={emailSubject}
                                                            setEmailSubject={setEmailSubject}
                                                            includeBusiness={includeBusiness}
                                                            setIncludeBusiness={setIncludeBusiness}
                                                            includeSection={includeSection}
                                                            setIncludeSection={setIncludeSection}
                                                            selectedLevel={selectedLevel}
                                                            setSelectedLevel={setSelectedLevel}
                                                            collectionDescription={collectionDescription}
                                                            setCollectionDescription={setCollectionDescription}
                                                            collectionShared={collectionShared}
                                                            setCollectionShared={setCollectionShared}
                                                            // allCollectionUsers={
                                                            //     allCollectionUsers
                                                            // }
                                                            collectionUsers={collectionUsers}
                                                            setCollectionUsers={setCollectionUsers}
                                                            collectionName={collectionName}
                                                            setCollectionName={setCollectionName}
                                                            allCollectionName={allCollectionName}
                                                            saveModal={saveModal}
                                                            setSaveModal={setSaveModal}
                                                            // saveSemanticQuery={
                                                            //     saveSemanticQuery
                                                            // }
                                                            editSemanticQuery={editSemanticQuery}
                                                            saveQueryName={saveQueryName}
                                                            setSaveQueryName={setSaveQueryName}
                                                            collectionModal={collectionModal}
                                                            setCollectionModal={setCollectionModal}
                                                            collectionPrivacy={collectionPrivacy}
                                                            setCollectionPrivacy={setCollectionPrivacy}
                                                            collectionShareList={collectionShareList}
                                                            collectionId={collectionId}
                                                            getAllCollections={getAllCollections}
                                                            isScore={true}
                                                            // dateFilter={setDateFilter}
                                                            // newFilterSource={
                                                            //     newFilterSource
                                                            // }
                                                            // setNewsFilters={
                                                            //     setNewsFilters
                                                            // }
                                                            // sortBy={sortBy}
                                                            // setSortBy={setSortBy}
                                                            setActiveStep={setActiveStep}
                                                            setIsLoading={setIsLoading}
                                                            selectedStories={selectedStories}
                                                            setSelectedStories={setSelectedStories}
                                                            isMyRequest={true}
                                                            advanceCurrentDate={advanceCurrentDate}
                                                            combinedRegCouPro={combinedRegCouPro}
                                                            allHierarchy={allHierarchy}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {selectedStories.length > 0 && (
                                <>
                                    {!selectedArticles && (
                                        <>
                                            <div
                                                className="advance_section"
                                                style={{
                                                    height:
                                                        document.documentElement.scrollHeight > 1500
                                                            ? document.documentElement.scrollHeight
                                                            : '90vh',
                                                    background: '#F2F5F7',
                                                    transition: 'opacity 3s ease',
                                                    overflow: 'auto',
                                                    width: '50px',
                                                    cursor: 'pointer',
                                                    marginTop: '-25px',
                                                    zIndex: 8,
                                                    boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                                }}
                                            >
                                                <KeyboardDoubleArrowLeftIcon
                                                    style={{ marginTop: '10px' }}
                                                    onClick={() => {
                                                        setSelectedArticles(true)
                                                    }}
                                                />
                                                <hr style={{ margin: '10px 0px' }} />
                                            </div>
                                            {/* <div
                                        className="advance_section"
                                        style={{
                                            height:
                                                document.documentElement.scrollHeight > 1500
                                                    ? document.documentElement.scrollHeight
                                                    : '90vh',
                                            background: '#F2F5F7',
                                            transition: 'opacity 3s ease',
                                            overflow: 'auto',
                                            width: '50px',
                                            cursor: 'pointer',
                                            position: 'fixed',
                                            right: 0,
                                            top: '70px',
                                            zIndex: 8,
                                        }}
                                    >
                                        <KeyboardDoubleArrowLeftIcon
                                            style={{ marginTop: '10px' }}
                                            onClick={() => {
                                                setSelectedArticles(true)
                                            }}
                                        />
                                        <hr style={{ margin: '10px 0px' }} />
                                    </div> */}
                                        </>
                                    )}

                                    {selectedArticles && (
                                        <>
                                            <div
                                                className={`advance_section`}
                                                style={{
                                                    height:
                                                        document.documentElement.scrollHeight > 1500
                                                            ? document.documentElement.scrollHeight
                                                            : '100vh',
                                                    background: '#F2F5F7',
                                                    transition: 'opacity 3s ease',
                                                    overflow: 'auto',
                                                    width: '250px',
                                                    boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                                }}
                                            />
                                            <div
                                                className="advance_section"
                                                style={{
                                                    height:
                                                        document.documentElement.scrollHeight > 1500
                                                            ? document.documentElement.scrollHeight
                                                            : '90vh',
                                                    background: '#F2F5F7',
                                                    transition: 'opacity 3s ease',
                                                    overflow: 'auto',
                                                    width: '260px',
                                                    position: 'fixed',
                                                    right: 0,
                                                    top: '70px',
                                                    zIndex: 8,
                                                    boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                                }}
                                            >
                                                <div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-center"
                                                        style={{ marginTop: '10px', cursor: 'pointer' }}
                                                    >
                                                        <KeyboardDoubleArrowRightIcon
                                                            onClick={() => {
                                                                setSelectedArticles(false)
                                                            }}
                                                        />

                                                        <label style={{ fontSize: '16px' }}>Selected Articles</label>
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setSelectedArticles(false)
                                                            }}
                                                        >
                                                            <IoIosClose style={{ fontSize: '26px' }} />
                                                        </span>
                                                    </div>
                                                    <hr style={{ margin: '10px 0px' }} />
                                                    <div>
                                                        {selectedStories.map(story => {
                                                            return (
                                                                <NewSearchQueryResult
                                                                    isFindAndShare={true}
                                                                    key={story.id}
                                                                    EachrawNews={story}
                                                                    isBasicSearch={true}
                                                                    selectedStories={selectedStories}
                                                                    isStorySelected={
                                                                        selectedStories.findIndex(
                                                                            arr => arr.id === story.id
                                                                        ) === -1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    addToSelection={addToSelection}
                                                                    allCategories={allCategories}
                                                                    allSubCategories={allSubCategories}
                                                                    allIndustries={allIndustries}
                                                                    allCompanies={allCompanies}
                                                                    setAllCompanies={setAllCompanies}
                                                                    allRegions={allRegions}
                                                                    allLinkedInGroups={[]}
                                                                    removeFromSelection={removeFromSelection}
                                                                    allTopics={allTopics}
                                                                    collapsed={!expandedView}
                                                                    usertype={usertype}
                                                                    selectAll={isAllSelected}
                                                                    currentPath={currentPath}
                                                                    selectedNewsOnly={selectedNewsOnly}
                                                                    isScore={true}
                                                                    selectedLevel={selectedLevel}
                                                                    selectedOnly={true}
                                                                    generateSummary={generateSummary}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <Modal
                        isOpen={scheduleModel}
                        toggle={() => {
                            setScheduleModal(!scheduleModel)
                        }}
                        size="lg"
                    >
                        <div
                            style={{ padding: '20px 10px 0px 10px' }}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <h3 style={{ paddingLeft: '2%', fontWeight: 600 }}>Scheduling NewsLetter</h3>

                            <span
                                style={{ paddingRight: '2%', cursor: 'pointer' }}
                                onClick={() => {
                                    setScheduleModal(!scheduleModel)
                                }}
                            >
                                <CancelIcon />
                            </span>
                        </div>
                        <hr />
                        <ModalBody style={{ padding: '0px 10px' }}>
                            <div
                                style={{
                                    width: '68%',
                                    border: '1px solid lightgray',
                                    height: '50px',
                                    margin: '0px 10px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="form-check form-switch" style={{ marginLeft: '10px' }}>
                                    <input
                                        style={{ cursor: 'pointer' }}
                                        title="enabled/disabled of scheduling"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        value={isEnabled}
                                        checked={isEnabled ? true : false}
                                        onChange={e => {
                                            setIsEnabled(current => !current)
                                        }}
                                    />
                                    <label className="form-check-label text-black" htmlFor="flexSwitchCheckChecked">
                                        {isEnabled ? 'Scheduling Enabled' : 'Scheduling Disabled'}
                                    </label>
                                </div>
                            </div>

                            <div className="d-flex" style={{ padding: '10px' }}>
                                <div style={{ width: '70%' }}>
                                    <div style={{ marginTop: '5px' }}>
                                        <TextField
                                            disabled={isEnabled ? false : true}
                                            sx={{ width: '100%' }}
                                            multiline
                                            rows={3}
                                            value={emailSubject}
                                            onChange={e => {
                                                setEmailSubject(e.target.value)
                                            }}
                                            size="small"
                                            variant="outlined"
                                            placeholder="Subject Line"
                                        />
                                    </div>
                                    <div style={{ marginTop: '15px' }}>
                                        <Autocomplete
                                            disabled={isEnabled ? false : true}
                                            sx={{ width: '100%' }}
                                            options={templates}
                                            autoHighlight
                                            value={selectedTemplate}
                                            getOptionLabel={option => option.label}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <img
                                                        src={option.imageUrl}
                                                        alt={option.label}
                                                        style={{ width: '24px', marginRight: '8px' }}
                                                    />
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: '40px' }}
                                                    style={{ height: 40 }}
                                                    placeholder="Choose a template"
                                                    size="small"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                setSelectedTemplate(value)
                                                if (value === null || value.has_sections === false) {
                                                    setIncludeSection(false)
                                                }
                                            }}
                                        />
                                    </div>

                                    {/* Includes Sections and Business Events */}
                                    <div style={{ marginTop: '25px' }}>
                                        <div className="form-check form-switch">
                                            <label className="form-check-label text-black">
                                                {'Include Business Events in the Newsletter'}
                                            </label>
                                            <input
                                                style={{ cursor: 'pointer' }}
                                                title="include/exclude of business events in the newsletter"
                                                className="form-check-input"
                                                type="checkbox"
                                                value={includeBusiness}
                                                checked={includeBusiness ? true : false}
                                                onChange={e => {
                                                    setIncludeBusiness(current => !current)
                                                }}
                                                disabled={isEnabled ? false : true}
                                            />
                                        </div>
                                        {selectedTemplate && selectedTemplate.has_sections && (
                                            <>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label text-black">
                                                        {'Include Section in the Newsletter'}
                                                    </label>
                                                    <input
                                                        style={{ cursor: 'pointer' }}
                                                        title="include/exlude of Section in the newsletter"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={includeSection}
                                                        checked={includeSection ? true : false}
                                                        onChange={e => {
                                                            setIncludeSection(current => !current)
                                                        }}
                                                        disabled={isEnabled ? false : true}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {/* section */}
                                    {selectedTemplate !== null && selectedTemplate.has_sections && includeSection && (
                                        <div style={{ marginTop: '10px' }}>
                                            <Autocomplete
                                                disabled={isEnabled ? false : true}
                                                sx={{ width: '100%' }}
                                                options={sections}
                                                autoHighlight
                                                aria-required={selectedTemplate.has_sections ? true : false}
                                                value={selectedSection}
                                                getOptionLabel={option => option.label}
                                                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        sx={{ height: '40px' }}
                                                        style={{ height: 40 }}
                                                        placeholder="Choose a section type"
                                                        size="small"
                                                    />
                                                )}
                                                onChange={(event, value) => {
                                                    setSelectedSection(value)
                                                    if (value === null) {
                                                        setIncludeSection(false)
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                    {/* users */}
                                    <div
                                        style={{
                                            marginTop: includeSection ? '25px' : '15px',
                                        }}
                                    >
                                        <Autocomplete
                                            disabled={isEnabled ? false : true}
                                            sx={{ width: '100%' }}
                                            multiple
                                            options={users}
                                            value={selectedUsers}
                                            getOptionLabel={option => {
                                                if (option.label) {
                                                    return option.label
                                                }

                                                return option.email
                                            }}
                                            onChange={(event, newValue) => {
                                                if (newValue && newValue.length) {
                                                    const results = []

                                                    newValue.forEach(value => {
                                                        if (typeof value === 'string') {
                                                            function ValidateEmail(mail) {
                                                                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                    mail
                                                                )
                                                            }

                                                            if (!ValidateEmail(value)) {
                                                                toast.error(
                                                                    `You have entered an invalid email address! ${value}`
                                                                )
                                                                return
                                                            }

                                                            return results.push({
                                                                email: value,
                                                            })
                                                        }

                                                        return results.push(value)
                                                    })

                                                    setSelectedUsers(results)
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="Recipients"
                                                />
                                            )}
                                            renderTags={() => null}
                                            freeSolo
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params)
                                                const { inputValue } = params
                                                const isExisting = options.some(option => inputValue === option.email)
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        id: `Add email :   ${inputValue}`,
                                                        email: inputValue,
                                                    })
                                                }

                                                return filtered
                                            }}
                                            renderOption={(props, option) => (
                                                <li
                                                    {...props}
                                                    style={{
                                                        color: option.email === userEmail ? '#197bbd' : '',
                                                        title: option.email === userEmail ? 'User' : 'exiting user',
                                                    }}
                                                >
                                                    <span>{option.label || option.email}</span>
                                                    {usertype !== 'Regular User' &&
                                                        option.user_category === 'LiteUser' && (
                                                            <span
                                                                className="Lite"
                                                                style={{
                                                                    marginLeft: '5px',
                                                                    paddingLeft: '5px',
                                                                    fontSize: 'small',
                                                                    background: 'skyblue',
                                                                    padding: '2px 5px',
                                                                    borderRadius: '5px',
                                                                }}
                                                            >
                                                                Lite*
                                                            </span>
                                                        )}
                                                </li>
                                            )}
                                        />
                                    </div>

                                    {selectedUsers.length > 0 && (
                                        <Grid item style={{ marginTop: '10px' }}>
                                            <Grid container direction="row" spacing={1}>
                                                {selectedUsers.map(user => (
                                                    <Grid item>
                                                        <Chip
                                                            size="small"
                                                            key={user.id}
                                                            label={user.email}
                                                            style={{
                                                                background:
                                                                    typeof user.id === 'number' ? '' : '#cdc8c7',
                                                            }}
                                                            title={
                                                                typeof user.id === 'number'
                                                                    ? 'Existing user'
                                                                    : 'Custom user'
                                                            }
                                                            disabled={isEnabled ? false : true}
                                                            onDelete={() =>
                                                                setSelectedUsers(
                                                                    selectedUsers.filter(
                                                                        currUser => currUser.id !== user.id
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="cron_tab">
                                    <div>
                                        <CronTab
                                            isEnabled={isEnabled}
                                            setIsEnabled={setIsEnabled}
                                            cronTabValue={cronTabValue}
                                            setCronTabValue={setCronTabValue}
                                        />
                                    </div>
                                </div>
                            </div>

                            <ModalFooter style={{ padding: '10px' }}>
                                <Button
                                    variant="contained"
                                    endIcon={<AiOutlineSave style={{ fontSize: '18px' }} />}
                                    style={{
                                        background: '#186ADE',
                                        borderRadius: '4px',
                                        width: 'max-content',
                                        marginRight: '40px',
                                        fontSize: '14px',
                                        height: '40px',
                                        color: 'white',
                                        boxShadow: 'none',
                                        textTransform: 'none',
                                    }}
                                    className="btn btn-primary"
                                    onClick={() => {
                                        saveChanges(scheduleContent)
                                    }}
                                    title="save"
                                >
                                    Save
                                </Button>
                            </ModalFooter>
                        </ModalBody>
                    </Modal>
                    {semanticSaveModal && (
                        <SaveSearches
                            collectionDescription={collectionDescription}
                            setCollectionDescription={setCollectionDescription}
                            collectionShared={collectionShared}
                            setCollectionShared={setCollectionShared}
                            allCollectionUsers={allCollectionName}
                            collectionUsers={collectionUsers}
                            setCollectionUsers={setCollectionUsers}
                            collectionName={collectionName}
                            setCollectionName={setCollectionName}
                            allCollectionName={allCollectionName}
                            openModal={semanticSaveModal}
                            setOpenModal={setSemanticSaveModal}
                            editSemanticQuery={editSemanticQuery}
                            saveQueryName={saveQueryName}
                            setSaveQueryName={setSaveQueryName}
                            editqueryName={editqueryName}
                            setEditQueryName={setEditQueryName}
                            collectionModal={collectionModal}
                            setCollectionModal={setCollectionModal}
                            collectionPrivacy={collectionPrivacy}
                            setCollectionPrivacy={setCollectionPrivacy}
                            collectionShareList={collectionShareList}
                            isQuickSearch={true}
                            directRun={directRun}
                            isMyRequest={createNews ? true : false}
                        />
                    )}

                    {dummyDataModal && (
                        <Modal
                            isOpen={dummyDataModal}
                            toggle={() => {
                                setDummyDataModal(!dummyDataModal)
                            }}
                        >
                            <ModalHeader>Save Search</ModalHeader>
                            <ModalBody>
                                <label>Name</label>
                                <br />
                                <TextField
                                    value={saveQueryName}
                                    onChange={e => {
                                        setSaveQueryName(e.target.value)
                                    }}
                                    placeholder="Name"
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: '100%' }}
                                    className="mt-1"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    variant="contained"
                                    style={{
                                        background: '#186ADE',
                                        borderRadius: '4px',
                                        width: 'max-content',
                                        // marginLeft: '20px',
                                        fontSize: '14px',
                                        height: '40px',
                                        color: 'white',
                                        boxShadow: 'none',
                                        textTransform: 'none',
                                    }}
                                    className="Cancel_btn"
                                    onClick={() => {
                                        setDummyDataModal(false)
                                    }}
                                >
                                    Cancel
                                </Button>

                                {subPlace === 0 && (
                                    <Button
                                        variant="contained"
                                        style={{
                                            background: '#186ADE',
                                            borderRadius: '4px',
                                            width: 'max-content',
                                            // marginLeft: '20px',
                                            fontSize: '14px',
                                            height: '40px',
                                            color: 'white',
                                            boxShadow: 'none',
                                            textTransform: 'none',
                                            marginLeft: '20px',
                                        }}
                                        onClick={dummyEdit ? editSemanticDummy : saveSemanticDummy}
                                        className="save_btn"
                                    >
                                        Save
                                    </Button>
                                )}
                                {subPlace === 1 && (
                                    <Button
                                        variant="contained"
                                        style={{
                                            background: '#186ADE',
                                            borderRadius: '4px',
                                            width: 'max-content',
                                            // marginLeft: '20px',
                                            fontSize: '14px',
                                            height: '40px',
                                            color: 'white',
                                            boxShadow: 'none',
                                            textTransform: 'none',
                                            marginLeft: '20px',
                                        }}
                                        onClick={dummyEdit ? editAdvanceDummy : saveAdvanceDummy}
                                        className="save_btn"
                                    >
                                        Save
                                    </Button>
                                )}
                            </ModalFooter>
                        </Modal>
                    )}
                </>
            )}

            {activeStep === 1 && (
                <>
                    <SelectTemplate
                        selectedTemplate={selectedPreviewTempalte}
                        handleTemplateSelected={handleTemplateSelected}
                        usertype={usertype}
                    />
                </>
            )}

            {activeStep === 2 && (
                <>
                    <TemplatePreview
                        selectedStories={selectedStories}
                        selectedTemplate={selectedPreviewTempalte}
                        setContent={setContent}
                        subjectLine={subjectLine}
                        setSubjectLine={setSubjectLine}
                        title={title}
                        setTitle={setTitle}
                        introText={introText}
                        setIntroText={setIntroText}
                        hyperlink={setHyperlink}
                        setHyperlink={setHyperlink}
                        primaryColor={primaryColor}
                        setPrimaryColor={setPrimaryColor}
                        secondaryColor={secondaryColor}
                        setSecondaryColor={setSecondaryColor}
                        setLogo={setLogo}
                        resetPrimaryLogo={resetPrimaryLogo}
                        logoFileName={logoFileName}
                        secondaryLogo={secondaryLogo}
                        setSecondaryLogo={setsecondaryLogo}
                        resetSecondaryLogo={resetSecondaryLogo}
                        setSize={setSize}
                        seclogoFileName={seclogoFileName}
                        setBackgroundImage={setBackgroundImage}
                        bgFileName={bgFileName}
                        selectedLogo={selectedLogo}
                        selectedBg={selectedBg}
                        categorizedNews={categorizedNews}
                        setNewsCategory={setNewsCategory}
                        usertype={usertype}
                        isSearchComponent={true}
                        includeSection={includeSection}
                        setIncludeSection={setIncludeSection}
                        includeBusiness={includeBusiness}
                        setIncludeBusiness={setIncludeBusiness}
                        hyperlinkTitle={hyperlinkTitle}
                        setHyperLinkTitle={setHyperLinkTitle}
                        hyperlinkSource={hyperlinkSource}
                        setHyperLinkSource={setHyperLinkSource}
                    />
                </>
            )}

            {activeStep == 3 && (
                <SendNewsLetter
                    selectedTemplate={selectedPreviewTempalte}
                    selectedStories={selectedStories}
                    allCategories={allCategories}
                    allIndustries={allIndustries}
                    allCompanies={allCompanies}
                    allFirmUsers={allFirmUsers}
                    allRegions={allRegions}
                    allTopics={allTopics}
                    allFirms={allFirms}
                    categorizedNews={categorizedNews}
                    subjectLine={subjectLine}
                    introText={introText}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    title={title}
                    selectedLogo={selectedLogo}
                    secondaryLogo={secondaryLogo}
                    selectedBg={selectedBg}
                    logoSize={logoSize}
                    secLogoSize={secLogoSize}
                    hyperlink={hyperlink}
                    content={content}
                    usertype={usertype}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkTitle={hyperlinkTitle}
                    setHyperLinkTitle={setHyperLinkTitle}
                    hyperlinkSource={hyperlinkSource}
                    setHyperLinkSource={setHyperLinkSource}
                    setActiveStep={setActiveStep}
                    sentUsers={sentUsers}
                    setSentUsers={setSentUsers}
                />
            )}

            {activeStep === 4 && (
                <ScheduleSucessMsg
                    subjectLine={subjectLine}
                    setActiveStep={setActiveStep}
                    sentUsers={sentUsers}
                    isMyRequest={true}
                />
            )}
        </BaseScreen>
    )
}
